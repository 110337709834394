<template>
    <!-- Modal toggle -->
    <button id="btn-modal-flowbite" ref="btn-modal-flowbite" data-modal-target="modal-flowbite" @click="modalShow()"
        class="block hidden text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button">
        Toggle modal
    </button>

    <!-- Main modal -->
    <div id="modal-flowbite" tabindex="-1" aria-hidden="true"
        class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative w-full max-w-lg max-h-full">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button type="button" @click="hideModal"
                    class="absolute top-6 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="modal-flowbite">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <!--Modal Content--->
                <div class="px-2 py-6 lg:px-2">
                    <component v-if="componentName != ''" :is="componentName" :document_id="document_id"></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        Modal
    } from 'flowbite'
    import FolderRenameModal from '@/views/document/components/FolderRenameModal.vue';
    import DocumentAccessModal from '@/views/document/components/DocumentAccessModal.vue';
    import FolderAccessModal from '@/views/document/components/FolderAccessModal.vue';
    import ModalTambahMeeting from '@/views/meeting/components/ModalTambahMeeting.vue';
    import ModalDetailMeeting from '@/views/meeting/components/ModalDetailMeeting.vue';
    import ModalBagikanMeeting from '@/views/meeting/components/ModalBagikanMeeting.vue';
    import ModalEditMeeting from '@/views/meeting/components/ModalEditMeeting.vue';
    import AsetDetailModal from '@/views/aset/components/AsetDetailModal.vue';
    import AsetTambahModal from '@/views/aset/components/AsetTambahModal.vue';
    import AsetEditModal from '@/views/aset/components/AsetEditModal.vue';
    export default {
        components: {
            FolderRenameModal,
            DocumentAccessModal,
            FolderAccessModal,
            Modal,
            ModalTambahMeeting,
            ModalDetailMeeting,
            ModalBagikanMeeting,
            ModalEditMeeting,
            AsetDetailModal,
            AsetTambahModal,
            AsetEditModal
        },
        props: {
            showModal: Boolean,
            componentName: String,
            document_id: [String, Number]
        },
        emits: ["closeModal"],
        watch: {
            showModal: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    if (newValue == true) {
                        document.getElementById("btn-modal-flowbite").click();
                    }
                }
            }
        },
        methods: {
            hideModal() {
                this.$emit('closeModal', 'folder-rename-modal', 'modal-flowbite');
            },
            modalShow() {
                const $modalElement = document.querySelector('#modal-flowbite');

                const modalOptions = {
                    placement: 'center',
                    backdrop: 'static',
                    onHide: () => {
                        // console.log('modal is hidden');
                    },
                    onShow: () => {
                        // console.log('modal is shown');
                    },
                    onToggle: () => {
                        // console.log('modal has been toggled');
                    }
                }

                const modal = new Modal($modalElement, modalOptions);

                modal.show();
            }
        }
    }
</script>