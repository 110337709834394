<template>
    <div>
        <li class="flex justify-items-start item-center"
            @click="$emit('openModal', 'aset-edit-modal', 'modal-flowbite', kode_aset)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                <path
                    d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                <path
                    d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
            </svg>

            <span class="ml-3 text-sm">Edit</span>
        </li>
        <li class="flex justify-items-start item-center"
            @click="$emit('openModal', 'aset-detail-modal', 'modal-flowbite', aset_id)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
            </svg>

            <span class="ml-3 text-sm">Detail</span>
        </li>
        <li class="flex justify-items-start item-center"
            @click="confirmDelete()">
            <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
            </svg>

            <span class="ml-3 text-sm">Hapus</span>
        </li>
    </div>
</template>

<script>
    export default {
        props: {
            kode_aset: String,
            aset_id: Number
        },
        setup() {

        },
        methods: {
            confirmDelete() {
                this.$swal.fire({
                    title: "Peringatan!",
                    text: "Anda yakin akan menghapus data aset ini?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yakin"
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteAset()
                    }
                });
            },
            deleteAset() {
                this.$http.post('/api/auth/aset/delete-data-aset', {
                    aset_id: this.aset_id
                }).then((response) => {
                    if (response.data.status == "Success") {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Aset berhasil di hapus',
                            showConfirmButton: false,
                            timer: 1200
                        })
                        setInterval(location.reload(), 5000);
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                })
            }
        }
    }
</script>