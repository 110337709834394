<template>
    <!-- <navbar></navbar> -->
    <div class="p-2 sm:ml-64 mt-16">
        <div class="p-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <table class="mb-3">
                <tr>
                    <th class="text-left w-1/2">NOP</th>
                    <th>:</th>
                    <td class="text-left text-sm text-gray-500">{{dataHeader.nop}}</td>
                </tr>
                <tr>
                    <th class="text-left">Nama Wajib Pajak</th>
                    <th>:</th>
                    <td class="text-left text-sm text-gray-500">{{dataHeader.nama_wajib_pajak}}</td>
                </tr>
                <tr>
                    <th class="text-left">Alamat Wajib Pajak</th>
                    <th>:</th>
                    <td class="text-left text-sm text-gray-500">{{dataHeader.alamat_wajib_pajak}}</td>
                </tr>
                <tr>
                    <th class="text-left">Letak Objek Pajak</th>
                    <th>:</th>
                    <td class="text-left text-sm text-gray-500">{{dataHeader.letak_objek_pajak}}</td>
                </tr>
                <tr>
                    <th class="text-left">Luas Bumi</th>
                    <th>:</th>
                    <td class="text-left text-sm text-gray-500">{{dataHeader.luas_bumi}} m<sup>2</sup></td>
                </tr>
                <tr>
                    <th class="text-left">Luas Bangunan</th>
                    <th>:</th>
                    <td class="text-left text-sm text-gray-500">{{dataHeader.luas_bangunan}} m<sup>2</sup></td>
                </tr>
                <tr>
                    <th class="text-left">NJOP</th>
                    <th>:</th>
                    <td class="text-left text-sm text-gray-500">{{dataHeader.njop}}</td>
                </tr>
            </table>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-white bg-blue-600 dark:bg-blue-600 dark:text-white">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-center">
                                No
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Tahun
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Ketetapan
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Status
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Jatuh Tempo
                            </th>
                            <th scope="col" class="px-6 py-3 text-right">
                                Jumlah Bayar
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Tanggal Bayar
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                File
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, i) in dataTablePembayaranPbb" :key="i"
                            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                            <td class="px-6 py-4 text-center">
                                {{ i + 1 }}
                            </td>
                            <td class="px-6 py-4 text-center">
                                {{ row.tahun }}
                            </td>
                            <td class="px-6 py-4">
                                {{ row.ketetapan }}
                            </td>
                            <td class="px-6 py-4">
                                <span :class="[row.badge_class]">{{ row.status_document }}</span>
                            </td>
                            <td class="px-6 py-4 text-center">
                                {{ row.jatuh_tempo }}
                            </td>
                            <td class="px-6 py-4 text-right">
                                Rp. {{ formatPrice(row.jumlah_bayar) }}
                            </td>
                            <td class="px-6 py-4 text-center">
                                {{ row.tanggal_bayar }}
                            </td>
                            <td class="px-6 py-4 text-center">
                                <div class="flex justify-center space-x-1">
                                    <button v-if="row.status_document == 'Belum Terlaporkan'"
                                        @click="updateStatusDoc(row.id_details)">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                                        </svg>
                                    </button>
                                    <button
                                        @click="toPageFiles(row.document_id, row.document_folder_id, row.index_number)">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 00-1.883 2.542l.857 6a2.25 2.25 0 002.227 1.932H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-1.883-2.542m-16.5 0V6A2.25 2.25 0 016 3.75h3.879a1.5 1.5 0 011.06.44l2.122 2.12a1.5 1.5 0 001.06.44H18A2.25 2.25 0 0120.25 9v.776" />
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import Navbar from '@/components/navbar/Navbar.vue'
    export default {
        components: {
            Navbar
        },
        data() {
            return {
                dataHeader: {
                    nop: null,
                    nama_wajib_pajak: null,
                    alamat_wajib_pajak: null,
                    letak_objek_pajak: null,
                    luas_bumi: null,
                    luas_bangunan: null,
                    njop: null
                },
                dataTablePembayaranPbb: []
            }
        },
        created() {
            this.getDetailPembayaranPbb()
        },
        mounted() {
            // set the modal menu element
            const targetEl = document.getElementById('default-modal');

            // options with default values
            const options = {
                placement: 'center',
                backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40'
            };

            this.modal = new Modal(targetEl, options);
        },
        methods: {
            getDetailPembayaranPbb() {
                this.$http.post('/api/auth/getDataDetailPbb', {
                    no_shm: this.$route.query.shm,
                    nop: this.$route.query.nop
                }).then((response) => {
                    var resp = response.data.data.dataPembayaranPbb
                    if (resp.length != 0) {
                        this.dataHeader.nop = resp[0].nop
                        this.dataHeader.nama_wajib_pajak = resp[0].nama_wajib_pajak
                        this.dataHeader.alamat_wajib_pajak = resp[0].alamat_wajib_pajak
                        this.dataHeader.letak_objek_pajak = resp[0].letak_objek_pajak
                        this.dataHeader.luas_bumi = resp[0].luas_bumi
                        this.dataHeader.luas_bangunan = resp[0].luas_bangunan
                        this.dataHeader.njop = resp[0].njop
                    }

                    this.dataTablePembayaranPbb = resp
                }).catch((err) => {
                    console.log(err)
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            showModal() {
                // set the modal menu element
                const $targetEl = document.getElementById('default-modal');
                const modal = new Modal($targetEl);
                modal.show();
            },
            toggleModal() {
                this.modal.toggle();
            },
            toPageFiles(document_id, document_folder_id, index_number) {
                var page = this.$router.resolve({
                    name: 'document-folder-page',
                    params: {
                        parent_id: document_folder_id,
                        index_number: index_number + 1
                    },
                    query: {
                        id: document_id
                    }
                })
                window.open(page.href, '_blank')
            },
            updateStatusDoc(id_details) {
                this.$http.post('/api/auth/updateStatusDocumentDetails', {
                    id_details: id_details
                }).then((response) => {
                    this.$swal('Updated!', '', 'Document berhasil di update')
                    setInterval(() => {
                        location.reload()
                    }, 1000)
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            }
        }
    }
</script>