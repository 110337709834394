<template>
    <div class="h-full">
        <h5 class="text-left mb-2 font-bold pl-1 mt-1" v-if="dataDocument.length != 0">{{nama_dokumen}}</h5>
        <div class="h-full grid grid-cols-4 gap-4 mb-4" v-if="dataDocument.length != 0">
            <div class="col-span-3" v-for="(row, i) in dataDocument" :key="i">
                
                <div v-if="checkExtension(row.document_extension) == true">
                    <object :data="row.document_file" type="application/pdf" v-if="row.document_extension == 'PDF'" width="100%" height="600"></object>
                    <iframe v-if="row.document_extension != 'PDF'" type="application/pdf" id="document_viewer"
                        :src="checkDocExt(row.document_extension, row.document_file)" width="100%" height="600"
                        frameborder='0' class="rounded">
                        <p>This browser does not support PDF!</p>
                    </iframe>
                </div>
                <div v-else>
                    <div class="images" v-viewer="{navbar: false, toolbar: false}">
                        <img :key="row.document_file" :src="row.document_file" class="rounded h-auto max-w-lg mx-auto">
                    </div>
                    <!-- <img :src="row.document_file" class="rounded h-auto max-w-lg mx-auto"> -->
                </div>
            </div>

            <!-- Comment Bubble -->
            <div class="relative col-span-1">
                <div class="mb-2 rounded bg-gray-100 dark:bg-gray-100 p-2">
                    <ul>
                        <li class="flex justify-items-start item-center">
                            <button type="button" @click="updateItem()"
                                class="w-full flex items-center p-2 text-gray-900 hover:text-white text-sm rounded-lg dark:text-white hover:bg-blue-700 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-5 h-5 mr-2">
                                    <path fill-rule="evenodd"
                                        d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm6.905 9.97a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72V18a.75.75 0 001.5 0v-4.19l1.72 1.72a.75.75 0 101.06-1.06l-3-3z"
                                        clip-rule="evenodd" />
                                    <path
                                        d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
                                </svg>

                                Update Item
                            </button>
                            <input @change="loadFile($event.target)"
                                class="hidden block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                aria-describedby="update_file_help" id="update_file" type="file">
                        </li>
                        <!-- <li class="flex justify-items-start item-center">
                            <button type="button"
                                class="w-full flex items-center p-2 text-gray-900 hover:text-white text-sm rounded-lg dark:text-white hover:bg-blue-700 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-5 h-5 mr-2">
                                    <path fill-rule="evenodd"
                                        d="M19.5 21a3 3 0 003-3V9a3 3 0 00-3-3h-5.379a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H4.5a3 3 0 00-3 3v12a3 3 0 003 3h15zm-6.75-10.5a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V10.5z"
                                        clip-rule="evenodd" />
                                </svg>

                                Move Item
                            </button>
                        </li> -->
                        <li class="flex justify-items-start item-center">
                            <button type="button" @click="markAsFinal()"
                                class="w-full flex items-center p-2 text-gray-900 hover:text-white text-sm rounded-lg dark:text-white hover:bg-blue-700 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-5 h-5 mr-2">
                                    <path fill-rule="evenodd"
                                        d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5zm6.61 10.936a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                        clip-rule="evenodd" />
                                    <path
                                        d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                                </svg>

                                Mark As Final
                            </button>
                        </li>
                    </ul>
                </div>
                <h5 class="text-left mb-1">Comment</h5>
                <div class="mt-25 h-1/2 overflow-auto pb-2" id="comment-form">
                    <div v-for="(row, i) in dataComment" :key="i">
                        <div class="flex items-start gap-2.5 mb-2" v-if="row.is_sender == false">
                            <img class="w-8 h-8 rounded-full" :src="row.profile_foto" alt="Jese image">
                            <div
                                class="flex flex-col w-full max-w-[320px] leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
                                <div class="flex items-center space-x-2 rtl:space-x-reverse">
                                    <span
                                        class="text-left text-xs font-semibold text-gray-900 dark:text-white">{{row.nama}}</span>
                                    <span
                                        class="text-xs font-normal text-gray-500 dark:text-gray-400">{{row.date}}</span>
                                </div>
                                <p class="text-left text-xs font-normal py-2.5 text-gray-900 dark:text-white">
                                    {{row.comment}}</p>
                                <span
                                    class="text-right text-xs font-normal text-gray-500 dark:text-gray-400">{{row.waktu}}</span>
                            </div>
                        </div>

                        <div class="flex items-start gap-2.5 mb-2" v-else>
                            <div
                                class="flex flex-col w-full max-w-[320px] leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-l-xl rounded-br-xl dark:bg-gray-700">
                                <div class="flex items-center space-x-2 rtl:space-x-reverse">
                                    <span
                                        class="text-left text-xs font-semibold text-gray-900 dark:text-white">{{row.nama}}</span>
                                    <span
                                        class="text-right text-xs font-normal text-gray-500 dark:text-gray-400">{{row.date}}</span>
                                </div>
                                <p class="text-left text-xs font-normal py-2.5 text-gray-900 dark:text-white">
                                    {{row.comment}}</p>
                                <span
                                    class="text-right text-xs font-normal text-gray-500 dark:text-gray-400">{{row.waktu}}</span>
                            </div>
                            <img class="w-8 h-8 rounded-full" :src="row.profile_foto" alt="Jese image">
                        </div>
                    </div>

                </div>

                <!-- Comment Form -->
                <div class="sticky bottom-0 right-0 w-full">
                    <div
                        class="border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                        <div class="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
                            <label for="kolom-komentar" class="sr-only">Your comment</label>
                            <textarea id="kolom-komentar" rows="4" value=""
                                class="w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                placeholder="Write a comment..." required></textarea>
                        </div>
                        <div class="flex items-center justify-between px-3 py-2 border-t dark:border-gray-600">
                            <button @click="send_message()"
                                class="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800">
                                Post comment
                            </button>
                            <div class="flex ps-0 space-x-1 rtl:space-x-reverse sm:ps-2">
                                <button type="button"
                                    class="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 12 20">
                                        <path stroke="currentColor" stroke-linejoin="round" stroke-width="2"
                                            d="M1 6v8a5 5 0 1 0 10 0V4.5a3.5 3.5 0 1 0-7 0V13a2 2 0 0 0 4 0V6" />
                                    </svg>
                                    <span class="sr-only">Attach file</span>
                                </button>
                                <button type="button"
                                    class="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 16 20">
                                        <path
                                            d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                                    </svg>
                                    <span class="sr-only">Set location</span>
                                </button>
                                <button type="button"
                                    class="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 18">
                                        <path
                                            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                                    </svg>
                                    <span class="sr-only">Upload image</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'viewerjs/dist/viewer.css'
    import {
        directive as viewer
    } from "v-viewer"
    import Navbar from '@/components/navbar/Navbar.vue'

    export default {
        directives: {
            viewer: viewer({
                debug: false
            })
        },
        components: {
            Navbar
        },
        data() {
            return {
                document_id: this.$route.params.document_id,
                token: localStorage.getItem("storageTokenKeyName"),
                fileUrl: '',
                fileObj: null,
                fileSize: 0,
                fileType: '',
                counter: 1,
                dataDocument: [],
                dataComment: [],
                connection_ready: false,
                connection_error: false,
                websocket: null,
                komentar_baru: "",
                nama_dokumen : ""
            }
        },
        created() {
            this.getDataFile();
        },
        mounted() {
            this.init_chat();
        },
        methods: {
            getDataFile() {
                this.$http.post('/api/auth/getDataFileUrl', {
                    document_id: this.document_id
                }).then((response) => {
                    if (response.data.data) {
                        this.dataDocument = response.data.data.dataDoc
                        this.nama_dokumen = response.data.data.dataDoc[0].document_name
                        var datakomen = response.data.data.dataComment
                        datakomen.forEach(element => {
                            var objMessage = {
                                is_sender: localStorage.getItem("id") == element.user_id ? true : false,
                                user_id: element.user_id,
                                nama: element.nama,
                                profile_foto: element.profile_foto,
                                date: element.date,
                                waktu: element.waktu,
                                comment: element.comment
                            }
                            this.dataComment.push(objMessage)
                        });
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            updateItem() {
                document.getElementById("update_file").click();
            },
            loadFile(event) {
                this.fileUrl = URL.createObjectURL(event.files[0]);
                this.fileObj = event.files[0];
                this.fileSize = event.files[0].size;

                var name_file = event.files[0].name;
                var myArray = name_file.split(".");
                var fileExt = myArray[1]

                this.fileType = fileExt
                this.counter = 1
                var dataUpdateItem = new FormData()
                dataUpdateItem.append('document_id', this.document_id)
                dataUpdateItem.append('file', this.fileObj)
                let thisPage = this
                this.$http.post('api/auth/saveFile', dataUpdateItem, {
                    onUploadProgress: function (progressEvent) {
                        let {
                            loaded,
                            total
                        } = progressEvent;

                        let counterProgress = Math.floor(((loaded * 100) / total) - 5);
                        thisPage.counter = counterProgress
                    }
                }).then(response => {
                    if (response.data.status == "Success") {
                        this.counter = 100;
                        this.busy = false;
                        this.processing = false;
                        this.$swal({
                            icon: 'success',
                            title: 'Berhasil!',
                            text: 'Document berhasil di upload',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                location.reload()
                            }
                        })
                    }
                })
            },
            markAsFinal() {
                this.$http.post('/api/auth/updateStatusDocument', {
                    nama: localStorage.getItem("nama"),
                    document_id: this.document_id,
                    flag_status: 5
                }).then((response) => {
                    this.$swal('Updated!', '', 'File anda berhasil di update')
                    setInterval(() => {
                        location.reload()
                    }, 2000)
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            init_chat() {
                //connect to Sockets Bay
                var sockets_bay_url = `wss://legal.rsii.id/ws/auth/comment/` + this.document_id + `?token=` + this
                    .token + ``;
                this.websocket = new WebSocket(sockets_bay_url);
                //
                this.websocket.onopen = this.onSocketOpen;
                this.websocket.onmessage = this.onSocketMessage;
                this.websocket.onerror = this.onSockerError;
            },
            onSocketOpen(evt) {
                this.connection_ready = true;
            },
            onSocketMessage(evt) {
                //we parse the json that we receive
                var message = JSON.parse(evt.data);
                var objMessage = {
                    is_sender: localStorage.getItem("id") == message.user_id ? true : false,
                    user_id: message.user_id,
                    nama: message.nama,
                    profile_foto: message.profile_foto,
                    date: message.date,
                    waktu: message.waktu,
                    comment: message.comment
                }
                //check if it's our message or from a friend
                this.dataComment.push(objMessage);
                //scroll to the bottom of the messages div
                setTimeout(() => {
                    var objDiv = document.getElementById("comment-form");
                    objDiv.scroll({
                        top: objDiv.scrollHeight,
                        behavior: 'smooth'
                    });
                }, 0)
            },
            onSockerError(evt) {
                this.connection_error = true;
            },
            send_message() {
                var today = new Date()
                var dateFormat = today.toLocaleDateString("en-US")
                var hour = this.addZero(today.getHours())
                var minutes = this.addZero(today.getMinutes())
                var komentar_baru = document.getElementById("kolom-komentar").value;

                var to_send = {
                    document_id: this.document_id,
                    user_id: localStorage.getItem('id'),
                    nama: localStorage.getItem('nama'),
                    profile_foto: localStorage.getItem('foto_profil'),
                    date: dateFormat,
                    waktu: hour + ":" + minutes,
                    comment: komentar_baru
                }
                this.websocket.send(JSON.stringify(to_send))
            },
            addZero(i) {
                if (i < 10) {
                    i = "0" + i
                }
                return i;
            },
            checkExtension(ext) {
                var arrDoc = ['PDF', 'DOC', 'DOCX', 'XLSX', 'PPT', 'PPTX', 'XLS']
                var check = arrDoc.includes(ext)
                return check
            },
            checkDocExt(ext, urlFile) {
                var urlOffice = 'https://view.officeapps.live.com/op/embed.aspx?src='
                var urlGoogle = 'https://docs.google.com/viewer?embedded=true&url='
                if (ext == 'PDF') {
                    var url = urlGoogle + urlFile
                } else {
                    var url = urlOffice + urlFile
                }

                return url
            }
        }
    }
</script>