<template>
  <div class="leading-normal tracking-normal text-white gradient" style="font-family: 'Source Sans Pro', sans-serif;">
    <navbar-landing-page></navbar-landing-page>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import NavbarLandingPage from '@/components/navbar/NavbarLandingPage.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    NavbarLandingPage
  }
}
</script>
