<template>
    <!-- <div tabindex="-1" id="modal-regardoc"
        class="ease-in transition duration-150 bg-gray-100 shadow-2xl w-1/2 fixed top-16 z-50 ml-52 rounded-lg border pb-5"
        v-if="viewModal" ref="modal_buat_dokumen">
        <component :is="componentName" @closeModal="closeModal" :document_id="document_id"></component>
    </div> -->

    <!-- Modal toggle -->
    <button id="button-show-modal" ref="button-show-modal" data-modal-target="authentication-modal" @click="modalShow()"
        class="block hidden text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button">
        Toggle modal
    </button>

    <!-- Main modal -->
    <div id="authentication-modal" tabindex="-1" aria-hidden="true"
        class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative w-full max-w-lg max-h-full mt-2">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-2 md:p-2 border-b rounded-t dark:border-gray-600">
                    <h3 class="ml-5 text-xl font-medium text-gray-900 dark:text-white">
                        Dokumen
                    </h3>
                    <button type="button" @click="closeModal"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="authentication-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!--Modal Content--->
                <div class="px-2 py-6 lg:px-2">
                    <component v-if="componentName != ''" :is="componentName" @closeModal="closeModal"
                        :document_id="document_id"></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DocumentCreationModal from '@/views/document/components/DocumentCreationModal.vue'
    import FolderCreationModal from '@/views/document/components/FolderCreationModal.vue'
    import DocumentEditModal from '@/views/document/components/DocumentEditModal.vue'
    import DocumentDetailModal from '@/views/document/components/DocumentDetailModal.vue'
    import DocumentShareModal from '@/views/document/components/DocumentShareModal.vue'
    export default {
        components: {
            DocumentCreationModal,
            FolderCreationModal,
            DocumentEditModal,
            DocumentDetailModal,
            DocumentShareModal
        },
        props: {
            viewModal: Boolean,
            componentName: String,
            document_id: Number

        },
        emits: ["closeModal"],
        watch: {
            viewModal: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    if (newValue == true) {
                        document.getElementById("button-show-modal").click();
                    }
                }
            }
        },
        methods: {
            closeModal() {
                this.$emit('closeModal')
            },
            modalShow() {
                const $modalElement = document.querySelector('#authentication-modal');

                const modalOptions = {
                    placement: 'center',
                    backdrop: 'static',
                    onHide: () => {
                        // console.log('modal is hidden');
                    },
                    onShow: () => {
                        // console.log('modal is shown');
                    },
                    onToggle: () => {
                        // console.log('modal has been toggled');
                    }
                }

                const modal = new Modal($modalElement, modalOptions);

                modal.show();
            }
        }

    }
</script>