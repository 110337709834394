<template>
    <div class="p-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <h6 class="text-md text-left font-bold tracking-tight text-gray-900 dark:text-white">Document Statistic</h6>
        <hr class="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700">
        <div>
            <div
                class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul class="flex flex-nowrap -mb-px">
                    <li class="mr-2" @click="setTabActive('tabLevel', 'Statisik Level')">
                        <a :class="[tabActive.tabLevel ? tabClass.tabActive : tabClass.tabNonaktive]">Level</a>
                    </li>
                    <li class="mr-2" @click="setTabActive('tabPrioritas', 'Statisik Prioritas')">
                        <a :class="[tabActive.tabPrioritas ? tabClass.tabActive : tabClass.tabNonaktive]"
                            aria-current="page">Prioritas</a>
                    </li>
                    <li class="mr-2" @click="setTabActive('tabJenis', 'Statisik Jenis')">
                        <a :class="[tabActive.tabJenis ? tabClass.tabActive : tabClass.tabNonaktive]"
                            aria-current="page">Jenis</a>
                    </li>
                </ul>
            </div>

            <div class="relative overflow-x-auto sm:rounded-lg">

                <div class="max-w-sm w-full mx-auto rounded-lg shadow p-4 md:p-6">

                    <div class="flex justify-between items-start w-full">
                        <div class="flex-col items-center">
                            <div class="flex items-center mb-1">
                                <h5 class="text-xl font-bold leading-none text-gray-900 dark:text-white mr-1">
                                    {{ chartHeader }}</h5>
                                <svg data-popover-target="chart-info" data-popover-placement="bottom"
                                    class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white cursor-pointer ml-1"
                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                    viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0 0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z" />
                                </svg>
                                <div data-popover id="chart-info" role="tooltip"
                                    class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
                                    <div class="p-3 space-y-2">
                                        <h3 class="font-semibold text-gray-900 dark:text-white">{{ chartHeader }}</h3>
                                        <p>Report ini berdasarkan {{ chartHeader }} dari dokumen yang di upload oleh
                                            pengguna.</p>
                                    </div>
                                    <div data-popper-arrow></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Line Chart -->
                    <!-- <div v-if="tabActive.tabLevel" class="py-6" id="chart-level"></div> -->
                    <!-- <div v-if="tabActive.tabPrioritas" class="py-6" id="chart-prioritas"></div>
                    <div v-if="tabActive.tabJenis" class="py-6" id="chart-jenis"></div> -->
                    <div class="chart-container" style="position: relative; height:40vh; width:100%">
                        <canvas v-if="tabActive.tabLevel" id="chart-level" class="h-60"></canvas>
                        <canvas v-if="tabActive.tabPrioritas" id="chart-prioritas"></canvas>
                        <canvas v-if="tabActive.tabJenis" id="chart-jenis"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tabActive: {
                    tabLevel: true,
                    tabPrioritas: false,
                    tabJenis: false
                },
                tabClass: {
                    tabActive: 'inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500',
                    tabNonaktive: 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                },
                chartHeader: 'Level Statistik',
                dataChartLevel: {
                    series: [],
                    color: [],
                    label: []
                },
                dataChartPrioritas: {
                    series: [],
                    color: [],
                    label: []
                },
                dataChartJenis: {
                    series: [],
                    color: [],
                    label: []
                }
            }
        },
        mounted() {
            this.$nextTick(
                this.getChartLevel()
            )
        },
        methods: {
            setTabActive(tabName, chartHeader) {
                this.tabActive = Object.assign(Object.keys(this.tabActive).map(k => ({
                    [k]: false
                })));
                this.tabActive[tabName] = true
                this.chartHeader = chartHeader
                if (tabName == 'tabLevel') {
                    this.getChartLevel()
                } else if (tabName == 'tabPrioritas') {
                    this.getChartPrioritas()
                } else if (tabName == 'tabJenis') {
                    this.getChartJenis()
                }
            },
            getChartLevel() {
                this.$http.get('/api/auth/getDataChartByLevel').then((response) => {
                    if (response.data.data.dataChartByLevel) {
                        var dataRes = response.data.data.dataChartByLevel
                        this.loadChartLevel(dataRes)
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi! ' + err,
                    })
                })


            },
            getChartPrioritas() {
                this.$http.get('/api/auth/getDataChartByPrioritas').then((response) => {
                    var dataRes = response.data.data.dataChartByPrioritas
                    this.loadChartPrioritas(dataRes)
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi! ' + err,
                    })
                })
            },
            getChartJenis() {
                this.$http.get('/api/auth/getDataChartByJenis').then((response) => {
                    var dataRes = response.data.data.dataChartByJenis
                    this.loadChartJenis(dataRes)
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi! ' + err,
                    })
                })
            },
            loadChartLevel(payload) {
                var series = []
                var color = []
                var label = []
                for (const el of payload) {
                    series.push(el.total)
                    color.push(el.color_hex)
                    label.push(el.document_level)
                }
                const ctx = document.getElementById('chart-level');
                const data = {
                    labels: label,
                    datasets: [{
                        label: 'Level Dokumen',
                        data: series,
                        backgroundColor: color,
                        hoverOffset: 4
                    }]
                };

                const config = {
                    type: 'pie',
                    data: data,
                    options: {
                        plugins: {
                            legend: {
                                position: "right"
                            }
                        }
                    }
                };

                new Chart(ctx, config);
            },
            loadChartPrioritas(payload) {
                var series = []
                var color = []
                var label = []
                for (const el of payload) {
                    series.push(el.total)
                    color.push(el.color_hex)
                    label.push(el.document_prioritas)
                }
                const ctx = document.getElementById('chart-prioritas');
                const data = {
                    labels: label,
                    datasets: [{
                        label: 'Prioritas Dokumen',
                        data: series,
                        backgroundColor: color,
                        hoverOffset: 4
                    }]
                };

                const config = {
                    type: 'pie',
                    data: data,
                    options: {
                        plugins: {
                            legend: {
                                position: "right"
                            }
                        }
                    }
                };

                new Chart(ctx, config);
            },
            loadChartJenis(payload) {
                var series = []
                var color = []
                var label = []
                for (const el of payload) {
                    series.push(el.total)
                    color.push(el.color_hex)
                    label.push(el.document_type)
                }
                const ctx = document.getElementById('chart-jenis');
                const data = {
                    labels: label,
                    datasets: [{
                        label: 'Jenis Dokumen',
                        data: series,
                        backgroundColor: color,
                        hoverOffset: 4
                    }]
                };

                const config = {
                    type: 'pie',
                    data: data,
                    options: {
                        plugins: {
                            legend: {
                                position: "right"
                            }
                        }
                    }
                };

                new Chart(ctx, config);
            }
        }
    }
</script>