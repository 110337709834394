import {
    createApp
} from 'vue'
import VueAxios from 'vue-axios';
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import axiosInstance from './libs/axios';
import 'flowbite';

//3rd party
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App)
app.use(router)
app.use(VueAxios, axiosInstance)
app.use(VueSweetalert2)

app.mount('#app')