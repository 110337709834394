<template>
    <div v-if="form1">
        <div class="px-14 mt-2 overflow-auto">
            <div>
                <label for="nama_dokumen"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Nama
                    Dokumen <span class="text-red-700">*</span></label>
                <div class="relative mb-2">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                            class="w-4 h-4 text-gray-500 dark:text-white">
                            <path fill-rule="evenodd"
                                d="M7.502 6h7.128A3.375 3.375 0 0118 9.375v9.375a3 3 0 003-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 00-.673-.05A3 3 0 0015 1.5h-1.5a3 3 0 00-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6zM13.5 3A1.5 1.5 0 0012 4.5h4.5A1.5 1.5 0 0015 3h-1.5z"
                                clip-rule="evenodd" />
                            <path fill-rule="evenodd"
                                d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V9.375zM6 12a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V12zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM6 15a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V15zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM6 18a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V18zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z"
                                clip-rule="evenodd" />
                        </svg>

                    </div>
                    <input type="text" id="nama_dokumen" v-model="dataForm1.nama_dokumen"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Dokumen Legal">
                </div>
            </div>

            <div>
                <label for="tanggal_expired"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Tanggal
                    Expired</label>
                <div class="relative mb-2">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                            class="w-4 h-4 text-gray-500 dark:text-white">
                            <path
                                d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                            <path fill-rule="evenodd"
                                d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                                clip-rule="evenodd" />
                        </svg>

                    </div>
                    <input type="datetime-local" id="tanggal_expired" v-model="dataForm1.tanggal_expired"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                </div>
            </div>

            <div>
                <label for="deskripsi"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Deskripsi <span class="text-red-700">*</span></label>
                <div class="relative mb-2">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                            class="w-4 h-4 text-gray-500 dark:text-white">
                            <path fill-rule="evenodd"
                                d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                    <textarea type="datetime-local" id="deskripsi" v-model="dataForm1.deskripsi"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Tulis deskripsi disini..."></textarea>
                </div>
            </div>

            <div>
                <label for="informasi_tambahan"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Informasi
                    Tambahan </label>
                <div class="relative mb-2">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                            class="w-4 h-4 text-gray-500 dark:text-white">
                            <path fill-rule="evenodd"
                                d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                    <textarea type="datetime-local" id="informasi_tambahan" v-model="dataForm1.informasi_tambahan"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Tulis informasi tambahan disini..."></textarea>
                </div>
            </div>

            <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 w-full mb-2 group">
                    <label for="level_dokumen"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Level
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-6">
                        <select id="level_dokumen" v-model="dataForm1.level_dokumen"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option selected value="0">Pilih</option>
                            <option v-for="(row, i) in dataLevelDoc" :key="i" v-bind:value="row.level_id">
                                {{row.level_name}}</option>
                        </select>
                    </div>
                </div>
                <div class="relative z-0 w-full mb-2 group">
                    <label for="prioritas_dokumen"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Prioritas
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-6">
                        <select id="prioritas_dokumen" v-model="dataForm1.prioritas_dokumen"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option selected value="0">Pilih</option>
                            <option v-for="(row, i) in dataPriorityDoc" :key="i" v-bind:value="row.priority_id">
                                {{row.priority_name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            
            <div class="grid md:grid-cols-3 md:gap-6">
                <div class="relative z-0 w-full mb-2 group">
                    <label for="kategori"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Kategori
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-6">
                        <select id="kategori" @change="getKategori2" v-model="dataForm1.jenis_dokumen"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option selected value="0">Pilih</option>
                            <option v-for="(row, i) in dataTypeDoc" :key="i" v-bind:value="row.type_id">
                                {{row.type_name}}</option>
                        </select>
                    </div>
                </div>
                <div class="relative z-0 w-full mb-2 group">
                    <label for="kategori_2"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Kategori 2
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-6">
                        <select id="kategori_2" @change="getKategori3" v-model="dataForm1.kategori_2_id"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option selected value="0">Pilih</option>
                            <option v-for="(row, i) in dataKategori2" :key="i" v-bind:value="row.type_id">
                                {{row.type_name}}</option>
                        </select>
                    </div>
                </div>
                <div class="relative z-0 w-full mb-2 group">
                    <label for="kategori_2"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Kategori 3
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-6">
                        <select id="kategori_2" v-model="dataForm1.kategori_3_id"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option selected value="0">Pilih</option>
                            <option v-for="(row, i) in dataKategori3" :key="i" v-bind:value="row.type_id">
                                {{row.type_name}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="flex justify-end py-2 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button type="button" @click="openForm2"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700">Lanjut</button>
            </div>
        </div>
    </div>

    <div v-if="form2">
        <div class="px-14 mt-2 overflow-auto">
            <div>
                <label for="input-group-2"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Tambahkan
                    hak akses ke orang lain</label>
                <div class="relative mb-2">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                            class="w-4 h-4 text-gray-500 dark:text-white">
                            <path
                                d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                        </svg>
                    </div>
                    <input type="text" id="add_access_person" v-model="accessPerson" @keyup="getPersonAccess"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Tambahkan orang">

                    <ul id="right-click-access" tabindex="1" ref="add_access" class="rounded w-auto" v-if="viewAddMenu">
                        <li class="p-2" v-for="(row, i) in dataAccessPerson" :key="i"
                            @click="updateUserAccess(row.id, row.nama_lengkap, row.email, row.role)">
                            <div class="flex items-left space-x-4">
                                <div class="flex-shrink-0">
                                    <img class="w-9 h-9 rounded-full" :src="row.foto_profil" alt="Neil image"
                                        onerror="this.onerror=null;this.src='http://localhost:8080/images/account_image.png';">
                                </div>
                                <div class="flex-1 px-0">
                                    <p class="text-sm text-left font-medium text-gray-900 dark:text-gray-900">
                                        {{row.nama_lengkap}}
                                    </p>
                                    <p class="text-sm text-left text-gray-500 dark:text-gray-900">
                                        {{row.email}}
                                    </p>
                                </div>
                                <div
                                    class="text-sm inline-flex items-center font-semibold text-gray-900 dark:text-gray-900">
                                    {{row.role}}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div>
                <h5 class="text-md font-semibold text-gray-950 dark:text-gray-950 text-left mb-2">
                    Orang yang memiliki akses
                </h5>

                <ul class="max-w-md divide-y divide-gray-200 dark:divide-gray-700">
                    <li class="pb-3 sm:pb-4" v-for="(row, i) in dataPemilikDoc" :key="i">
                        <div class="flex items-left space-x-4 mt-2">
                            <div class="flex-shrink-0">
                                <img class="w-9 h-9 rounded-full" :src="row.foto_profil" alt="Neil image">
                            </div>
                            <div class="flex-1 min-w-0 px-0">
                                <p class="text-sm text-left font-medium text-gray-900 truncate dark:text-gray-900">
                                    {{row.nama_lengkap}}
                                </p>
                                <p class="text-sm text-left text-gray-500 truncate dark:text-gray-900">
                                    {{row.email}}
                                </p>
                            </div>
                            <div v-if="i == 0"
                                class="text-sm inline-flex items-center font-semibold text-gray-900 dark:text-gray-900">
                                Pemilik
                            </div>
                            <div v-else class="text-sm inline-flex items-center">
                                <select id="user_doc_permision" @change="changePermision($event, i)"
                                    v-model="row.user_doc_permision"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option selected value="1">Editor</option>
                                    <option value="2">Pelihat</option>
                                </select>
                            </div>
                        </div>
                    </li>
                </ul>

            </div>
            <div class="flex justify-end py-2 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button @click="openForm1" data-modal-hide="staticModal" type="button"
                    class="text-gray-500 bg-white hover:bg-gray-100 focus:outline-none rounded-lg border border-gray-200 text-sm font-medium px-5 py-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">Batal</button>
                <button type="button" @click="simpanDokumen"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-small rounded-lg text-sm px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Simpan</button>
            </div>
        </div>
    </div>
</template>
<style scoped>
    #right-click-access {
        background: #FAFAFA;
        border: 1px solid #BDBDBD;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        z-index: 999999;
        outline: none;
    }

    #right-click-access li {
        border-bottom: 1px solid #E0E0E0;
        margin: 0;
        padding: 10px 10px;
    }

    #right-click-access li:last-child {
        border-bottom: none;
    }

    #right-click-access li:hover {
        background: #1E88E5;
        color: #FAFAFA;
    }
</style>
<script>
    export default {
        data() {
            return {
                form1: true,
                form2: false,
                dataLevelDoc: [],
                dataPriorityDoc: [],
                dataTypeDoc: [],
                dataKategori2: [],
                dataKategori3: [],
                dataForm1: {
                    nama_dokumen: null,
                    tanggal_expired: null,
                    deskripsi: null,
                    informasi_tambahan: null,
                    level_dokumen: 0,
                    prioritas_dokumen: 0,
                    jenis_dokumen: 0,
                    kategori_2_id: 0,
                    kategori_3_id: 0,
                    folder_dokumen: 0
                },
                dataPemilikDoc: [],
                viewAddMenu: false,
                accessPerson: '',
                dataAccessPerson: []
            }
        },
        props: {
            document_id: Number
        },
        emits: ["closeModal"],
        created() {
            this.getDataLevel()
        },
        methods: {
            getDataLevel() {
                this.$http.get('/api/auth/getDataLevelDoc').then((response) => {
                    this.dataLevelDoc = response.data.data.data_level ? response.data.data.data_level : []
                    this.dataPriorityDoc = response.data.data.data_priority ? response.data.data.data_priority :
                        []
                    this.dataTypeDoc = response.data.data.data_type ? response.data.data.data_type : []
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            openForm2() {
                this.form2 = true
                this.form1 = false
                this.dataPemilikDoc = []

                this.$http.get('/api/auth/getDataUserDetails').then((response) => {
                    var data = {
                        id: response.data.data.id,
                        nama_lengkap: response.data.data.nama_lengkap,
                        email: response.data.data.email,
                        role: response.data.data.role,
                        user_doc_permision: 1,
                        foto_profil: response.data.data.foto_profil
                    }
                    this.dataPemilikDoc.push(data)
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            openForm1() {
                this.form2 = false
                this.form1 = true
            },
            simpanDokumen() {
                if (this.dataForm1.nama_dokumen == null || this.dataForm1.deskripsi == null || this.dataForm1
                    .level_dokumen == 0 || this.dataForm1.kategori_2_id == 0 || this.dataForm1.kategori_3_id == 0
                    || this.dataForm1.prioritas_dokumen == 0) {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Silahkan lengkapi data terlebih dahulu!',
                    })
                } else {
                    this.dataForm1.folder_dokumen = this.$route.params.parent_id
                    this.form2 = false
                    this.form1 = true

                    this.$http.post('/api/auth/saveDataDoc', {
                        dataForm1: this.dataForm1,
                        dataPemilikDoc: this.dataPemilikDoc
                    }).then((response) => {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Mantap',
                            text: 'Berhasil simpan data',
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                this.dataForm1 = {
                                    nama_dokumen: null,
                                    tanggal_expired: null,
                                    deskripsi: null,
                                    informasi_tambahan: null,
                                    level_dokumen: 0,
                                    kategori_2_id: 0,
                                    kategori_3_id: 0,
                                    prioritas_dokumen: 0,
                                    folder_dokumen: 0
                                }
                                this.$emit('closeModal')
                                this.$emit('getDataDocument')
                            }
                        })
                    }).catch((err) => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                        })
                    })
                }
            },
            getPersonAccess() {
                var lengtKeyword = this.accessPerson.length

                if (lengtKeyword >= 3) {
                    this.viewAddMenu = true
                    this.$http.post('/api/auth/getDataUserAccess', {
                        keyword: this.accessPerson
                    }).then((response) => {
                        this.dataAccessPerson = response.data.data
                    }).catch((err) => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                        })
                    })
                } else {
                    this.viewAddMenu = false
                }
            },
            updateUserAccess(id, nama_lengkap, email, role) {
                this.viewAddMenu = false
                var data = {
                    id: id,
                    nama_lengkap: nama_lengkap,
                    email: email,
                    role: role,
                    user_doc_permision: 1
                }
                if (this.dataPemilikDoc.filter(e => e.id === id).length == 0) {
                    this.dataPemilikDoc.push(data)
                }

            },
            changePermision(e, i) {
                this.dataPemilikDoc[i].user_doc_permision = parseInt(e.target.value)
            },
            getKategori2() {
                var kategori = this.dataForm1.jenis_dokumen

                this.$http.get(`/api/auth/getDataKategori2?kategori_id=${kategori}`).then((response) => {
                    this.dataKategori2 = response.data.data.data
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            getKategori3() {
                var kategori_2_id = this.dataForm1.kategori_2_id

                this.$http.get(`/api/auth/getDataKategori3?kategori_2_id=${kategori_2_id}`).then((response) => {
                    this.dataKategori3 = response.data.data.data
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            }
        }
    }
</script>