<template>
    <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
        <a v-if="form2" @click="openForm(1)">
            <div class="p-1 max-w-fit rounded-lg mb-2 text-left hover:bg-gray-300 mb-2">
                <span
                    class="text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400">
                    <svg class="w-3 h-3 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                        height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M5 12h14M5 12l4-4m-4 4 4 4" />
                    </svg>
                    Kembali
                </span>
            </div>
        </a>
        Tambah Aset
    </h3>
    <form class="max-w-md mx-auto" @submit.prevent="simpanDataAset">
        <div v-if="form1">
            <div class="grid grid-cols-2 gap-2">
                <div class="mb-5">
                    <label for="jenis_aset"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white">Jenis
                        aset</label>
                    <select id="jenis_aset" v-model="dataFormAset.jenis_aset"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="0" selected>Pilih</option>
                        <option v-for="(ast, iast) in dataJenisAset" :key="iast" v-bind:value="ast.jenis_aset_id">
                            {{ast.jenis_aset}}</option>
                    </select>
                </div>
                <div class="mb-5">
                    <label for="entitas"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white">Perusahaan</label>
                    <select id="entitas" v-model="dataFormAset.entitas"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="0" selected>Pilih</option>
                        <option v-for="(ent, ient) in dataEntitas" :key="ient" v-bind:value="ent.entitas_id">
                            {{ent.entitas}}</option>
                    </select>
                </div>
                <div class="mb-5">
                    <label for="divisi"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Divisi</label>
                    <input type="text" id="divisi" v-model="dataFormAset.divisi"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: Printing" style="text-transform: capitalize" required />
                    <!-- <label for="divisi"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white">Divisi</label>
                    <select id="divisi" v-model="dataFormAset.divisi"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="0" selected>Pilih</option>
                        <option v-for="(div, idiv) in dataDivisi" :key="idiv" v-bind:value="div.role_id">{{div.divisi}}
                        </option>
                    </select> -->
                </div>
                <div class="mb-5">
                    <label for="nama_user"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Nama user</label>
                    <input type="text" id="nama_user" v-model="dataFormAset.nama_user"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: User A" style="text-transform: capitalize" required />
                </div>
                <div class="mb-5">
                    <label for="nama_aset"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Nama aset</label>
                    <input type="text" id="nama_aset" v-model="dataFormAset.nama_aset"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: Handphone" style="text-transform: capitalize" required />
                </div>
                <div class="mb-5">
                    <label for="brand"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Brand</label>
                    <input type="text" id="brand" v-model="dataFormAset.brand"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: Samsung" style="text-transform: capitalize" required />
                </div>
                <div class="mb-5">
                    <label for="tipe"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Tipe</label>
                    <input type="text" id="tipe" v-model="dataFormAset.tipe"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: A52" />
                </div>
                <div class="mb-5">
                    <label for="seri"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Seri</label>
                    <input type="text" id="seri" v-model="dataFormAset.seri"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: SM-A052D" />
                </div>
                <div class="mb-5">
                    <label for="serial_number"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Serial
                        number</label>
                    <input type="text" id="serial_number" v-model="dataFormAset.serial_number"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: 123456789" />
                </div>
                <div class="mb-5">
                    <label for="tahun_produksi"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white">Tahun
                        produksi</label>
                    <select id="tahun_produksi" v-model="dataFormAset.tahun_produksi"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="null" selected>Pilih</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                    </select>
                </div>
                <div class="mb-5">
                    <label for="tahun_kedatangan"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white">Tahun
                        kedatangan</label>
                    <select id="tahun_kedatangan" v-model="dataFormAset.tahun_kedatangan"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="null" selected>Pilih</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                    </select>
                </div>
                <div class="mb-5">
                    <label for="harga_pembelian"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Harga
                        pembelian</label>
                    <input type="number" id="harga_pembelian" v-model="dataFormAset.harga_pembelian"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: 200000" />
                </div>
                <div class="mb-5">
                    <label for="vendor"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Vendor</label>
                    <input type="text" id="vendor" v-model="dataFormAset.vendor"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: Victor" />
                </div>
                <div class="mb-5">
                    <label for="status"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white">Status</label>
                    <select id="status" v-model="dataFormAset.status"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="0" selected>Pilih</option>
                        <option value="1">Aktif</option>
                        <option value="2">Tidak Aktif</option>
                        <option value="3">Red Off</option>
                        <option value="4">Rusak</option>
                    </select>
                </div>
                <div class="mb-5">
                    <label for="kondisi"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Kondisi</label>
                    <input type="number" id="kondisi" v-model="dataFormAset.kondisi"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: 0-100" required />
                </div>
                <div class="mb-5">
                    <label for="lokasi"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Lokasi</label>
                    <input type="text" id="lokasi" v-model="dataFormAset.lokasi"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: Kantor Paviliun" style="text-transform: capitalize" required />
                    <!-- <label for="lokasi"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white">Lokasi</label>
                    <select id="lokasi" v-model="dataFormAset.lokasi"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="0" selected>Pilih</option>
                        <option v-for="(loc, iloc) in dataLokasi" :key="iloc" v-bind:value="loc.lokasi_id">
                            {{loc.lokasi}}
                        </option>
                    </select> -->
                </div>
                <div class="mb-5">
                    <label for="byod"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white">BYOD</label>
                    <select id="byod" v-model="dataFormAset.byod"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="1">Ya</option>
                        <option value="0">Tidak</option>
                    </select>
                </div>
                <div class="mb-5">
                    <label for="waktu_penyerahan"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Waktu
                        penyerahan</label>
                    <input type="date" id="waktu_penyerahan" v-model="dataFormAset.waktu_penyerahan"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: 0-100" />
                </div>
            </div>
            <div class="mb-5">
                <label for="legalitas"
                    class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Legalitas</label>
                <input type="text" id="legalitas" v-model="dataFormAset.legalitas"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Cth: BPKB" />
            </div>
            <div class="mb-5">
                <label for="informasi_tambahan"
                    class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Informasi
                    tambahan</label>
                <textarea id="informasi_tambahan" rows="4" v-model="dataFormAset.informasi_tambahan"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Tulis deskripsi disini..."></textarea>
            </div>
            <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700">
            <div class="mb-5">
                <a @click="openForm(2)">
                    <div class="p-1 rounded-lg mb-2 text-left hover:bg-gray-300 mb-2">
                        <span
                            class="text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400">
                            <svg class="w-3 h-3 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            Tambah Kelengkapan
                        </span>
                    </div>
                </a>

            </div>
        </div>
        <div v-if="form2">
            <div class="mb-5">
                <ul
                    class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
                    <li class="me-2">
                        <a @click="checkTabBarang(1)" aria-current="page"
                            :class="checkSourceBarang ? classTab.classTabActive : classTab.classTabNonactive">Ambil dari
                            gudang</a>
                    </li>
                    <li class="me-2">
                        <a @click="checkTabBarang(2)"
                            :class="!checkSourceBarang ? classTab.classTabActive : classTab.classTabNonactive">Input
                            baru</a>
                    </li>
                </ul>
            </div>
            <div class="grid grid-cols-2 gap-2">
                <div class="mb-5" v-if="checkSourceBarang">
                    <label for="aset_gudang_id"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white">Nama
                        Barang</label>
                    <select id="aset_gudang_id" v-model="dataFormPelengkap.aset_gudang_id"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="0" selected>Pilih</option>
                        <option v-for="(ast, iast) in dataBarangGudang" :key="iast"
                            v-bind:value="ast.aset_pelengkap_id">
                            {{ast.nama_barang}}|{{ast.merk}}</option>
                    </select>
                </div>
                <div class="mb-5" v-if="!checkSourceBarang">
                    <label for="nama_barang"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Nama
                        Barang</label>
                    <input type="text" id="nama_barang" v-model="dataFormPelengkap.nama_barang"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: Mouse Logitech" required />
                </div>
                <div class="mb-5">
                    <label for="status_pelengkap"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white">Status</label>
                    <select id="status_pelengkap" v-model="dataFormPelengkap.status_aset"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="0" selected>Pilih</option>
                        <option v-for="(ast, iast) in dataStatusAset" :key="iast" v-bind:value="ast.status_aset_id">
                            {{ast.status_aset}}</option>
                    </select>
                </div>
            </div>
            <div class="mb-5">
                <label for="keterangan_pelengkap"
                    class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Keterangan</label>
                <textarea id="keterangan_pelengkap" rows="4" v-model="dataFormPelengkap.keterangan"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Tulis deskripsi disini..."></textarea>
            </div>
            <div class="mb-5">
                <a @click="addKelengkapan()">
                    <div class="p-1 rounded-lg mb-2 text-left hover:bg-gray-300 mb-2">
                        <span
                            class="text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400">
                            <svg class="w-3 h-3 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            Tambahkan Kelengkapan
                        </span>
                    </div>
                </a>

            </div>
            <div class="mb-5">
                <div
                    class="w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                    <div class="flex items-center justify-between mb-4">
                        <h5 class="text-xl font-bold leading-none text-gray-900 dark:text-white">Data Kelengkapan</h5>
                        <a href="#" class="text-sm font-medium text-blue-600 dark:text-blue-500">
                            {{dataAsetPelengkap.length}}
                        </a>
                    </div>
                    <div class="flow-root">
                        <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
                            <li class="py-3 sm:py-4" v-for="(pel, ipel) in dataAsetPelengkap" :key="ipel">
                                <div class="flex items-center">
                                    <div class="flex-1 min-w-0 ms-0">
                                        <p class="text-left text-sm font-medium text-gray-900 truncate dark:text-white">
                                            {{pel.nama_barang}}
                                        </p>
                                        <p class="text-left text-sm text-gray-500 truncate dark:text-gray-400">
                                            {{pel.status_aset}}
                                        </p>
                                        <p class="text-left text-sm text-gray-500 truncate dark:text-gray-400">
                                            {{pel.keterangan}}
                                        </p>
                                    </div>
                                    <div
                                        class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                        <button type="button" @click="deleteKelengkapan(ipel)">
                                            <svg class="w-5 h-5 text-red-500 dark:text-white" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                                viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-linecap="round"
                                                    stroke-linejoin="round" stroke-width="2"
                                                    d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>

        <button type="submit"
            class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Simpan</button>
    </form>
</template>

<script>
    export default {
        props: {
            document_id: Number
        },
        data() {
            return {
                checkSourceBarang: true,
                classTab: {
                    classTabActive: "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500",
                    classTabNonactive: "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                },
                form1: true,
                form2: false,
                dataFormAset: {
                    jenis_aset: 0,
                    entitas: 0,
                    divisi: null,
                    nama_user: null,
                    nama_aset: null,
                    brand: null,
                    tipe: null,
                    seri: null,
                    serial_number: null,
                    tahun_produksi: null,
                    tahun_kedatangan: null,
                    harga_pembelian: null,
                    vendor: null,
                    status: 0,
                    kondisi: null,
                    lokasi: null,
                    byod: 0,
                    informasi_tambahan: null,
                    waktu_penyerahan: null,
                    legalitas: null
                },
                dataFormKelengkapan: [],
                dataJenisAset: [],
                dataEntitas: [],
                dataDivisi: [],
                dataLokasi: [],
                dataBarangGudang: [],
                dataStatusAset: [],
                dataFormPelengkap: {
                    aset_gudang_id: 0,
                    nama_barang: "",
                    status_aset: 0,
                    keterangan: ""
                },
                dataAsetPelengkap: []
            }
        },
        created() {
            this.getJenisAset()
            this.getJenisEntitas()
            this.getJenisDivisi()
            this.getJenisLokasi()
            this.getBarangGudang()
            this.getStatusAsetPelengkap()
        },
        methods: {
            getJenisAset() {
                this.$http.get(`/api/auth/aset/data-jenis-aset`).then((
                    response) => {
                    this.dataJenisAset = response.data.jenisAset
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                })
            },
            getJenisEntitas() {
                this.$http.get(`/api/auth/aset/data-entitas`).then((
                    response) => {
                    this.dataEntitas = response.data.dataEntitas
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                })
            },
            getJenisDivisi() {
                this.$http.get(`/api/auth/aset/data-divisi`).then((
                    response) => {
                    this.dataDivisi = response.data.dataDivisi
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                })
            },
            getJenisLokasi() {
                this.$http.get(`/api/auth/aset/data-lokasi`).then((
                    response) => {
                    this.dataLokasi = response.data.dataLokasi
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                })
            },
            simpanDataAset() {
                if (this.dataAsetPelengkap.length == 0) {
                    this.$swal.fire({
                        title: "Data kelengkapan kosong!",
                        text: "Anda yakin tidak menambahkan data kelengkapan?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yakin"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.simpanAset()
                        }
                    });
                } else {
                    this.simpanAset()
                }
            },
            simpanAset() {
                this.$http.post('/api/auth/aset/simpan-data-aset', {
                    dataFormAset: this.dataFormAset,
                    dataAsetPelengkap: this.dataAsetPelengkap
                }).then((response) => {
                    if (response.data.status == "Success") {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Aset berhasil di simpan',
                            showConfirmButton: false,
                            timer: 1200
                        })
                        setInterval(location.reload(), 5000);
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                })
            },
            getBarangGudang() {
                this.$http.get(`/api/auth/aset/data-aset-pelengkap`).then((
                    response) => {
                    this.dataBarangGudang = response.data.dataAset
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                })
            },
            getStatusAsetPelengkap() {
                this.$http.get(`/api/auth/aset/data-status-aset-pelengkap`).then((
                    response) => {
                    this.dataStatusAset = response.data.statusAset
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                })
            },
            openForm(form) {
                if (form == 1) {
                    this.form1 = true
                    this.form2 = false
                } else {
                    if (this.dataFormAset.jenis_aset == 0) {
                        this.$swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Harap lengkapi data di atas!"
                        });
                    } else {
                        this.form1 = false
                        this.form2 = true
                    }
                }
            },
            addKelengkapan() {
                if (this.checkSourceBarang) {
                    var aset_barang_id = document.getElementById("aset_gudang_id");
                    var text_aset_barang_id = aset_barang_id.options[aset_barang_id.selectedIndex].text;
                } else {
                    var aset_barang_id = null
                    var text_aset_barang_id = this.dataFormPelengkap.nama_barang
                }

                var status_pelengkap = document.getElementById("status_pelengkap");
                var text_status_pelengkap = status_pelengkap.options[status_pelengkap.selectedIndex].text;

                var dataPelengkap = {}
                dataPelengkap.aset_gudang_id = this.checkSourceBarang ? this.dataFormPelengkap.aset_gudang_id :
                    aset_barang_id
                dataPelengkap.flag_status = this.dataFormPelengkap.status_aset
                dataPelengkap.keterangan = this.dataFormPelengkap.keterangan
                dataPelengkap.nama_barang = this.checkSourceBarang ? text_aset_barang_id : this.dataFormPelengkap
                    .nama_barang
                dataPelengkap.status_aset = text_status_pelengkap
                dataPelengkap.checkSourceBarang = this.checkSourceBarang

                this.dataAsetPelengkap.push(dataPelengkap)
            },
            deleteKelengkapan(i) {
                const arr = this.dataAsetPelengkap;
                const removed = arr.splice(i, 1);
            },
            checkTabBarang(id) {
                if (id == 1) {
                    this.checkSourceBarang = true
                } else {
                    this.checkSourceBarang = false
                }
            }
        }
    }
</script>