<template>
    <div class="p-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div class="relative overflow-x-auto sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-white bg-blue-600 dark:bg-blue-600 dark:text-white">
                    <tr>
                        <th scope="col" class="px-6 py-3 text-center">
                            No
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Nama Pemegang Hak
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Lokasi
                        </th>
                        <th scope="col" class="px-6 py-3 text-center">
                            No.SHM
                        </th>
                        <th scope="col" class="px-6 py-3 text-center">
                            NOP
                        </th>
                        <th scope="col" class="px-6 py-3 text-center">
                            Aksi
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, i) in dataPembayaranPbb" :key="i"
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                        <td class="px-6 py-4 text-center">
                            {{ i + 1 }}
                        </td>
                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {{ row.nama_pemegang_hak }}
                        </th>
                        <td class="px-6 py-4">
                            {{ row.lokasi }}
                        </td>
                        <td class="px-6 py-4 text-center">
                            {{ row.no_shm }}
                        </td>
                        <td class="px-6 py-4 text-center">
                            {{ row.nop }}
                        </td>
                        <td class="px-6 py-4 text-center">
                            <div class="flex justify-center">
                                <button @click="getDetailPbb(row.no_shm, row.nop)">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                    </svg>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataPembayaranPbb: []
            }
        },
        created() {
            this.getDataRekapPbb();
        },
        methods: {
            getDataRekapPbb() {
                this.$http.get('/api/auth/getDataRekapPbb').then((response) => {
                    this.dataPembayaranPbb = response.data.data.dataPembayaranPbb
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            getDetailPbb(shm, nop) {
                this.$router.push({
                    name: 'detail-pembayaran-pbb',
                    query: {
                        shm: shm,
                        nop: nop
                    }
                });
            }
        }
    }
</script>