<template>
    <div class="p-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div class="relative overflow-x-auto sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-white bg-blue-600 dark:bg-blue-600 dark:text-white">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            Document
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Expiration Time
                        </th>
                    </tr>
                </thead>
                <tbody v-if="dataDocExp.length != 0">
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700" v-for="(row, i) in dataDocExp"
                        :key="i">
                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {{ row.document_name }}
                        </th>
                        <td class="px-6 py-4">
                            {{ row.expired_in }} Hari
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="2" class="text-center">No Data Showing</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataDocExp: []
            }
        },
        created() {
            this.getDataDocExp()
        },
        methods: {
            getDataDocExp() {
                this.$http.post('/api/auth/getDataDocumentExpiration', {
                    is_regulator: true
                }).then((response) => {
                    if (response.data.data.dataDocExp) {
                        this.dataDocExp = response.data.data.dataDocExp
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            }
        }
    }
</script>