<template>
    <div>
        <!--Folder Handling-->
        <div v-if="status == 'folder_details'">
            <li class="flex justify-items-start item-center"
                @click="$emit('openModal', 'folder-rename-modal', 'modal-flowbite')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                    <path
                        d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                    <path
                        d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                </svg>

                <span class="ml-3 text-sm">Rename</span>
            </li>
            <li v-if="add_to_regulator == 1" @click="moveToRegulator(document_id)"
                class="hidden flex justify-items-start item-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                </svg>

                <span class="ml-3 text-sm">Tambah Ke Laporan Regulator</span>
            </li>
            <li class="hidden flex justify-items-start item-center"
                @click="$emit('openModal', 'folder-access-modal', 'modal-flowbite')">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                </svg>

                <span class="ml-3 text-sm">Bagikan</span>
            </li>
            <li @click="deleteFolder(document_id)" class="flex justify-items-start item-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>

                <span class="ml-3 text-sm">Hapus</span>
            </li>

        </div>

        <!--File Handling-->
        <div v-if="status == 'file_details'">
            <li class="flex justify-items-start item-center" @click="$emit('openModal', 'document-edit-modal')"
                v-if="is_write == 2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                    <path
                        d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                    <path
                        d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                </svg>

                <span class="ml-3 text-sm">Edit</span>
            </li>
            <li class="flex justify-items-start item-center" @click="toPage('open-document', document_id)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                </svg>

                <span class="ml-3 text-sm">Open</span>
            </li>
            <li class="flex justify-items-start item-center" @click="downloadItem(document_id)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                    <path fill-rule="evenodd"
                        d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                        clip-rule="evenodd" />
                </svg>

                <span class="ml-3 text-sm">Download</span>
            </li>
            <li class="flex justify-items-start item-center"
                @click="$emit('openModal', 'document-access-modal', 'modal-flowbite')" v-if="is_write == 2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                </svg>

                <span class="ml-3 text-sm">Bagikan</span>
            </li>
            <li class="flex justify-items-start item-center" @click="$emit('openModal', 'document-detail-modal')">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
                </svg>


                <span class="ml-3 text-sm">Detail</span>
            </li>
            <li @click="deleteDoc(document_id)" class="flex justify-items-start item-center" v-if="is_write == 2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>


                <span class="ml-3 text-sm">Hapus</span>
            </li>
        </div>

        <!--Tambah Dokumen Handling-->
        <div v-if="status == 'tambah_document'">
            <li class="flex justify-items-start item-center" @click="$emit('openModal', 'folder-creation-modal')">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                </svg>

                <span class="ml-3 text-sm">Folder Baru</span>
            </li>
            <li @click="uploadFile()" class="flex justify-items-start item-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15" />
                </svg>


                <span class="ml-3 text-sm">Upload File</span>
            </li>
            <li class="flex justify-items-start item-center" @click="$emit('openModal', 'document-creation-modal')">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                </svg>

                <span class="ml-3 text-sm">Buat Dokumen</span>
            </li>
            <!-- UPLOAD FILE -->
            <input @change="loadFile($event.target)"
                class="hidden block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                aria-describedby="update_file_help" id="update_file" type="file">
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                document_folder_id: this.$route.params.parent_id,
                fileUrl: '',
                fileObj: null,
                fileSize: 0,
                fileType: '',
                counter: 1
            }
        },
        props: {
            status: String,
            document_id: Number,
            add_to_regulator: Number,
            is_read: Number,
            is_write: Number
        },
        methods: {
            uploadFile() {
                document.getElementById("update_file").click();
            },
            loadFile(event) {
                this.fileUrl = URL.createObjectURL(event.files[0]);
                this.fileObj = event.files[0];
                this.fileSize = event.files[0].size;

                var name_file = event.files[0].name;
                var myArray = name_file.split(".");
                var fileExt = myArray[1]

                this.fileType = fileExt
                this.counter = 1
                var dataUpdateItem = new FormData()
                dataUpdateItem.append('file', this.fileObj)
                dataUpdateItem.append('document_folder_id', this.document_folder_id)
                let thisPage = this

                this.$http.post('api/auth/checkDokumenByName', {
                    name_file: name_file
                }).then(response => {
                    this.$http.post('api/auth/uploadNewFile', dataUpdateItem, {
                        onUploadProgress: function (progressEvent) {
                            let {
                                loaded,
                                total
                            } = progressEvent;

                            let counterProgress = Math.floor(((loaded * 100) / total) - 5);
                            thisPage.counter = counterProgress
                        }
                    }).then(response => {
                        if (response.data.status == "Success") {
                            this.counter = 100;
                            this.busy = false;
                            this.processing = false;
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Dokumen berhasil di upload',
                                showConfirmButton: false,
                                timer: 1200
                            })
                            setInterval(location.reload(), 1500);
                        }
                    }).catch(err => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Mohon maaf terjadi kesalahan ' + err,
                        })
                    })
                }).catch(err => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Nama dokumen sudah digunakan',
                    })
                })

            },
            deleteDoc(document_id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Anda yakin akan menghapus item ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Yakin',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$http.post('/api/auth/deleteDataDoc', {
                            document_id: document_id
                        }).then((response) => {
                            this.$swal('Deleted!', '', 'File anda berhasil di hapus')
                            setInterval(() => {
                                location.reload()
                            }, 1000)
                        }).catch((err) => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                            })
                        })
                    }
                })
            },
            moveToRegulator(folder_id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Anda yakin akan memidah folder ini ke Regulator?',
                    showCancelButton: true,
                    confirmButtonText: 'Yakin',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$http.post('/api/auth/moveToRegulator', {
                            folder_id: folder_id
                        }).then((response) => {
                            this.$swal('Berhasil!', '', 'Folder berhasil di pindah')
                            setInterval(() => {
                                location.reload()
                            }, 1000)
                        }).catch((err) => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                            })
                        })
                    }
                })
            },
            deleteFolder(folder_id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Anda yakin akan menghapus folder ini beserta file di dalamnya?',
                    showCancelButton: true,
                    confirmButtonText: 'Yakin',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$http.post('/api/auth/deleteDataFolder', {
                            folder_id: folder_id
                        }).then((response) => {
                            this.$swal('Deleted!', '', 'Folder anda berhasil di hapus')
                            setInterval(() => {
                                location.reload()
                            }, 1000)
                        }).catch((err) => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                            })
                        })
                    }
                })
            },
            downloadItem(document_id) {
                this.$http.post('/api/auth/getDocumentDetail', {
                    document_id: document_id
                }).then((response) => {
                    var url = response.data.data.data_detail[0].document_url
                    var document_name = response.data.data.data_detail[0].document_name
                    var document_extension = response.data.data.data_detail[0].document_extension

                    let link = document.createElement("a");
                    link.setAttribute("download", document_name + "." + document_extension);
                    link.href = url;
                    link.click();

                }).catch((err) => {
                    console.log(err)
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            toPage(router_name, parameter) {
                this.$router.push({
                    name: router_name,
                    params: {
                        document_id: parameter
                    }
                });
            },
        }
    }
</script>