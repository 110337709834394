<template>
    <div class="p-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div class="relative overflow-x-auto sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-white bg-blue-600 dark:bg-blue-600 dark:text-white">
                    <tr class="border">
                        <th scope="col" rowspan="2" class="px-6 py-3 text-center">
                            No
                        </th>
                        <th scope="col" colspan="2" class="px-6 py-3 text-center">
                            Laporan Terakhir
                        </th>
                        <th scope="col" rowspan="2" class="px-6 py-3 text-left">
                            Data Kegiatan Usaha
                        </th>
                        <th scope="col" rowspan="2" class="px-6 py-3 text-center">
                            Tanggal Due Date
                        </th>
                        <th scope="col" rowspan="2" class="px-6 py-3 text-center">
                            Tanggal Realisasi
                        </th>
                        <th scope="col" rowspan="2" class="px-6 py-3 text-left">
                            Status
                        </th>
                        <th scope="col" rowspan="2" class="px-6 py-3 text-center">
                            Aksi
                        </th>
                    </tr>
                    <tr>
                        <th scope="col" class="px-6 py-3 text-center">
                            No. Laporan
                        </th>
                        <th scope="col" class="px-6 py-3 text-center">
                            Tanggal
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, i) in dataRekapLkpm" :key="i"
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                        <td class="px-6 py-4 text-center">
                            {{ i + 1 }}
                        </td>
                        <td class="px-6 py-4 text-center">
                            {{ row.no_laporan }}
                        </td>
                        <td class="px-6 py-4 text-center whitespace-nowrap">
                            {{ row.tanggal_laporan }}
                        </td>
                        <td class="px-6 py-4 text-left">
                            <p class="mb-2"><span class="font-bold">Perizinan Usaha:</span> {{ row.perizinan_usaha }}
                            </p>
                            <p class="mb-2 whitespace-nowrap"><span class="font-bold">No Kegiatan Usaha:</span>
                                {{ row.no_kegiatan_usaha }}</p>
                        </td>
                        <td class="px-6 py-4 text-center whitespace-nowrap">
                            {{ row.tgl_due_date_pelaporan }}
                        </td>
                        <td class="px-6 py-4 text-center whitespace-nowrap">
                            {{ row.tgl_realisasi_pelaporan }}
                        </td>
                        <td class="px-6 py-4 text-left whitespace-nowrap">
                            <span :class="[row.badge_class]">{{ row.status_document }}</span>
                        </td>
                        <td class="px-6 py-4 text-center">
                            <div class="flex justify-center">
                                <button @click="getDetailLkpm(row.no_laporan)">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                    </svg>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataRekapLkpm: []
            }
        },
        created() {
            this.getDataRekapLkpm();
        },
        methods: {
            getDataRekapLkpm() {
                this.$http.get('/api/auth/getDataRekapLkpm').then((response) => {
                    if (response.data.data.getRekapLkpm) {
                        this.dataRekapLkpm = response.data.data.getRekapLkpm
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            getDetailLkpm(no_laporan) {
                this.$router.push({
                    name: 'detail-laporan-lkpm',
                    query: {
                        no: no_laporan
                    }
                });
            }
        }
    }
</script>