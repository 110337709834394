<template>
    <div class="max-w-md px-6 mx-auto">
        <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">Detail Meeting</h3>
        <ol class="relative text-gray-500 border-l border-gray-200 dark:border-gray-700 dark:text-gray-400"
            v-for="(row, i) in dataDetail" :key="i">
            <li class="mb-6 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                    </svg>
                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Judul Meeting</h3>
                <p class="text-xs text-left">{{row.nama_meeting}}</p>
            </li>
            <li class="mb-6 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z" />
                    </svg>
                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Jadwal Meeting</h3>
                <p class="text-xs text-left">{{formatDate(row.tanggal_meeting)}}</p>
            </li>
            <li class="mb-6 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 16H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1M9 12H4m8 8V9h8v11h-8Zm0 0H9m8-4a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z" />
                    </svg>
                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Jenis Meeting</h3>
                <p class="text-xs text-left">{{row.jenis_meeting}}</p>
            </li>
            <li class="mb-6 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd"
                            d="M5 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4Zm12 12V5H7v11h10Zm-5 1a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
                            clip-rule="evenodd" />
                    </svg>
                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Metode Meeting</h3>
                <p class="text-xs text-left">{{row.metode_meeting}}</p>
            </li>
            <li class="mb-6 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M7.556 8.5h8m-8 3.5H12m7.111-7H4.89a.896.896 0 0 0-.629.256.868.868 0 0 0-.26.619v9.25c0 .232.094.455.26.619A.896.896 0 0 0 4.89 16H9l3 4 3-4h4.111a.896.896 0 0 0 .629-.256.868.868 0 0 0 .26-.619v-9.25a.868.868 0 0 0-.26-.619.896.896 0 0 0-.63-.256Z" />
                    </svg>
                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Deskripsi Meeting</h3>
                <p class="text-xs text-left">
                    <pre>{{row.deskripsi_meeting}}</pre>
                </p>
            </li>
            <li class="mb-6 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 4h12M6 4v16M6 4H5m13 0v16m0-16h1m-1 16H6m12 0h1M6 20H5M9 7h1v1H9V7Zm5 0h1v1h-1V7Zm-5 4h1v1H9v-1Zm5 0h1v1h-1v-1Zm-3 4h2a1 1 0 0 1 1 1v4h-4v-4a1 1 0 0 1 1-1Z" />
                    </svg>
                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Tempat Meeting</h3>
                <p class="text-xs text-left">{{row.tempat_meeting}}</p>
            </li>
            <li class="mb-6 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961" />
                    </svg>
                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Link Meeting</h3>
                <p class="text-xs text-left text-blue-400" @click="openLink(row.link_meeting)">
                    {{row.link_meeting ? row.link_meeting : '-'}}</p>
            </li>
            <li class="mb-6 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                            d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3.05A2.5 2.5 0 1 1 9 5.5M19.5 17h.5a1 1 0 0 0 1-1 3 3 0 0 0-3-3h-1m0-3.05a2.5 2.5 0 1 0-2-4.45m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3 1 1 0 0 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                    </svg>
                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Peserta Meeting</h3>
                <p class="text-xs flex text-left bg-yellow-100 px-2 py-0.5 rounded max-w-fit mb-2"
                    v-for="(pes, index) in dataPeserta" :key="index">
                    <svg class="w-4 h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                        height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-width="2"
                            d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                    {{pes.divisi}} - {{pes.nama_lengkap}}
                </p>
            </li>
            <li class="mb-6 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961" />
                    </svg>
                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Status</h3>
                <p class="text-xs flex text-left bg-yellow-100 px-2 py-0.5 rounded max-w-fit mb-2"
                    v-if="row.flag_status == 1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 me-1">
                        <path fill="#74C0FC"
                            d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                    </svg>
                    {{row.status_meeting}}
                </p>
                <p class="text-xs flex text-left bg-green-100 px-2 py-0.5 rounded max-w-fit mb-2"
                    v-if="row.flag_status == 2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-4 h-4 me-1 animate-spin">
                        <path fill="#74C0FC"
                            d="M0 24C0 10.7 10.7 0 24 0H360c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V67c0 40.3-16 79-44.5 107.5L225.9 256l81.5 81.5C336 366 352 404.7 352 445v19h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24h8V445c0-40.3 16-79 44.5-107.5L158.1 256 76.5 174.5C48 146 32 107.3 32 67V48H24C10.7 48 0 37.3 0 24zM110.5 371.5c-3.9 3.9-7.5 8.1-10.7 12.5H284.2c-3.2-4.4-6.8-8.6-10.7-12.5L192 289.9l-81.5 81.5zM284.2 128C297 110.4 304 89 304 67V48H80V67c0 22.1 7 43.4 19.8 61H284.2z" />
                    </svg>
                    {{row.status_meeting}}
                </p>
                <p class="text-xs flex text-left bg-blue-100 px-2 py-0.5 rounded max-w-fit mb-2"
                    v-if="row.flag_status == 3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 me-1 animate-spin">
                        <path fill="#74C0FC"
                            d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                    </svg>
                    {{row.status_meeting}}
                </p>
                <p class="text-xs flex text-left bg-purple-100 px-2 py-0.5 rounded max-w-fit mb-2"
                    v-if="row.flag_status == 4">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 me-1">
                        <path fill="#74C0FC"
                            d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                    {{row.status_meeting}}
                </p>
                <select id="status_meeting" @change="updateStatus()" v-model="status_meeting"
                    v-if="row.flag_status != 4"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block max-w-50 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option selected value="0">Pilih</option>
                    <option value="2">Akan Dimulai</option>
                    <option value="3">Sedang Berjalan</option>
                    <option value="4">Selesai</option>
                </select>
            </li>
            <li class="mb-6 ml-6" v-if="row.flag_status == 4 || row.flag_status == 3">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M18 5V4a1 1 0 0 0-1-1H8.914a1 1 0 0 0-.707.293L4.293 7.207A1 1 0 0 0 4 7.914V20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5M9 3v4a1 1 0 0 1-1 1H4m11.383.772 2.745 2.746m1.215-3.906a2.089 2.089 0 0 1 0 2.953l-6.65 6.646L9 17.95l.739-3.692 6.646-6.646a2.087 2.087 0 0 1 2.958 0Z" />
                    </svg>
                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Notulen <span class="text-xs text-red-500"
                        v-if="row.flag_status == 3">* Notulen rapat otomatis tersimpan saat status di pilih
                        selesai</span></h3>
                <p class="text-xs text-left" v-if="row.flag_status == 4">
                    <pre>{{row.notulen_meeting ? row.notulen_meeting : '-'}}
                    </pre>
                </p>
                <a v-if="row.flag_status == 4 && row.notulen_file_ftp != null"
                    @click="toPage('open-document', row.notulen_file, row.notulen_file_extension, row.id, row.nama_meeting)">
                    <div class="px-1 rounded-lg mb-2 text-left hover:bg-gray-300 mb-2">
                        <span
                            class="text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400">
                            <svg class="w-3 h-3 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M3 19V6a1 1 0 0 1 1-1h4.032a1 1 0 0 1 .768.36l1.9 2.28a1 1 0 0 0 .768.36H16a1 1 0 0 1 1 1v1M3 19l3-8h15l-3 8H3Z" />
                            </svg>
                            Lihat lampiran file
                        </span>
                    </div>
                </a>
                <textarea v-if="row.flag_status == 3" id="notulen_meeting" v-model="notulen_meeting" rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Tulis notulensi rapat..."></textarea>
            </li>
            <li class="mb-6 ml-6" v-if="row.flag_status == 3 || row.flag_status == 4">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 5v9m-5 0H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2M8 9l4-5 4 5m1 8h.01" />
                    </svg>
                </span>
                <h3 class="font-medium leading-tight text-left text-dark">File Notulen</h3>
                <div class="flex items-center justify-center w-1/2">
                    <label for="file_notulen"
                        class="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                            </svg>
                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click
                                    to upload</span></p>
                            <!-- <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)
                            </p> -->
                        </div>
                        <input id="file_notulen" type="file" @change="uploadNotulen(row.flag_status)" class="hidden" />
                    </label>
                </div>
            </li>
        </ol>
    </div>
</template>

<script>
    export default {
        props: {
            document_id: Number
        },
        data() {
            return {
                dataDetail: [],
                dataPeserta: [],
                status_meeting: 0,
                notulen_meeting: null,
                fileUrl: '',
                fileObj: null,
                fileSize: 0,
                fileType: null,
                counter: 0
            }
        },
        created() {
            this.getDataDetail()
        },
        methods: {
            getDataDetail() {
                this.$http.get(`/api/auth/meeting/data-detail-meeting?meeting_id=${this.document_id}`).then((
                    response) => {
                    this.dataDetail = response.data.data_meeting
                    this.dataPeserta = response.data.data_peserta
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            formatDate(dateTime) {
                var replaceT = dateTime.replace("T", " ");
                var replaceZ = replaceT.replace("Z", "")

                var date = new Date(replaceZ).toLocaleString('ind', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    weekday: 'long'
                })
                var time = new Date(replaceZ).toLocaleTimeString('en-US', {
                    timeZone: 'Asia/Jakarta',
                    hour12: false
                })
                var date_time = date + " " + time
                return date_time
            },
            openLink(link) {
                window.open(link, '_blank').focus();
            },
            updateStatus() {
                var event = document.getElementById("file_notulen")
                if (event.files.length != 0) {
                    this.fileUrl = URL.createObjectURL(event.files[0]);
                    this.fileObj = event.files[0];
                    this.fileSize = event.files[0].size;

                    var name_file = event.files[0].name;
                    var myArray = name_file.split(".");
                    var fileExt = myArray[1]

                    this.fileType = fileExt
                    this.counter = 1
                }
                var dataUpdateItem = new FormData()
                dataUpdateItem.append('file_notulen', this.fileObj ? this.fileObj : [])
                dataUpdateItem.append('flag_status', this.status_meeting)
                dataUpdateItem.append('meeting_id', this.document_id)
                dataUpdateItem.append('notulen_meeting', this.notulen_meeting)

                if (this.status_meeting != 0) {
                    this.$http.post('/api/auth/meeting/update-status-meeting', dataUpdateItem).then((response) => {
                        if (response.data.status == "Success") {
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Status meeting berhasil update',
                                showConfirmButton: false,
                                timer: 5000
                            })
                            setInterval(location.reload(), 5000);
                        }
                    }).catch((err) => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                        })
                    })
                }
            },
            toPage(router_name, file_name, file_ext, aset_id, judul_meeting) {
                // this.$router.push({
                //     name: router_name,
                //     params: {
                //         document_id: aset_id
                //     },
                //     query: {
                //         file_name: file_name,
                //         file_ext: file_ext
                //     }
                // });

                const routeData = this.$router.resolve({
                    name: router_name,
                    params: {
                        document_id: aset_id
                    },
                    query: {
                        name: judul_meeting,
                        file_name: file_name,
                        file_ext: file_ext
                    }
                });
                window.open(routeData.href, '_blank');
            },
            uploadNotulen(flag_status) {
                if (flag_status == 4) {
                    var event = document.getElementById("file_notulen")
                    this.fileUrl = URL.createObjectURL(event.files[0]);
                    this.fileObj = event.files[0];
                    this.fileSize = event.files[0].size;

                    var name_file = event.files[0].name;
                    var myArray = name_file.split(".");
                    var fileExt = myArray[1]

                    this.fileType = fileExt
                    this.counter = 1

                    var dataUpdateItem = new FormData()
                    dataUpdateItem.append('file_notulen', this.fileObj ? this.fileObj : [])
                    dataUpdateItem.append('meeting_id', this.document_id)

                    this.$http.post('/api/auth/meeting/upload-file-notulen', dataUpdateItem).then((response) => {
                        if (response.data.status == "Success") {
                            this.$swal.fire({
                                icon: 'success',
                                title: 'File notulen meeting berhasil diupload',
                                showConfirmButton: false,
                                timer: 5000
                            })
                            setInterval(location.reload(), 5000);
                        }
                    }).catch((err) => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                        })
                    })
                }
            }
        }
    }
</script>