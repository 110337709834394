import {
  createRouter,
  createWebHistory
} from 'vue-router'
import {
    ref,
    nextTick
} from 'vue'

//Page View
import LoginPage from '../views/auth/LoginPage.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import DocumentCreation from '../views/document/DocumentCreation.vue'
import DocumentFolder from '../views/document/DocumentFolder.vue'
import DocumentFolderShare from '../views/document/DocumentFolderShare.vue'
import HomeView from '../views/HomeView.vue'
import DocumentEditor from '../views/document/DocumentEditor.vue'
import ReportRegulator from '../views/report_regulator/ReportRegulator.vue'
import DetailPembayaranPbb from '../views/report_regulator/components/DetailPembayaranPbb.vue'
import DetailLaporanLkpm from '../views/report_regulator/components/DetailLaporanLkpm.vue'
import SSOAuthentication from '../views/auth/SSOAuthentication.vue'
import DocumentOpen from '../views/document/components/DocumentOpen.vue'
import Meeting from '../views/meeting/Meeting.vue'
import AsetPerusahaan from '../views/aset/AsetPerusahaan.vue'
import AsetGudang from '../views/aset/AsetGudang.vue'
import AsetPerusahaanDetail from '../views/aset/AsetPerusahaanDetail.vue'

import {
  isUserLoggedIn,
  getHomeRouteForLoggedInUser,
  redirectToSSOAuthentication
} from '@/auth'
import NProgress from 'nprogress';

const routes = [{
    path: '/login-page',
    name: 'login-page',
    component: LoginPage,
    meta: {
      layout: 'login-page',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      title: 'Login'
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
        title: 'Dashboard'
    },
  },
  {
    path: '/document/r',
    name: 'document-creation-page',
    component: DocumentCreation,
    meta: {
        title: 'Tambah Dokumen'
    },
  },
  {
    path: '/document/f/:parent_id/:index_number',
    name: 'document-folder-page',
    component: DocumentFolder,
    meta: {
        title: 'Dokumen'
    },
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      layout: 'home-view',
      title: 'Home'
    },

  },
  {
    path: '/document/e/:document_id',
    name: 'document-editor-page',
    component: DocumentEditor,
    meta: {
        title: 'Dokumen Edit'
    },
  },
  {
    path: '/report/regulator',
    name: 'laporan-regulator',
    component: ReportRegulator,
    meta: {
        title: 'Laporan Regulator'
    },
  },
  {
    path: '/report/regulator/detail-pembayaran-pbb',
    name: 'detail-pembayaran-pbb',
    component: DetailPembayaranPbb,
    meta: {
        title: 'Pembayaran PBB'
    },
  },
  {
    path: '/report/regulator/detail-laporan-lkpm',
    name: 'detail-laporan-lkpm',
    component: DetailLaporanLkpm,
    meta: {
        title: 'Laporan LKPM'
    },
  },
  {
    path: '/document/swmf/:parent_id/:index_number',
    name: 'share-with-me',
    component: DocumentFolderShare,
    meta: {
        title: 'Dokumen Share'
    },
  },
  {
    path: '/sso',
    name: 'sso-authentication',
    component: SSOAuthentication,
    meta: {
      layout: 'sso-authentication',
      redirectIfLoggedIn: false,
    },

  },
  {
    path: '/document/view/:document_id',
    name: 'open-document',
    component: DocumentOpen,
    meta: {
        title: 'Dokumen View'
    },
  },
  {
    path: '/meeting',
    name: 'meeting',
    component: Meeting,
    meta: {
        title: 'Meeting'
    },
  },
  {
    path: '/aset-perusahaan',
    name: 'aset-perusahaan',
    component: AsetPerusahaan,
    meta: {
        title: 'Aset Perusahaan'
    },
  },
  {
    path: '/aset-perusahaan/detail',
    name: 'aset-perusahaan-detail',
    component: AsetPerusahaanDetail,
    meta: {
        title: 'Data Aset'
    },
  },
  {
    path: '/aset-gudang',
    name: 'aset-gudang',
    component: AsetGudang,
    meta: {
        title: 'Aset Gudang'
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0,
        behavior: 'smooth'
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!isLoggedIn) {
    if (to.name !== 'login-page') return next({
      name: 'login-page'
    })
  }

  if (isLoggedIn) {
    if (to.meta.redirectIfLoggedIn) {
      next(getHomeRouteForLoggedInUser())
    }
  }
  next();
})

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  NProgress.done();
  nextTick(() => {
    document.title = "Regarsport Legal | " + to.meta.title || "Regarsport Legal";
  })
})

export default router