<template>
    <div class="px-4 py-2">
        <p class="text-left text-dark mb-3">Assigned Document To</p>

        <label for="add_access_person"
            class="text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">Key person</label>
        <div class="relative mb-2">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    class="w-4 h-4 text-gray-500 dark:text-white">
                    <path
                        d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                </svg>
            </div>
            <input type="text" id="add_access_person" v-model="accessPerson" @keyup="getPersonAccess"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Tambahkan orang">

            <ul id="right-click-access" tabindex="1" ref="add_access" class="rounded w-auto" v-if="viewAddMenu">
                <li class="p-2" v-for="(row, i) in dataAccessPerson" :key="i"
                    @click="updateUserAccess(row.id, row.nama_lengkap, row.email, row.role)">
                    <div class="flex items-left space-x-4">
                        <div class="flex-shrink-0">
                            <img class="w-9 h-9 rounded-full" :src="row.foto_profil" alt="Neil image"
                                onerror="this.onerror=null;this.src='https://is3.cloudhost.id/legalrsii/images/account_image.png';">
                        </div>
                        <div class="flex-1 px-0">
                            <p class="text-sm text-left font-medium text-gray-900 dark:text-gray-900">
                                {{row.nama_lengkap}}
                            </p>
                            <p class="text-sm text-left text-gray-500 dark:text-gray-900">
                                {{row.email}}
                            </p>
                        </div>
                        <div class="text-sm inline-flex items-center font-semibold text-gray-900 dark:text-gray-900">
                            {{row.role}}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="mb-6">
            <label for="message" class="text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your
                message</label>
            <textarea id="message" rows="4" v-model="message"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your message..."></textarea>
        </div>
    </div>

    <div class="grid item-center justify-items-end px-2">
        <button type="button" @click="addAssignedPerson()"
            class="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-4 h-4 mr-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
            </svg>
            Add Level
        </button>
    </div>

    <hr class="h-px mx-4 my-2 bg-gray-200 border-0 dark:bg-gray-700">

    <div class="px-4 py-2 overflow-y-auto h-32">
        <ul class="max-w-md divide-y divide-gray-200 dark:divide-gray-700">
            <li class="pb-3 sm:pb-4" v-for="(row, i) in dataAssignedPerson" :key="i">
                <div class="flex items-left space-x-4 mt-2">
                    <div class="flex-shrink-0">
                        <img class="w-9 h-9 rounded-full"
                            src="https://is3.cloudhost.id/legalrsii/images/account_image.png" alt="Neil image">
                    </div>
                    <div class="flex-1 min-w-0 px-0">
                        <p class="text-sm text-left font-medium text-dark truncate dark:text-dark">
                            {{row.nama_person}}
                        </p>
                        <p class="text-sm text-left text-dark truncate dark:text-dark">
                            {{row.message}}
                        </p>
                    </div>
                    <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-dark">
                        <button type="button" @click="deleteAssignedPerson(i)"
                            class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm p-2 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>

                            <span class="sr-only">Icon description</span>
                        </button>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <div class="grid item-center justify-items-end px-2" v-if="dataAssignedPerson.length != 0">
        <button type="button" @click="shareAssignedDoc()"
            class="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-4 h-4 mr-2">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
            </svg>
            Bagikan
        </button>
    </div>
</template>

<style scoped>
    #right-click-access {
        background: #FAFAFA;
        border: 1px solid #BDBDBD;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        z-index: 999999;
        outline: none;
    }

    #right-click-access li {
        border-bottom: 1px solid #E0E0E0;
        margin: 0;
        padding: 10px 10px;
    }

    #right-click-access li:last-child {
        border-bottom: none;
    }

    #right-click-access li:hover {
        background: #1E88E5;
        color: #FAFAFA;
    }
</style>

<script>
    export default {
        props: {
            viewModalShare: Boolean,
            document_id: Number
        },
        data() {
            return {
                namaDocument: '',
                accessPersonId: null,
                accessPerson: '',
                dataAccessPerson: [],
                viewAddMenu: false,
                dataAssignedPerson: [],
                message: '',
                emailPerson: ''
            }
        },
        emits: ["closeModal"],
        created() {
            this.getDataDetail()
        },
        methods: {
            getDataDetail() {
                this.$http.post('/api/auth/getDocumentDetail', {
                    document_id: this.document_id
                }).then((response) => {
                    if(response.data.data.data_detail){
                        this.namaDocument = response.data.data.data_detail[0].document_name
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            getPersonAccess() {
                var lengtKeyword = this.accessPerson.length

                if (lengtKeyword >= 3) {
                    this.viewAddMenu = true
                    this.$http.post('/api/auth/getDataUserAccess', {
                        keyword: this.accessPerson
                    }).then((response) => {
                        this.dataAccessPerson = response.data.data
                    }).catch((err) => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                        })
                    })
                } else {
                    this.viewAddMenu = false
                }
            },
            updateUserAccess(id, nama_lengkap, email, role) {
                this.viewAddMenu = false
                this.accessPersonId = id
                this.accessPerson = nama_lengkap
                this.emailPerson = email

            },
            addAssignedPerson() {
                var document_id = this.document_id
                var user_id = this.accessPersonId
                var nama_person = this.accessPerson
                var message = this.message
                var emailPerson = this.emailPerson

                this.dataAssignedPerson.push({
                    document_id: document_id,
                    user_id: user_id,
                    nama_person: nama_person,
                    message: message,
                    email: emailPerson,
                    nama_document: this.namaDocument
                })
            },
            deleteAssignedPerson(index) {
                this.dataAssignedPerson.splice(index, 1);
            },
            shareAssignedDoc() {
                this.$http.post('/api/auth/saveAssignedUser', {
                    dataAssignedPerson: this.dataAssignedPerson
                }).then((response) => {
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Mantap',
                        text: 'Berhasil mengirim Approvement',
                    }).then((result) => {
                        location.reload();
                    })
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            }
        }
    }
</script>