<template>
    <div class="p-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <h6 class="text-md text-left font-bold tracking-tight text-gray-900 dark:text-white">Document Status</h6>
        <hr class="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700">
        <div>
            <div
                class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul class="flex flex-nowrap -mb-px">
                    <li class="mr-2" @click="setTabActive('tabLevel')">
                        <a :class="[tabActive.tabLevel ? tabClass.tabActive : tabClass.tabNonaktive]">Pending
                            Approval</a>
                    </li>
                    <li class="mr-2" @click="setTabActive('tabUser')">
                        <a :class="[tabActive.tabUser ? tabClass.tabActive : tabClass.tabNonaktive]"
                            aria-current="page">Approved</a>
                    </li>
                </ul>
            </div>

            <div class="relative overflow-x-auto overflow-y-scroll sm:rounded-lg h-80">
                <table class="w-full text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Nama Dokumen
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Uploaded At
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="dataDocApproval.length != 0">
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                            v-for="(row, i) in dataDocApproval" :key="i">
                            <th scope="row"
                                class="px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                                <span class="text-xs">{{ row.document_name }}</span>
                            </th>
                            <td class="px-6 py-4">
                                <span class="text-xs">{{ formatDate(row.created_at) }}</span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="text-center" colspan="2">
                                No Data Showing
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tabActive: {
                    tabLevel: true,
                    tabUser: false
                },
                tabClass: {
                    tabActive: 'inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500',
                    tabNonaktive: 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                },
                dataDocApproval: [],
            }
        },
        created() {
            this.getDataDocApproval(1)
        },
        methods: {
            setTabActive(tabName) {
                this.tabActive = Object.assign(Object.keys(this.tabActive).map(k => ({
                    [k]: false
                })));
                this.tabActive[tabName] = true
                var status_approval = tabName == 'tabLevel' ? 1 : 2
                this.getDataDocApproval(status_approval)
            },
            getDataDocApproval(status_approval) {
                this.$http.post('/api/auth/getDataDocumentApproval', {
                    status_approval: status_approval
                }).then((response) => {
                    if (response.data.data.dataDocApproval) {
                        this.dataDocApproval = response.data.data.dataDocApproval
                    }
                }).catch((err) => {
                    // this.$swal.fire({
                    //     icon: 'error',
                    //     title: 'Oops...',
                    //     text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    // })
                })
            },
            formatDate(dateTime) {
                var date = new Date(dateTime).toLocaleString('ind', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    weekday: 'long'
                })
                var time = new Date(dateTime).toLocaleTimeString()
                var date_time = date + " " + time
                return date_time
            },
        }
    }
</script>