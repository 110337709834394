<template>
    <div class="max-w-md px-6 mx-auto">
        <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">Edit Aset</h3>
        <form class="max-w-md mx-auto" @submit.prevent="simpanDataAset">
            <div v-for="(row, i) in dataDetailAset" :key="i">
                <div class="mb-5">
                    <label for="status"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white">Status</label>
                    <select id="status" v-model="row.flag_status"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="0" selected>Pilih</option>
                        <option value="1">Aktif</option>
                        <option value="2">Tidak Aktif</option>
                        <option value="3">Red Off</option>
                        <option value="4">Rusak</option>
                    </select>
                </div>
                <div class="mb-5">
                    <label for="kondisi"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Kondisi</label>
                    <input type="number" id="kondisi" v-model="row.kondisi"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: 0-100" required />
                </div>
                <div class="mb-5">
                    <label for="nama_user"
                        class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Nama User</label>
                    <input type="text" id="nama_user" v-model="row.nama_user"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Cth: HARY SETIAWAN" />
                </div>
            </div>
            <button type="submit"
                class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Simpan</button>
        </form>
    </div>
</template>

<script>
    export default {
        props: {
            document_id: [String, Number]
        },
        data() {
            return {
                kode_aset: this.$route.query.kode_aset,
                dataDetailAset: []
            }
        },
        created() {
            this.getDataAset()
        },
        methods: {
            getDataAset() {
                this.$http.get(`/api/auth/aset/data-aset-detail?kode_aset=${this.kode_aset ? this.kode_aset : this.document_id}`).then((
                    response) => {
                    this.dataDetailAset = response.data.dataDetail
                    // this.dataDetailKelengkapan = response.data.dataKelengkapan
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            simpanDataAset() {
                var status = document.getElementById("status").value;
                var kondisi = document.getElementById("kondisi").value;
                var nama_user = document.getElementById("nama_user").value;

                this.$http.post('/api/auth/aset/simpan-edit-data-aset', {
                    kode_aset: this.kode_aset ? this.kode_aset : this.document_id,
                    status: status,
                    kondisi: kondisi,
                    nama_user: nama_user
                }).then((response) => {
                    if (response.data.status == "Success") {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Aset berhasil di simpan',
                            showConfirmButton: false,
                            timer: 1200
                        })
                        setInterval(location.reload(), 5000);
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                })
            }
        }
    }
</script>