<template>
    <div>
        <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">Edit Meeting</h3>
        <form class="max-w-sm mx-auto" v-for="(row, i) in dataDetail" :key="i" @submit.prevent="simpanDataMeeting">
            <div class="mb-5">
                <label for="nama_meeting"
                    class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Nama meeting</label>
                <input type="text" id="nama_meeting" v-model="row.nama_meeting"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Cth: Meeting Makro" required />
            </div>
            <div class="mb-5">
                <label for="tanggal_meeting"
                    class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Tanggal
                    meeting</label>
                <input type="datetime-local" id="tanggal_meeting" v-model="row.tanggal_meeting"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required />
            </div>
            <div class="mb-5">
                <label for="deskripsi_meeting"
                    class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Deskripsi</label>
                <textarea id="deskripsi_meeting" rows="4" v-model="row.deskripsi_meeting"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Tulis deskripsi disini..."></textarea>
            </div>
            <div class="mb-5">
                <label for="jenis_meeting"
                    class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Jenis meeting</label>
                <select id="jenis_meeting" v-model="row.jenis_meeting_id"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option selected value="0">Pilih</option>
                    <option value="1">Eksternal</option>
                    <option value="2">Internal</option>
                    <option value="3">Coaching</option>
                </select>
            </div>
            <div class="mb-5">
                <label for="metode_meeting"
                    class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Metode
                    meeting</label>
                <select id="metode_meeting" v-model="row.metode_meeting_id"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option selected value="0">Pilih</option>
                    <option value="1">Daring</option>
                    <option value="2">Luring</option>
                </select>
            </div>
            <div class="mb-5">
                <label for="tempat_meeting"
                    class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Tempat
                    meeting</label>
                <input type="text" id="tempat_meeting" v-model="row.tempat_meeting"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Cth: Paviliun" required />
            </div>
            <div class="mb-5">
                <label for="link_meeting"
                    class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Link meeting</label>
                <input type="text" id="link_meeting" v-model="row.link_meeting"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Cth: https://us06web.zoom.us/j/82975753365?pwd=VTeQqWTu2oKpgsaEWaqo8cQyaJFlky.1" />
            </div>

            <button type="submit"
                class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Simpan</button>
        </form>

    </div>
</template>

<script>
    export default {
        props: {
            document_id: Number
        },
        data() {
            return {
                dataDetail: [],
                dataPeserta: [],
            }
        },
        created() {
            this.getDataDetail()
        },
        methods: {
            getDataDetail() {
                this.$http.get(`/api/auth/meeting/data-detail-meeting?meeting_id=${this.document_id}`).then((
                    response) => {
                    var data = response.data.data_meeting
                    data.forEach(element => {
                        var dataMeeting = {}
                        dataMeeting.nama_meeting = element.nama_meeting
                        dataMeeting.tanggal_meeting = this.formatDate(element.tanggal_meeting)
                        dataMeeting.deskripsi_meeting = element.deskripsi_meeting
                        dataMeeting.jenis_meeting_id = element.jenis_meeting_id
                        dataMeeting.metode_meeting_id = element.metode_meeting_id
                        dataMeeting.tempat_meeting = element.tempat_meeting
                        dataMeeting.link_meeting = element.link_meeting
                        dataMeeting.meeting_id = this.document_id

                        this.dataDetail.push(dataMeeting)
                    });

                    this.dataPeserta = response.data.data_peserta
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            formatDate(dateTime) {
                var replaceT = dateTime.replace("T", " ");
                var replaceZ = replaceT.replace("Z", "");
                var splitDate = replaceT.split(" ")
                
                var date = splitDate[0]

                var time = new Date(replaceZ).toLocaleTimeString('en-US', {
                    timeZone: 'Asia/Jakarta',
                    hour12: false
                })
                var date_time = date + " " + time
                return date_time
            },
            simpanDataMeeting() {
                this.$http.post('/api/auth/meeting/update-data-meeting', {
                    data_edit: this.dataDetail
                }).then((response) => {
                    if (response.data.status == "Success") {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Meeting berhasil di simpan',
                            showConfirmButton: false,
                            timer: 1200
                        })
                        setInterval(location.reload(), 5000);
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            }
        }
    }
</script>