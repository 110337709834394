<template>
    <div>
        <main>
            <section class="absolute w-full h-full">
                <div class="absolute top-0 w-full h-full bg-gray-900"
                    style="background-size: 100%; background-repeat: no-repeat; background-image: url('https://is3.cloudhost.id/legalrsii/images/background.png')">
                </div>
                <div class="container mx-auto px-4 h-full">
                    <div class="flex content-center items-center justify-center h-full">
                        <div class="w-full md:w-1/2 px-4">
                            <div
                                class="relative flex min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-current border-0">
                                <div class="w-1/2">
                                    <div class="rounded-t mb-0 px-6 py-6">
                                        <div class="text-center mb-3">
                                            <h6 class="text-white text-sm font-bold">
                                                Sign in
                                            </h6>
                                        </div>
                                        <hr class="mt-6 border-b-1 border-gray-400" />
                                    </div>
                                    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                                        <form>
                                            <div class="relative w-full mb-4">
                                                <input type="text" v-model="username"
                                                    class="border px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded-full text-sm shadow focus:outline-none focus:ring w-full"
                                                    placeholder="Username" style="transition: all 0.15s ease 0s;" />
                                            </div>
                                            <div class="relative w-full mb-4">
                                                <input type="password" v-model="password"
                                                    class="border px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded-full text-sm shadow focus:outline-none focus:ring w-full"
                                                    placeholder="Password" style="transition: all 0.15s ease 0s;" />
                                            </div>
                                            <div class="text-center mt-10">
                                                <button
                                                    class="bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 text-white text-sm font-bold uppercase px-6 py-2 rounded-full shadow focus:outline-none mr-1 mb-1 w-full"
                                                    type="button" style="transition: all 0.15s ease 0s;"
                                                    @click="loginRegarnet()" @keyup.enter="loginRegarnet()">
                                                    Sign In
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="bg-gray-300 w-1/2 rounded-r-lg">
                                    <img class="h-max rounded-r-lg"
                                        src="https://img.freepik.com/free-vector/illustration-social-media-concept_53876-18383.jpg?w=740&t=st=1691737642~exp=1691738242~hmac=e8b263aa3e04018f1b1878062f03b872de6ddbdc0244513219d68e760cb1c19e" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "login-page",
        components: {

        },
        data() {
            return {
                username: '',
                password: ''
            }
        },
        methods: {
            loginRegarnet() {
                axios.post('https://regarsport.net/api/login/account_check', {
                    username: this.username,
                    password: this.password
                }).then((response) => {
                    var nia = response.data.data.regarnet_nia
                    this.signin(nia)
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf username atau password anda salah!',
                    })
                })
            },
            signin(nia) {
                this.$http.post('/api/authentication', {
                    nia: nia,
                }).then((response) => {
                    if (response.data.message) {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Mohon maaf terdapat kesalahan! ' + response.data.message,
                        })
                    } else {
                        localStorage.setItem('storageTokenKeyName', response.data.data.token)
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Authentication',
                            text: 'Anda berhasil login! Mohon tunggu sedang mengalihkan',
                            timer: 4000,
                            timerProgressBar: true,
                            showConfirmButton: false
                        });

                        setInterval(() => {
                            this.$router.push({
                                name: 'dashboard'
                            });
                            location.reload();
                        }, 5000)
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terdapat kesalahan! ' + err,
                    })
                })
            }
        }
    }
</script>