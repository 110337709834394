<template>
    <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">Pengaturan Multi Akses Dokumen</h3>
    <div class="space-y-6" action="#">
        <div>
            <div
                class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul class="flex flex-nowrap -mb-px">
                    <li class="mr-2" @click="setTabActive('tabLevel')">
                        <a href="#" :class="[tabActive.tabLevel ? tabClass.tabActive : tabClass.tabNonaktive]">Level</a>
                    </li>
                    <li class="mr-2" @click="setTabActive('tabUser')">
                        <a href="#" :class="[tabActive.tabUser ? tabClass.tabActive : tabClass.tabNonaktive]"
                            aria-current="page">User</a>
                    </li>
                </ul>
            </div>

        </div>

        <!--Form Level-->
        <div v-if="tabActive.tabLevel">
            <label for="document_level"
                class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Level Dokumen</label>
            <select id="document_level" v-model="selectedLevelDoc"
                class="block w-full p-2 mb-6 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option selected value="0">Pilih</option>
                <option v-for="(row, i) in dataLevelDoc" :key="i" v-bind:value="row.level_id">
                    {{row.level_name}}</option>
            </select>
            <button type="button" @click="simpanLevelDokumen"
                class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Simpan</button>
        </div>

        <!--Form User-->
        <div v-if="tabActive.tabUser">
            <div>
                <label for="input-group-2"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Tambahkan
                    hak akses ke orang lain</label>
                <div class="relative mb-2">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                            class="w-4 h-4 text-gray-500 dark:text-white">
                            <path
                                d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                        </svg>
                    </div>
                    <input type="text" id="add_access_person" v-model="accessPerson" @keyup="getPersonAccess"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Tambahkan orang">

                    <ul id="right-click-access" tabindex="1" ref="add_access" class="rounded w-auto" v-if="viewAddMenu">
                        <li class="p-2" v-for="(row, i) in dataAccessPerson" :key="i"
                            @click="updateUserAccess(row.id, row.nama_lengkap, row.email, row.role)">
                            <div class="flex items-left space-x-4">
                                <div class="flex-shrink-0">
                                    <img class="w-9 h-9 rounded-full" :src="row.foto_profil" alt="Neil image"
                                        onerror="this.onerror=null;this.src='https://is3.cloudhost.id/legalrsii/images/account_image.png';">
                                </div>
                                <div class="flex-1 px-0">
                                    <p class="text-sm text-left font-medium text-gray-900 dark:text-gray-900">
                                        {{row.nama_lengkap}}
                                    </p>
                                    <p class="text-sm text-left text-gray-500 dark:text-gray-900">
                                        {{row.email}}
                                    </p>
                                </div>
                                <div
                                    class="text-sm inline-flex items-center font-semibold text-gray-900 dark:text-gray-900">
                                    {{row.role}}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div>
                <h5 class="text-md font-semibold text-gray-950 dark:text-gray-950 text-left mb-2">
                    Orang yang memiliki akses
                </h5>

                <ul class="max-w-md divide-y divide-gray-200 dark:divide-gray-700">
                    <li class="pb-3 sm:pb-4" v-for="(row, i) in dataPemilikDoc" :key="i">
                        <div class="flex items-left space-x-4 mt-2">
                            <div class="flex-shrink-0">
                                <img class="w-9 h-9 rounded-full" :src="row.foto_profil" alt="Neil image">
                            </div>
                            <div class="flex-1 min-w-0 px-0">
                                <p class="text-sm text-left font-medium text-gray-900 truncate dark:text-gray-900">
                                    {{row.nama_lengkap}}
                                </p>
                                <p class="text-sm text-left text-gray-500 truncate dark:text-gray-900">
                                    {{row.email}}
                                </p>
                            </div>
                            <div class="text-sm inline-flex items-center">
                                <select id="user_doc_permision" @change="changePermision($event, i)"
                                    v-model="row.user_doc_permision"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option selected value="1">Editor</option>
                                    <option value="2">Pelihat</option>
                                </select>
                            </div>
                        </div>
                    </li>
                </ul>

            </div>
            <button type="button" @click="simpanDokumen"
                class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Simpan</button>
        </div>
    </div>
</template>

<style scoped>
    #right-click-access {
        background: #FAFAFA;
        border: 1px solid #BDBDBD;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        z-index: 999999;
        outline: none;
    }

    #right-click-access li {
        border-bottom: 1px solid #E0E0E0;
        margin: 0;
        padding: 10px 10px;
    }

    #right-click-access li:last-child {
        border-bottom: none;
    }

    #right-click-access li:hover {
        background: #1E88E5;
        color: #FAFAFA;
    }
</style>

<script>
    export default {
        props: {
            document_id: Number
        },
        data() {
            return {
                tabActive: {
                    tabLevel: true,
                    tabUser: false
                },
                tabClass: {
                    tabActive: 'inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500',
                    tabNonaktive: 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                },
                selectedLevelDoc: 0,
                dataLevelDoc: [],
                dataAccessPerson: [],
                accessPerson: '',
                viewAddMenu: false,
                dataPemilikDoc: [],
            }
        },
        created() {
            this.getDataLevelDoc();
        },
        methods: {
            getDataLevelDoc() {
                this.$http.get('/api/auth/getDataLevelDoc').then((response) => {
                    this.dataLevelDoc = response.data.data.data_level ? response.data.data.data_level : []
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            setTabActive(tabName) {
                this.tabActive = Object.assign(Object.keys(this.tabActive).map(k => ({
                    [k]: false
                })));
                this.tabActive[tabName] = true
            },
            getPersonAccess() {
                var lengtKeyword = this.accessPerson.length

                if (lengtKeyword >= 3) {
                    this.viewAddMenu = true
                    this.$http.post('/api/auth/getDataUserAccess', {
                        keyword: this.accessPerson
                    }).then((response) => {
                        this.dataAccessPerson = response.data.data
                    }).catch((err) => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                        })
                    })
                } else {
                    this.viewAddMenu = false
                }
            },
            updateUserAccess(id, nama_lengkap, email, role) {
                this.viewAddMenu = false
                var data = {
                    id: id,
                    nama_lengkap: nama_lengkap,
                    email: email,
                    role: role,
                    user_doc_permision: 1
                }
                if (this.dataPemilikDoc.filter(e => e.id === id).length == 0) {
                    this.dataPemilikDoc.push(data)
                }

            },
            changePermision(e, i) {
                this.dataPemilikDoc[i].user_doc_permision = parseInt(e.target.value)
            },
            simpanLevelDokumen() {
                this.$http.post('/api/auth/updateLevelDocument', {
                    document_id: this.document_id,
                    document_level: this.selectedLevelDoc
                }).then((response) => {
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Mantap',
                        text: 'Berhasil simpan data',
                    }).then((result) => {
                        location.reload();
                    })
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            simpanDokumen() {
                this.$http.post('/api/auth/saveDataDoc', {
                    is_share: true,
                    nama: localStorage.getItem("nama"),
                    document_id: this.document_id,
                    dataPemilikDoc: this.dataPemilikDoc
                }).then((response) => {
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Mantap',
                        text: 'Berhasil simpan data',
                    }).then((result) => {
                        location.reload();
                    })
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            }
        }
    }
</script>