<template>
    <div class="h-full">
        <div
            class="grid grid-cols- xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-2 mt-2">
            <antrian-meeting @openModal="openModal"></antrian-meeting>
            <akan-dimulai-meeting @openModal="openModal"></akan-dimulai-meeting>
            <sedang-berlangsung-meeting @openModal="openModal"></sedang-berlangsung-meeting>
            <selesai-meeting @openModal="openModal"></selesai-meeting>
        </div>

        <modal-flowbite :showModal="showModal" :componentName="componentModalName" @closeModal="closeModal"
                :document_id="meeting_id"></modal-flowbite>
    </div>
</template>

<script>
    import ModalFlowbite from '@/components/modal/ModalFlowbite.vue'
    import AkanDimulaiMeeting from './components/AkanDimulaiMeeting.vue'
    import AntrianMeeting from './components/AntrianMeeting.vue'
    import SedangBerlangsungMeeting from './components/SedangBerlangsungMeeting.vue'
    import SelesaiMeeting from './components/SelesaiMeeting.vue'
    export default {
        components: {
            AntrianMeeting,
            AkanDimulaiMeeting,
            SedangBerlangsungMeeting,
            SelesaiMeeting,
            ModalFlowbite
        },
        data() {
            return {
                showModal: false,
                componentModalName: '',
                meeting_id: 0,
                viewModal: {
                    viewModalReg: false,
                    viewModalDetail: false
                },
            }
        },
        methods: {
            openModal(componentModalName, modalName, meeting_id) {
                this.meeting_id = meeting_id ? meeting_id : 0
                if (componentModalName != 'viewModalDetail') {
                    if (modalName == 'modal-flowbite') {
                        this.showModal = true
                    } else {
                        this.viewModal.viewModalReg = true
                    }
                } else {
                    this.viewModal[componentModalName] = true
                }
                this.componentModalName = componentModalName
            },
            closeModal(componentModalName, modalName) {
                if (modalName == 'modal-flowbite') {
                    this.showModal = false
                } else {
                    this.viewModal.viewModalReg = false
                    this.viewModal.viewModalDetail = false
                }
                location.reload();
            },
        }
    }
</script>