<template>
    <div class="h-full">
        <div class="border-b border-gray-200 dark:border-gray-700">
            <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                <li class="me-2">
                    <a @click="changeTab('aset')" :class="tabMenu.tabAset ? tabClass.tabActive : tabClass.tabNonActive">
                        <svg :class="tabMenu.tabAset ? tabClass.tabActiveIcon : tabClass.tabNonActiveIcon"
                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            viewBox="0 0 20 20">
                            <path
                                d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                        </svg>Aset
                    </a>
                </li>
                <li class="me-2">
                    <a @click="changeTab('gudang')"
                        :class="tabMenu.tabGudang ? tabClass.tabActive : tabClass.tabNonActive" aria-current="page">
                        <svg :class="tabMenu.tabGudang ? tabClass.tabActiveIcon : tabClass.tabNonActiveIcon"
                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            viewBox="0 0 18 18">
                            <path
                                d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                        </svg>Gudang
                    </a>
                </li>
            </ul>
        </div>
        <div v-if="tabMenu.tabAset"
            class="max-w-full p-2 bg-white border border-gray-200 rounded-b-lg shadow dark:bg-gray-800 dark:border-gray-700">

            <div class="relative overflow-x-auto sm:rounded-lg max-h-96">
                <div class="pb-4 px-1 bg-white dark:bg-gray-900 sticky top-0">
                    <label for="table-search" class="sr-only">Search</label>
                    <div class="relative mt-1">
                        <div
                            class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input type="text" id="table-search" @keyup="getDataAset(0)" v-model="keyword"
                            class="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search for items">
                    </div>
                </div>
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-12">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Kode
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Nama Aset
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Jenis Aset
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Divisi
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Perusahaan
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Kondisi
                            </th>
                        </tr>
                    </thead>
                    <tbody class="h-4/5 overflow-y-auto">
                        <tr v-for="(row, i) in dataAset" :key="i"
                            @pointerdown="startCount($event, row.aset_id, row.kode_aset)" @pointerup="stopTimer()"
                            @mouseup.right="openMenu($event, row.aset_id, row.kode_aset)"
                            class="border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                            :class="row.class_aset">
                            <td scope="row" class="px-6 py-4">
                                {{row.kode_aset}}
                            </td>
                            <th class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {{row.nama_aset}} {{row.brand}}
                            </th>
                            <td class="px-6 py-4">
                                {{row.jenis_aset}}
                            </td>
                            <td class="px-6 py-4">
                                {{row.divisi}}
                            </td>
                            <td class="px-6 py-4">
                                {{row.entitas}}
                            </td>
                            <td class="px-6 py-4 flex">
                                <div class="w-full bg-gray-200 rounded-sm dark:bg-gray-700">
                                    <div class="text-xs font-medium text-center p-0.5 leading-none rounded-sm"
                                        :class="checkClassColor(row.kondisi)" :style="checkClassWidth(row.kondisi)">
                                        {{row.kondisi}}%</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <nav aria-label="Page navigation example">
                <ul class="inline-flex -space-x-px text-sm">
                    <li>
                        <a href="#"
                            class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</a>
                    </li>
                    <li v-for="(page, i) in pagination" :key="i">
                        <a @click="getDataAset(page.offset)"
                            class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{{page.page}}</a>
                    </li>
                    <li>
                        <a href="#"
                            class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                    </li>
                </ul>
            </nav>

        </div>
        <div v-else>
            <aset-gudang></aset-gudang>
        </div>

        <!--Document Handling-->
        <ul id="right-click-menu" tabindex="-1" ref="menu_details" v-if="viewMenu" :style="{top: top, left:left}"
            v-on:blur="closeMenu" v-on:mouseleave="closeMenu" class="rounded md:w-1/4 sm:w-1/4">
            <aset-handling @openModal="openModal" :aset_id="aset_id" :kode_aset="kode_aset"></aset-handling>
        </ul>

        <button type="button" @click="openModal('aset-tambah-modal', 'modal-flowbite', 0)"
            class="float-right fixed bottom-4 right-4 text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                <path fill-rule="evenodd"
                    d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                    clip-rule="evenodd" />
            </svg>
        </button>

        <modal-flowbite :showModal="showModal" :componentName="componentModalName" @closeModal="closeModal"
            :document_id="meeting_id"></modal-flowbite>
    </div>
</template>

<style>
    #right-click-menu {
        background: #FAFAFA;
        border: 1px solid #BDBDBD;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        z-index: 999999;
        outline: none;
    }

    #right-click-menu li {
        border-bottom: 1px solid #E0E0E0;
        margin: 0;
        padding: 10px 10px;
    }

    #right-click-menu li:last-child {
        border-bottom: none;
    }

    #right-click-menu li:hover {
        background: #1E88E5;
        color: #FAFAFA;
    }

    .blink {
        animation: blink-animation .5s steps(5, start) infinite;
        -webkit-animation: blink-animation .5s steps(5, start) infinite;
        animation-iteration-count: 3;
    }

    @keyframes blink-animation {
        0% {
            opacity: 0;
        }

        50% {
            opacity: .5;
        }

        100% {
            opacity: 1;
        }
    }
</style>

<script>
    import ModalFlowbite from '@/components/modal/ModalFlowbite.vue';
    import AsetHandling from './components/AsetHandling.vue';
    import AsetGudang from './AsetGudang.vue';
    export default {
        components: {
            AsetHandling,
            ModalFlowbite,
            AsetGudang
        },
        data() {
            return {
                startTimer: false,
                timer: 0,
                dataAset: [],
                aset_id: 0,
                kode_aset: '',
                viewMenu: false,
                top: '0px',
                left: '0px',
                el: '',
                tabClass: {
                    tabActiveIcon: 'w-4 h-4 me-2 text-blue-600 dark:text-blue-500',
                    tabNonActiveIcon: 'w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300',
                    tabActive: 'inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group',
                    tabNonActive: 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'
                },
                tabMenu: {
                    tabAset: true,
                    tabGudang: false
                },
                meeting_id: 0,
                componentModalName: '',
                showModal: false,
                limit: 100,
                offset: 0,
                total_page: 0,
                pagination: [],
                keyword: ''
            }
        },
        watch: {
            timer(value) {
                if (value == 150) {
                    this.openMenu(this.el, this.aset_id, this.kode_aset)
                }
            }
        },
        mounted() {
            window.addEventListener("contextmenu", (event) => {
                event.preventDefault();
            });
        },
        created() {
            this.getDataAset(this.offset)
        },
        methods: {
            changeTab(tab) {
                if (tab == 'aset') {
                    this.tabMenu.tabAset = true
                    this.tabMenu.tabGudang = false
                } else {
                    this.tabMenu.tabAset = false
                    this.tabMenu.tabGudang = true
                }
            },
            timerOn() {
                if (this.startTimer) {
                    setTimeout(() => {
                        this.timer += 1
                        this.timerOn()
                    }, 10)
                }
            },
            stopTimer() {
                this.startTimer = false
            },
            startCount(e, aset_id, kode_aset) {
                this.el = e
                this.aset_id = aset_id
                this.kode_aset = kode_aset
                this.startTimer = true
                this.timer = 0
                this.timerOn()
            },
            getDataAset(offset) {
                var limit = this.limit
                var keywordSearch = this.keyword
                var keywordSearchLength = keywordSearch.length

                this.$http.get(`/api/auth/aset/data-aset?limit=${limit}&offset=${offset}&keyword=${keywordSearch}`).then((response) => {
                    this.dataAset = response.data.data.data.dataAset
                    var total_row = response.data.data.data.totalRow
                    this.total_page = Math.ceil(total_row / this.limit)

                    var length_offset = -100
                    var number_paginate = 0
                    var pagination = []
                    for (let i = 0; i < this.total_page; i++) {
                        length_offset += 100
                        number_paginate += 1

                        var dataPagination = {}
                        dataPagination.offset = length_offset
                        dataPagination.page = number_paginate

                        pagination.push(dataPagination)
                    }

                    if (this.pagination.length == 0) {
                        this.pagination = pagination
                    }else{
                        if(keywordSearchLength != 0){
                            this.pagination = pagination
                        }else{
                            this.pagination = pagination
                        }
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })

                var aset_id = this.$route.query.aset_id
                if (aset_id) {
                    setTimeout(() => this.openModal('aset-detail-modal', 'modal-flowbite', parseInt(aset_id)), 2000)

                }
            },
            checkClassWidth(kondisi) {
                var style = `width: ${kondisi}%`
                return style;
            },
            checkClassColor(kondisi) {
                if (kondisi >= 1 && kondisi <= 20) {
                    var style = `bg-red-600 text-red-100`
                } else if (kondisi >= 21 && kondisi <= 40) {
                    var style = `bg-orange-600 text-orange-100`
                } else if (kondisi >= 41 && kondisi <= 60) {
                    var style = `bg-yellow-600 text-yellow-100`
                } else if (kondisi >= 61 && kondisi <= 80) {
                    var style = `bg-blue-600 text-blue-100`
                } else if (kondisi >= 81 && kondisi <= 100) {
                    var style = `bg-green-600 text-green-100`
                }
                return style;
            },
            openMenu(e, aset_id, kode_aset) {
                this.viewMenu = true;
                this.aset_id = aset_id;
                this.kode_aset = kode_aset;

                this.$nextTick(function () {
                    const editButtonRef = this.$refs.menu_details;
                    editButtonRef.focus();

                    this.setMenu(e.y, e.x)
                }.bind(this));
                e.preventDefault();
            },
            closeMenu() {
                this.viewMenu = false;
            },
            setMenu(top, left) {
                var largestHeight = window.innerHeight - this.$refs.menu_details.offsetHeight - 25;
                var largestWidth = window.innerWidth - this.$refs.menu_details.offsetWidth - 25;

                if (top > largestHeight) top = largestHeight;

                if (left > largestWidth) left = largestWidth;

                this.top = top + 'px';
                this.left = left + 'px';
            },
            openModal(componentModalName, modalName, meeting_id) {
                this.meeting_id = meeting_id ? meeting_id : 0
                if (componentModalName != 'viewModalDetail') {
                    if (modalName == 'modal-flowbite') {
                        this.showModal = true
                    } else {
                        this.viewModal.viewModalReg = true
                    }
                } else {
                    this.viewModal[componentModalName] = true
                }
                this.componentModalName = componentModalName
            },
            closeModal(componentModalName, modalName) {
                if (modalName == 'modal-flowbite') {
                    this.showModal = false
                } else {
                    this.viewModal.viewModalReg = false
                    this.viewModal.viewModalDetail = false
                }
                location.reload();
            },
            searchTable() {
                var keywordSearch = this.keyword
                var keywordSearchLength = keywordSearch.length
                
                if(keywordSearchLength >= 3){}
            }
        }
    }
</script>