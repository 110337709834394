<template>
    <!-- <navbar></navbar> -->
    <div class="p-2 sm:ml-64 mt-16">
        <div class="flex items-center justify-left px-2">
            <span class="text-md font-bold mb-2">Riwayat LKPM</span>
        </div>
        <div class="p-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div class="relative overflow-x-auto sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-white bg-blue-600 dark:bg-blue-600 dark:text-white">
                        <tr class="border">
                            <th scope="col" rowspan="2" class="px-6 py-3 text-center">
                                No
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                No. Laporan
                            </th>
                            <th scope="col" class="px-6 py-3 text-center">
                                Tanggal
                            </th>
                            <th scope="col" rowspan="2" class="px-6 py-3 text-left">
                                Data Kegiatan Usaha
                            </th>
                            <th scope="col" rowspan="2" class="px-6 py-3 text-center">
                                Tanggal Due Date
                            </th>
                            <th scope="col" rowspan="2" class="px-6 py-3 text-center">
                                Tanggal Realisasi
                            </th>
                            <th scope="col" rowspan="2" class="px-6 py-3 text-left">
                                Status
                            </th>
                            <th scope="col" rowspan="2" class="px-6 py-3 text-center">
                                Aksi
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, i) in dataTableLkpm" :key="i"
                            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                            <td class="px-6 py-4 text-center">
                                {{ i + 1 }}
                            </td>
                            <td class="px-6 py-4 text-center">
                                {{ row.no_laporan }}
                            </td>
                            <td class="px-6 py-4 text-center whitespace-nowrap">
                                {{ row.tanggal_laporan }}
                            </td>
                            <td class="px-6 py-4 text-left">
                                <p class="mb-2"><span class="font-bold">Perizinan Usaha:</span>
                                    {{ row.perizinan_usaha }}</p>
                                <p class="mb-2 whitespace-nowrap"><span class="font-bold">No Kegiatan Usaha:</span>
                                    {{ row.no_kegiatan_usaha }}</p>
                            </td>
                            <td class="px-6 py-4 text-center whitespace-nowrap">
                                {{ row.tgl_due_date_pelaporan }}
                            </td>
                            <td class="px-6 py-4 text-center whitespace-nowrap">
                                {{ row.tgl_realisasi_pelaporan }}
                            </td>
                            <td class="px-6 py-4 text-left whitespace-nowrap">
                                <span :class="[row.badge_class]">{{ row.status_document }}</span>
                            </td>
                            <td class="px-6 py-4 text-center">
                                <div class="flex justify-center space-x-1">
                                    <button v-if="row.status_document == 'Belum Terlaporkan'"
                                        @click="updateStatusDoc(row.id_details)">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                                        </svg>
                                    </button>
                                    <button
                                        @click="toPageFiles(row.document_id, row.document_folder_id, row.index_number)">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 00-1.883 2.542l.857 6a2.25 2.25 0 002.227 1.932H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-1.883-2.542m-16.5 0V6A2.25 2.25 0 016 3.75h3.879a1.5 1.5 0 011.06.44l2.122 2.12a1.5 1.5 0 001.06.44H18A2.25 2.25 0 0120.25 9v.776" />
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import Navbar from '@/components/navbar/Navbar.vue'
    export default {
        components: {
            Navbar
        },
        data() {
            return {
                dataTableLkpm: []
            }
        },
        created() {
            this.getDetailLkpm()
        },
        methods: {
            getDetailLkpm() {
                this.$http.post('/api/auth/getDataDetailLkpm', {
                    no_laporan: this.$route.query.no
                }).then((response) => {
                    if (response.data.data.dataLkpm) {
                        var resp = response.data.data.dataLkpm
                        this.dataTableLkpm = resp
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            toPageFiles(document_id, document_folder_id, index_number) {
                var page = this.$router.resolve({
                    name: 'document-folder-page',
                    params: {
                        parent_id: document_folder_id,
                        index_number: index_number + 1
                    },
                    query: {
                        id: document_id
                    }
                })
                window.open(page.href, '_blank')
            },
            updateStatusDoc(id_details) {
                this.$http.post('/api/auth/updateStatusDocumentDetails', {
                    id_details: id_details
                }).then((response) => {
                    this.$swal('Updated!', '', 'Document berhasil di update')
                    setInterval(() => {
                        location.reload()
                    }, 1000)
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            }
        }
    }
</script>