<template>
    <!-- TABS -->
    <div class="border-b border-gray-200 dark:border-gray-700">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li class="mr-2" @click="getDataDetail()">
                <a :class="[tabs.tabsDetail ? tabActive : tabNonActive]">
                    <svg :class="[tabs.tabsDetail ? iconTabActive : iconTabNonActive]" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                    </svg>Details
                </a>
            </li>
            <li class="mr-2" @click="getDataPbb()" v-if="formValidation.formPbbValidation">
                <a :class="[tabs.tabsPbb ? tabActive : tabNonActive]" aria-current="page">
                    <svg :class="[tabs.tabsPbb ? iconTabActive : iconTabNonActive]" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                        <path
                            d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                    </svg>PBB
                </a>
            </li>
            <li class="mr-2" @click="getDataLkpm()" v-if="formValidation.formLkpmValidation">
                <a :class="[tabs.tabsLkpm ? tabActive : tabNonActive]">
                    <svg :class="[tabs.tabsLkpm ? iconTabActive : iconTabNonActive]" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z" />
                    </svg>LKPM
                </a>
            </li>
            <li class="mr-2" @click="getDataLokasi()">
                <a :class="[tabs.tabsLokasi ? tabActive : tabNonActive]">
                    <svg :class="[tabs.tabsLokasi ? iconTabActive : iconTabNonActive]"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path fill-rule="evenodd"
                            d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                            clip-rule="evenodd" />
                    </svg>
                    Lokasi
                </a>
            </li>
        </ul>
    </div>

    <!-- DETAILS -->
    <div class="px-10 mt-4 overflow-auto" v-if="tabs.tabsDetail">
        <label for="nama_folder"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Nama
            File <span class="text-red-700">*</span></label>
        <div class="relative mb-2">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    class="w-4 h-4 text-gray-500 dark:text-white">
                    <path fill-rule="evenodd"
                        d="M7.502 6h7.128A3.375 3.375 0 0118 9.375v9.375a3 3 0 003-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 00-.673-.05A3 3 0 0015 1.5h-1.5a3 3 0 00-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6zM13.5 3A1.5 1.5 0 0012 4.5h4.5A1.5 1.5 0 0015 3h-1.5z"
                        clip-rule="evenodd" />
                    <path fill-rule="evenodd"
                        d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V9.375zM6 12a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V12zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM6 15a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V15zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM6 18a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V18zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z"
                        clip-rule="evenodd" />
                </svg>

            </div>
            <input type="text" id="document_name" v-model="document_name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Nama File">
        </div>
        <div class="flex justify-end py-2 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button type="button" @click="openPage('document-editor-page', document_id)" v-if="is_image == false"
                class="text-white bg-yellow-300 hover:bg-yellow-300 focus:outline-none font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-yellow-300 dark:hover:bg-yellow-300">Update
                Dokumen</button>
            <button type="button" @click="saveEditNama"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700">Simpan</button>
        </div>
    </div>

    <!-- PBB -->
    <div class="px-10 mt-4" v-if="tabs.tabsPbb && formValidation.formPbbValidation">
        <div id="alert-additional-content-2"
            class="p-4 mb-4 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
            role="alert">
            <div class="mt-2 mb-4 text-sm">
                Jika file yang dipilih termasuk ke dalam file PBB, maka silahkan isi detail di bawah ini
            </div>
        </div>

        <hr class="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700">
        <div class="h-80 overflow-auto">
            <div class="grid md:grid-cols-2 md:gap-3">
                <div class="relative z-0 w-full mb-0 group">
                    <label for="nama_pemegang_hak"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Nama
                        Pemegang
                        Hak
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path fill-rule="evenodd"
                                        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="text" id="nama_pemegang_hak" v-model="dataPbb.nama_pemegang_hak"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Nama Pemegang Hak">
                        </div>
                    </div>
                </div>
                <div class="relative z-0 w-full mb-0 group">
                    <label for="lokasi"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Lokasi
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path fill-rule="evenodd"
                                        d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="text" id="lokasi" v-model="dataPbb.lokasi"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Lokasi">
                        </div>
                    </div>
                </div>
                <!----------------------->
                <div class="relative z-0 w-full mb-0 group">
                    <label for="nop"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">NOP
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path
                                        d="M18.75 12.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM12 6a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 6zM12 18a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 18zM3.75 6.75h1.5a.75.75 0 100-1.5h-1.5a.75.75 0 000 1.5zM5.25 18.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM3 12a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 12zM9 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM12.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM9 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                                </svg>
                            </div>
                            <input type="text" id="nop" v-model="dataPbb.nop"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="NOP">
                        </div>
                    </div>
                </div>
                <div class="relative z-0 w-full mb-0 group">
                    <label for="nama_wajib_pajak"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Nama
                        Wajib
                        Pajak
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path fill-rule="evenodd"
                                        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="text" id="nama_wajib_pajak" v-model="dataPbb.nama_wajib_pajak"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Nama Wajib Pajak">
                        </div>
                    </div>
                </div>
                <!----------------------->
                <div class="relative z-0 w-full mb-0 group">
                    <label for="alamat_wajib_pajak"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Alamat
                        Wajib
                        Pajak
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path fill-rule="evenodd"
                                        d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="text" id="alamat_wajib_pajak" v-model="dataPbb.alamat_wajib_pajak"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Alamat Wajib Pajak">
                        </div>
                    </div>
                </div>
                <div class="relative z-0 w-full mb-0 group">
                    <label for="letak_objek_pajak"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Letak
                        Objek
                        Pajak
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path fill-rule="evenodd"
                                        d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="text" id="letak_objek_pajak" v-model="dataPbb.letak_objek_pajak"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Letak Objek Pajak">
                        </div>
                    </div>
                </div>
                <!----------------------->
                <div class="relative z-0 w-full mb-0 group">
                    <label for="luas_bumi"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Luas Bumi
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path
                                        d="M15.75 8.25a.75.75 0 01.75.75c0 1.12-.492 2.126-1.27 2.812a.75.75 0 11-.992-1.124A2.243 2.243 0 0015 9a.75.75 0 01.75-.75z" />
                                    <path fill-rule="evenodd"
                                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM4.575 15.6a8.25 8.25 0 009.348 4.425 1.966 1.966 0 00-1.84-1.275.983.983 0 01-.97-.822l-.073-.437c-.094-.565.25-1.11.8-1.267l.99-.282c.427-.123.783-.418.982-.816l.036-.073a1.453 1.453 0 012.328-.377L16.5 15h.628a2.25 2.25 0 011.983 1.186 8.25 8.25 0 00-6.345-12.4c.044.262.18.503.389.676l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.575 15.6z"
                                        clip-rule="evenodd" />
                                </svg>

                            </div>
                            <input type="number" id="luas_bumi" v-model="dataPbb.luas_bumi"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Luas Bumi">
                        </div>
                    </div>
                </div>
                <div class="relative z-0 w-full mb-0 group">
                    <label for="luas_bangunan"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Luas
                        Bangunan
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path fill-rule="evenodd"
                                        d="M3 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5H15v-18a.75.75 0 000-1.5H3zM6.75 19.5v-2.25a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75zM6 6.75A.75.75 0 016.75 6h.75a.75.75 0 010 1.5h-.75A.75.75 0 016 6.75zM6.75 9a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM6 12.75a.75.75 0 01.75-.75h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 6a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zm-.75 3.75A.75.75 0 0110.5 9h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 12a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM16.5 6.75v15h5.25a.75.75 0 000-1.5H21v-12a.75.75 0 000-1.5h-4.5zm1.5 4.5a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008zm.75 2.25a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75v-.008a.75.75 0 00-.75-.75h-.008zM18 17.25a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z"
                                        clip-rule="evenodd" />
                                </svg>

                            </div>
                            <input type="number" id="luas_bangunan" v-model="dataPbb.luas_bangunan"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Luas Bangunan">
                        </div>
                    </div>
                </div>

                <div class="relative z-0 w-full group">
                    <label for="njop"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">NJOP
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path
                                        d="M18.75 12.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM12 6a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 6zM12 18a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 18zM3.75 6.75h1.5a.75.75 0 100-1.5h-1.5a.75.75 0 000 1.5zM5.25 18.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM3 12a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 12zM9 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM12.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM9 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                                </svg>
                            </div>
                            <input type="number" id="njop" v-model="dataPbb.njop"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="NJOP">
                        </div>
                    </div>
                </div>
                <div class="relative z-0 w-full group">
                    <label for="no_shm"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">No SHM
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path fill-rule="evenodd"
                                        d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625zM7.5 15a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 017.5 15zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H8.25z"
                                        clip-rule="evenodd" />
                                    <path
                                        d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                                </svg>
                            </div>
                            <input type="number" id="no_shm" v-model="dataPbb.no_shm"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="No SHM">
                        </div>
                    </div>
                </div>
            </div>

            <hr class="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700">

            <div class="grid md:grid-cols-2 md:gap-3">
                <div class="relative z-0 w-full mb-0 group">
                    <label for="tahun"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Tahun
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path fill-rule="evenodd"
                                        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="number" id="tahun" v-model="dataPbb.tahun"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Tahun">
                        </div>
                    </div>
                </div>
                <div class="relative z-0 w-full mb-0 group">
                    <label for="ketetapan"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Ketetapan
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path fill-rule="evenodd"
                                        d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5zm6.61 10.936a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                        clip-rule="evenodd" />
                                    <path
                                        d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                                </svg>
                            </div>
                            <input type="text" id="ketetapan" v-model="dataPbb.ketetapan"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Ketetapan">
                        </div>
                    </div>
                </div>
                <!----------------------->
                <div class="relative z-0 w-full mb-0 group">
                    <label for="jatuh_tempo"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Jatuh
                        Tempo
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path
                                        d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                                    <path fill-rule="evenodd"
                                        d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="date" id="jatuh_tempo" v-model="dataPbb.jatuh_tempo"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Ketetapan">
                        </div>
                    </div>
                </div>
                <div class="relative z-0 w-full mb-0 group">
                    <label for="jumlah_bayar"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Jumlah
                        Bayar
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path
                                        d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                                    <path fill-rule="evenodd"
                                        d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="number" id="jumlah_bayar" v-model="dataPbb.jumlah_bayar"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Cth. 200000">
                        </div>
                    </div>
                </div>
                <!----------------------->
                <div class="relative z-0 w-full mb-0 group">
                    <label for="tanggal_bayar"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Tanggal
                        Bayar
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path
                                        d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                                    <path fill-rule="evenodd"
                                        d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="date" id="tanggal_bayar" v-model="dataPbb.tanggal_bayar"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Ketetapan">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-end py-2 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button type="button" @click="saveEditPbb"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700">Simpan</button>
        </div>
    </div>

    <!-- LKPM -->
    <div class="px-10 mt-4" v-if="tabs.tabsLkpm && formValidation.formLkpmValidation">
        <div id="alert-additional-content-2"
            class="p-4 mb-4 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
            role="alert">
            <div class="mt-2 mb-4 text-sm">
                Jika file yang dipilih termasuk ke dalam file LKPM, maka silahkan isi detail di bawah ini
            </div>
        </div>

        <hr class="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700">
        <div class="h-80 overflow-auto">
            <div class="grid md:grid-cols-2 md:gap-3">

                <div class="relative z-0 w-full mb-0 group">
                    <label for="no_laporan"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">No
                        Laporan
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path fill-rule="evenodd"
                                        d="M2.625 6.75a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0A.75.75 0 018.25 6h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75zM2.625 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM7.5 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12A.75.75 0 017.5 12zm-4.875 5.25a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875 0a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75z"
                                        clip-rule="evenodd" />
                                </svg>

                            </div>
                            <input type="text" id="no_laporan" v-model="dataLkpm.no_laporan"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="No Laporan">
                        </div>
                    </div>
                </div>
                <div class="relative z-0 w-full mb-0 group">
                    <label for="tanggal_laporan"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Tanggal
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path
                                        d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                                    <path fill-rule="evenodd"
                                        d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="date" id="tanggal_laporan" v-model="dataLkpm.tanggal_laporan"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="">
                        </div>
                    </div>
                </div>
                <!----------------------->
                <div class="relative z-0 w-full mb-0 group">
                    <label for="perizinan_usaha"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Perizinan
                        Usaha
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path fill-rule="evenodd"
                                        d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625zM7.5 15a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 017.5 15zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H8.25z"
                                        clip-rule="evenodd" />
                                    <path
                                        d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                                </svg>

                            </div>
                            <input type="text" id="perizinan_usaha" v-model="dataLkpm.perizinan_usaha"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Perizinan Usaha">
                        </div>
                    </div>
                </div>
                <div class="relative z-0 w-full mb-0 group">
                    <label for="no_kegiatan_usaha"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">No
                        Kegiatan Usaha
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path fill-rule="evenodd"
                                        d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625zM7.5 15a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 017.5 15zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H8.25z"
                                        clip-rule="evenodd" />
                                    <path
                                        d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                                </svg>

                            </div>
                            <input type="text" id="no_kegiatan_usaha" v-model="dataLkpm.no_kegiatan_usaha"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="No Kegiatan Usaha">
                        </div>
                    </div>
                </div>
                <!----------------------->
                <div class="relative z-0 w-full mb-0 group">
                    <label for="tgl_due_date_pelaporan"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Due Date
                        Pelaporan
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path
                                        d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                                    <path fill-rule="evenodd"
                                        d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="date" id="tgl_due_date_pelaporan" v-model="dataLkpm.tgl_due_date_pelaporan"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="">
                        </div>
                    </div>
                </div>
                <div class="relative z-0 w-full mb-0 group">
                    <label for="tgl_realisasi_pelaporan"
                        class="block mb-0 text-sm font-medium text-gray-900 dark:text-gray-900 ml-1 text-left">Tgl
                        Realisasi Pelaporan
                        <span class="text-red-700">*</span></label>
                    <div class="relative mb-0">
                        <div class="relative mb-0">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-4 h-4 text-gray-500 dark:text-white">
                                    <path
                                        d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                                    <path fill-rule="evenodd"
                                        d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="date" id="tgl_realisasi_pelaporan" v-model="dataLkpm.tgl_realisasi_pelaporan"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="">
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="flex justify-end py-2 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button type="button" @click="saveEditLkpm"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700">Simpan</button>
        </div>
    </div>

    <!-- LOKASI -->
    <div class="px-10 mt-4 overflow-auto" v-if="tabs.tabsLokasi">
        <label for="nama_folder"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Lokasi Dokumen<span
                class="text-red-700">*</span></label>
        <div class="relative mb-2">
            <select id="level_dokumen" v-model="lokasi_dokumen"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option selected value="0">Pilih</option>
                <option v-for="(row, i) in dataLokasiDoc" :key="i" v-bind:value="row.id">
                    {{row.description}}</option>
            </select>
        </div>
        <div class="flex justify-end py-2 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button type="button" @click="saveEditLokasi"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700">Simpan</button>
        </div>
    </div>

</template>

<script>
    import {
        Dismiss
    } from "flowbite";
    export default {
        components: {
            Dismiss
        },
        props: {
            viewModalEdit: Boolean,
            document_id: Number
        },
        emits: ["closeModal"],
        data() {
            return {
                iconTabActive: 'w-4 h-4 mr-2 text-blue-600 dark:text-blue-500',
                iconTabNonActive: 'w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300',
                tabActive: 'inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group',
                tabNonActive: 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group',
                tabs: {
                    tabsDetail: true,
                    tabsPbb: false,
                    tabsLkpm: false,
                    tabsLokasi: false
                },
                document_name: "",
                dataPbb: {
                    id_details: null,
                    document_type_id: 3,
                    nama_pemegang_hak: "",
                    lokasi: "",
                    nop: null,
                    nama_wajib_pajak: "",
                    alamat_wajib_pajak: "",
                    letak_objek_pajak: "",
                    luas_bumi: null,
                    luas_bangunan: null,
                    njop: null,
                    no_shm: null,
                    tahun: null,
                    ketetapan: "",
                    jatuh_tempo: null,
                    jumlah_bayar: 0,
                    tanggal_bayar: null,
                    document_id: this.document_id
                },
                checkDataPbb: 0,
                dataLkpm: {
                    document_type_id: 4,
                    no_laporan: null,
                    tanggal_laporan: null,
                    perizinan_usaha: null,
                    no_kegiatan_usaha: null,
                    tgl_due_date_pelaporan: null,
                    tgl_realisasi_pelaporan: null,
                    document_id: this.document_id
                },
                checkDataLkpm: 0,
                formValidation: {
                    formPbbValidation: false,
                    formLkpmValidation: false
                },
                lokasi_dokumen: 0,
                dataLokasiDoc: [],
                is_image: false
            }
        },
        created() {
            this.cekDataDetails();
        },
        methods: {
            cekDataDetails() {
                let a = this;
                this.$http.post('/api/auth/getDetailDoc', {
                    document_id: this.document_id
                }).then((response) => {
                    var document_type_id = response.data.data.dataDoc[0].document_type_id
                    var document_extension = response.data.data.dataDoc[0].document_extension

                    var checDocumentType = document_type_id
                    this.formValidation.formPbbValidation = checDocumentType == null || checDocumentType == 3 ?
                        true : false
                    this.formValidation.formLkpmValidation = checDocumentType == null || checDocumentType == 4 ?
                        true : false

                    if (this.checkImage(document_extension)) {
                        this.is_image = true
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            checkImage(extension) {
                const imageArray = ['JPG', 'PNG', 'TIFF', 'JPEG', 'GIF']
                const checkExt = imageArray.includes(extension);
                return checkExt
            },
            getDataDetail() {
                this.tabs.tabsDetail = true
                this.tabs.tabsPbb = false
                this.tabs.tabsLkpm = false
                this.tabs.tabsLokasi = false
            },
            getDataPbb() {
                this.tabs.tabsDetail = false
                this.tabs.tabsPbb = true
                this.tabs.tabsLkpm = false
                this.tabs.tabsLokasi = false

                let a = this;
                this.$http.post('/api/auth/getDetailDoc', {
                    document_id: this.document_id
                }).then((response) => {
                    var dataDoc = response.data.data.dataDoc
                    this.checkDataPbb = dataDoc[0].id != null ? dataDoc.length : 0
                    if (this.checkDataPbb != 0) {
                        dataDoc.forEach(function (arrayItem) {
                            a.dataPbb = {
                                id_details: arrayItem.id,
                                document_type_id: arrayItem.document_type_id,
                                nama_pemegang_hak: arrayItem.nama_pemegang_hak,
                                lokasi: arrayItem.lokasi,
                                nop: arrayItem.nop,
                                nama_wajib_pajak: arrayItem.nama_wajib_pajak,
                                alamat_wajib_pajak: arrayItem.alamat_wajib_pajak,
                                letak_objek_pajak: arrayItem.letak_objek_pajak,
                                luas_bumi: arrayItem.luas_bumi,
                                luas_bangunan: arrayItem.luas_bangunan,
                                njop: arrayItem.njop,
                                no_shm: arrayItem.no_shm,
                                tahun: arrayItem.tahun,
                                ketetapan: arrayItem.ketetapan,
                                jatuh_tempo: arrayItem.jatuh_tempo,
                                jumlah_bayar: arrayItem.jumlah_bayar,
                                tanggal_bayar: arrayItem.tanggal_bayar,
                                document_id: arrayItem.document_id
                            }
                        });
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            getDataLkpm() {
                this.tabs.tabsDetail = false
                this.tabs.tabsPbb = false
                this.tabs.tabsLkpm = true
                this.tabs.tabsLokasi = false

                let a = this;
                this.$http.post('/api/auth/getDetailDoc', {
                    document_id: this.document_id
                }).then((response) => {
                    var dataDoc = response.data.data.dataDoc
                    this.checkDataLkpm = dataDoc[0].id != null ? dataDoc.length : 0
                    if (this.checkDataLkpm != 0) {
                        dataDoc.forEach(function (arrayItem) {
                            a.dataLkpm = {
                                id_details: arrayItem.id,
                                document_type_id: arrayItem.document_type_id,
                                no_laporan: arrayItem.no_laporan,
                                tanggal_laporan: arrayItem.tanggal_laporan,
                                perizinan_usaha: arrayItem.perizinan_usaha,
                                no_kegiatan_usaha: arrayItem.no_kegiatan_usaha,
                                tgl_due_date_pelaporan: arrayItem.tgl_due_date_pelaporan,
                                tgl_realisasi_pelaporan: arrayItem.tgl_realisasi_pelaporan,
                                document_id: arrayItem.document_id
                            }
                        });
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            saveEditNama() {
                if (this.document_name == "") {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'Mohon isi kolom nama file terlebih dahulu',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    this.$http.post('/api/auth/renameDataDoc', {
                        document_id: this.document_id,
                        document_name: this.document_name
                    }).then((response) => {
                        if (response.data.status == "Success") {
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Nama dokumen berhasil di simpan',
                                showConfirmButton: false,
                                timer: 1200
                            })
                            setInterval(location.reload(), 1500);
                        }
                    }).catch((err) => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Mohon maaf terjadi kesalahan, nama dokumen sudah digunakan!',
                        })
                    })
                }
            },
            saveEditPbb() {
                this.$http.post('/api/auth/saveEditPbb', {
                    dataPbb: this.dataPbb,
                    checkDataPbb: this.checkDataPbb
                }).then((response) => {
                    if (response.data.status == "Success") {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Dokumen PBB berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1200
                        })
                        setInterval(location.reload(), 1500);
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            saveEditLkpm() {
                this.$http.post('/api/auth/saveEditLkpm', {
                    dataLkpm: this.dataLkpm,
                    checkDataLkpm: this.checkDataLkpm
                }).then((response) => {
                    if (response.data.status == "Success") {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Dokumen LKPM berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1200
                        })
                        setInterval(location.reload(), 1500);
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            getDataLokasi() {
                this.tabs.tabsDetail = false
                this.tabs.tabsPbb = false
                this.tabs.tabsLkpm = false
                this.tabs.tabsLokasi = true
                this.$http.get('/api/auth/getDataLokasi').then((response) => {
                    this.dataLokasiDoc = response.data.data
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            saveEditLokasi() {
                this.$http.post('/api/auth/updateLokasiDocument', {
                    document_id: this.document_id,
                    document_lokasi: this.lokasi_dokumen
                }).then((response) => {
                    if (response.data.status == "Success") {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Lokasi dokumen berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1200
                        })
                        setInterval(location.reload(), 1500);
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            openPage(router_name, document_id) {
                let r = this.$router.resolve({
                    name: router_name,
                    params: {
                        document_id: document_id
                    }
                });
                window.location.assign(r.href)
            },
        }

    }
</script>