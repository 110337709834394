<template>
    <div>
        <div class="px-14 mt-2 overflow-auto">
            <div>
                <label for="nama_folder"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 ml-0 text-left">Nama
                    Folder <span class="text-red-700">*</span></label>
                <div class="relative mb-2">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                            class="w-4 h-4 text-gray-500 dark:text-white">
                            <path fill-rule="evenodd"
                                d="M7.502 6h7.128A3.375 3.375 0 0118 9.375v9.375a3 3 0 003-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 00-.673-.05A3 3 0 0015 1.5h-1.5a3 3 0 00-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6zM13.5 3A1.5 1.5 0 0012 4.5h4.5A1.5 1.5 0 0015 3h-1.5z"
                                clip-rule="evenodd" />
                            <path fill-rule="evenodd"
                                d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V9.375zM6 12a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V12zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM6 15a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V15zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM6 18a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V18zm2.25 0a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z"
                                clip-rule="evenodd" />
                        </svg>

                    </div>
                    <input type="text" id="nama_folder" v-model="nama_folder"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Masukkan nama folder">
                </div>
            </div>

            <div class="flex justify-end py-2 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button type="button" @click="saveFolder"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700">Simpan</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                parent_id: this.$route.params.parent_id,
                index_number: this.$route.params.index_number,
                nama_folder: ''
            }
        },
        props: {
            document_id: Number
        },
        emits: ["closeModal"],
        methods: {
            saveFolder() {
                var nama_folder = this.nama_folder
                var parent_id = this.parent_id
                var index_number = this.index_number

                this.$http.post('/api/auth/saveNewFolder', {
                    dataFolder: {
                        description: nama_folder,
                        parent_id: parent_id,
                        index_number: index_number,
                        root_id: 0
                    }
                }).then((response) => {
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Mantap',
                        text: 'Berhasil simpan data',
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            location.reload();
                        }
                    })
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            }
        }
    }
</script>