export const isUserLoggedIn = () => {
    return localStorage.getItem('storageTokenKeyName')
}

export const getStorageTokenKeyName = () => {
    return JSON.parse(localStorage.getItem('storageTokenKeyName'))
}

export const getHomeRouteForLoggedInUser = () => {
    return {
        name: 'dashboard'
    }
}

export const getLoginRouteForLoggedInUser = () => {
    return {
        name: 'login-page'
    }
}

export const redirectToSSOAuthentication = () => {
    return {
        name: 'sso-authentication'
    }
}