<template>
    <div class="h-full">
        <!-- <navbar></navbar> -->
        <div id="dataDiv">
            <!-- <breadcrumb v-if="parent_id != 0" :dataIndexFolder="dataFolderIndex"
                :dataIndexFolderParent="dataFolderIndexParent"></breadcrumb> -->
            <!-- Folder -->
            <div v-if="dataFolder.length != 0">
                <div class="flex items-center justify-left px-2">
                    <span class="text-md font-bold mb-2">Folder</span>
                </div>
                <div class="p-2 rounded-lg dark:border-gray-700">
                    <div class="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 lg:grid-cols-6 gap-2 mb-4">
                        <button @click="getDataDocumentRoute(row.folder_root, row.folder_id, row.folder_index)"
                            @mouseup.right="openMenu($event, row.folder_id, 'folder_details', row.add_to_regulator)"
                            class="flex items-center justify-left h-12 rounded bg-gray-300 dark:bg-gray-300 hover:bg-gray-400 px-5 py-2"
                            v-for="(row, i) in dataFolder" :key="i">
                            <p class="text-gray-600">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                    class="w-5 h-5">
                                    <path
                                        d="M19.5 21a3 3 0 003-3v-4.5a3 3 0 00-3-3h-15a3 3 0 00-3 3V18a3 3 0 003 3h15zM1.5 10.146V6a3 3 0 013-3h5.379a2.25 2.25 0 011.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 013 3v1.146A4.483 4.483 0 0019.5 9h-15a4.483 4.483 0 00-3 1.146z" />
                                </svg>

                            </p>
                            <span class="ml-2 text-sm truncate">{{row.folder_name}}</span>
                        </button>
                    </div>
                </div>
            </div>

            <!-- File -->
            <div v-if="dataFile.length != 0">
                <div class="flex items-center justify-left px-2">
                    <span class="text-md font-bold mb-2">File</span>
                </div>
                <div class="p-2 rounded-lg dark:border-gray-700">
                    <div class="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 lg:grid-cols-6 gap-2 mb-4">
                        <div @mouseup.right="openMenu($event, row.file_id, 'file_details', 1)" v-for="(row, i) in dataFile"
                            :key="i" :id="['files_' + row.file_id]" @dblclick="toPage('open-document', row.file_id)"
                            class="rounded bg-gray-300 dark:bg-gray-300 hover:bg-gray-400 h-52">
                            <a class="flex items-top justify-left px-5 py-2">
                                <p class="text-gray-400 dark:text-gray-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                        class="w-5 h-5 fill-green-600">
                                        <path
                                            d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z" />
                                        <path
                                            d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                                    </svg>
                                </p>
                                <span class="ml-2 text-sm truncate" @mouseover="showPopover(i)"
                                    @mouseleave="closePopover(i)">{{row.file_name}}</span>
                                <span class="ml-2 text-sm absolute rounded bg-gray-900 px-1 hidden text-white mt-5 ml-8"
                                    tabindex="1" :id="['popover_' + i]">{{row.file_name}}</span>
                            </a>
                            <div class="flex h-40 p-2 item-center justify-center"
                                v-if="checkImage(row.document_extension)">
                                <img :src="row.file">
                            </div>
                            <div class="flex h-36 p-2 item-center justify-center" v-else>
                                <img :src="documentLogo(row.document_extension)">
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <!--Document Handling-->
            <ul id="right-click-menu" tabindex="-1" ref="menu_details" v-if="viewMenu" :style="{top: top, left:left}"
                v-on:blur="closeMenu" v-on:mouseleave="closeMenu" class="rounded">
                <document-handling :status="documentHandlingStatus" :document_id="document_id"
                    :add_to_regulator="add_to_regulator" :is_read="is_read" :is_write="is_write" @openModal="openModal">
                </document-handling>
            </ul>

            <button type="button" @click="openMenu($event, 0, 'tambah_document', 1)"
                class="float-right fixed bottom-4 right-4 text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                    <path fill-rule="evenodd"
                        d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                        clip-rule="evenodd" />
                </svg>
            </button>

            <modal :viewModal="viewModal.viewModalReg" :componentName="componentModalName" @closeModal="closeModal"
                :document_id="document_id">
            </modal>

            <document-detail-modal v-if="viewModal.viewModalDetail" :viewModalDetail="viewModal.viewModalDetail"
                :document_id="document_id" @closeModal="closeModal"></document-detail-modal>

            <modal-flowbite :showModal="showModal" :componentName="componentModalName" @closeModal="closeModal"
                :document_id="document_id"></modal-flowbite>

        </div>
    </div>
</template>
<style>
    #right-click-menu {
        background: #FAFAFA;
        border: 1px solid #BDBDBD;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        width: 250px;
        z-index: 999999;
        outline: none;
    }

    #right-click-menu li {
        border-bottom: 1px solid #E0E0E0;
        margin: 0;
        padding: 10px 10px;
    }

    #right-click-menu li:last-child {
        border-bottom: none;
    }

    #right-click-menu li:hover {
        background: #1E88E5;
        color: #FAFAFA;
    }

    .blink {
        animation: blink-animation .5s steps(5, start) infinite;
        -webkit-animation: blink-animation .5s steps(5, start) infinite;
        animation-iteration-count: 3;
    }

    @keyframes blink-animation {
        0% {
            opacity: 0;
        }

        50% {
            opacity: .5;
        }

        100% {
            opacity: 1;
        }
    }
</style>
<script>
    import Navbar from '@/components/navbar/Navbar.vue'
    import DocumentHandling from './components/DocumentHandling.vue'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import DocumentCreationModal from './components/DocumentCreationModal.vue'
    import Modal from '@/components/modal/Modal.vue'
    import DocumentDetailModal from './components/DocumentDetailModal.vue'
    import ModalFlowbite from '@/components/modal/ModalFlowbite.vue'

    export default {
        components: {
            Navbar,
            DocumentHandling,
            Breadcrumb,
            DocumentCreationModal,
            Modal,
            DocumentDetailModal,
            ModalFlowbite
        },
        data() {
            return {
                parent_id: this.$route.params.parent_id,
                index_number: this.$route.params.index_number,
                root_id: 1,
                dataFolderIndexParent: [],
                dataFolderIndex: [],
                dataFolder: [],
                dataFile: [],
                viewMenu: false,
                top: '0px',
                left: '0px',
                documentHandlingStatus: "",
                viewModal: {
                    viewModalReg: false,
                    viewModalDetail: false
                },
                viewPopover: true,
                componentModalName: '',
                document_id: null,
                add_to_regulator: 1,
                showModal: false,
                is_read: 2,
                is_write: 2
            }
        },
        emits: ["closeModal"],
        created() {
            this.getDataDocument()
        },
        mounted() {
            window.addEventListener("contextmenu", (event) => {
                event.preventDefault();
            });
        },
        watch: {
            $route(to, from) {
                this.parent_id = to.params.parent_id
                this.index_number = to.params.index_number
                this.getDataDocument(to.params.parent_id, to.params.index_number)
            }
        },
        methods: {
            checkImage(extension) {
                const imageArray = ['JPG', 'PNG', 'TIFF', 'JPEG', 'GIF']
                const checkExt = imageArray.includes(extension);
                return checkExt
            },
            documentLogo(document_extension) {
                if (document_extension) {
                    var doc_ext = document_extension.toLowerCase();
                    var icon_file = doc_ext + '.png'
                    var url_file = 'https://is3.cloudhost.id/legalrsii/images/' + icon_file
                    return url_file
                } else {
                    var url_file = 'https://is3.cloudhost.id/legalrsii/images/empty_doc.png'
                    return url_file
                }
            },
            showPopover(i) {
                var element = document.getElementById("popover_" + i);
                element.classList.remove("hidden");
            },
            closePopover(i) {
                var element = document.getElementById("popover_" + i);
                element.classList.add("hidden");
            },
            setMenu(top, left) {
                var largestHeight = window.innerHeight - this.$refs.menu_details.offsetHeight - 25;
                var largestWidth = window.innerWidth - this.$refs.menu_details.offsetWidth - 25;

                if (top > largestHeight) top = largestHeight;

                if (left > largestWidth) left = largestWidth;

                this.top = top + 'px';
                this.left = left + 'px';
            },
            openModal(componentModalName, modalName) {
                if (componentModalName != 'viewModalDetail') {
                    if (modalName == 'modal-flowbite') {
                        this.showModal = true
                    } else {
                        this.viewModal.viewModalReg = true
                    }
                } else {
                    this.viewModal[componentModalName] = true
                }
                this.componentModalName = componentModalName
            },
            closeModal(componentModalName, modalName) {
                if (modalName == 'modal-flowbite') {
                    this.showModal = false
                } else {
                    this.viewModal.viewModalReg = false
                    this.viewModal.viewModalDetail = false
                }
                location.reload();
            },
            closeMenu() {
                this.viewMenu = false;
            },
            openMenu(e, file_id, handling_status, add_to_regulator) {
                this.viewMenu = true;
                this.documentHandlingStatus = handling_status;
                this.document_id = file_id;
                this.add_to_regulator = add_to_regulator

                this.$nextTick(function () {
                    const editButtonRef = this.$refs.menu_details;
                    editButtonRef.focus();

                    this.setMenu(e.y, e.x)
                }.bind(this));
                e.preventDefault();
            },
            getDataDocumentRoute(root_id, parent_id, index_number) {
                let parameter_parrent_id = parent_id ? parent_id : this.parent_id
                let parameter_index_number = index_number ? parseInt(index_number) + 1 : this.index_number
                this.root_id = root_id

                this.$router.replace({
                    name: 'document-folder-page',
                    params: {
                        parent_id: parameter_parrent_id,
                        index_number: parameter_index_number
                    }
                })
            },
            getDataDocument(parent_id, index_number) {
                let parameter_root_id = this.root_id
                let parameter_parent_id = parent_id ? parent_id : this.parent_id
                let parameter_index_number = index_number ? index_number : this.index_number

                this.$http.post('/api/auth/getDataDocument', {
                    root_id: parameter_root_id,
                    parent_id: parameter_parent_id,
                    index_number: parameter_index_number
                }).then((response) => {
                    if (response.data.data.data_folder_index) {
                        this.dataFolderIndexParent = response.data.data.data_folder_index[0] ? response.data
                            .data
                            .data_folder_index[0] : []
                        this.dataFolderIndex = response.data.data.data_folder_index ? response.data.data
                            .data_folder_index.slice(1) : []
                        this.dataFolder = response.data.data.data_folder ? response.data.data.data_folder : []
                        this.dataFile = response.data.data.data_file ? response.data.data.data_file : []


                        if (this.$route.query.id) {
                            this.$nextTick(function () {
                                var document_id = this.$route.query.id
                                var element = document.getElementById("files_" + document_id)
                                element.classList.remove("bg-gray-300");
                                element.classList.add("bg-blue-200");
                                element.classList.add("blink");
                            })
                        }
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            toPage(router_name, parameter) {
                this.$router.push({
                    name: router_name,
                    params: {
                        document_id: parameter
                    }
                });
            },
        }
    }
</script>