<template>
    <div class="max-w-full mx-auto">
        <div class="flex justify-center mb-6" v-for="(row, i) in dataDetailAset" :key="i">
            <!-- <vue-barcode  :value="row.kode_aset" :options="{ displayValue: true, lineColor: '#0388fc', width: '1px', height: '40px', fontSize: '12px' }"></vue-barcode> -->
            <!-- <qrcode-vue :value="getDataAsetUrl(row.kode_aset)" size="100" level="H" margin="0" /> -->
            <div
                class="max-w-fit bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <a @click="exportToPDF(row.kode_aset)">
                    <div id="element-to-convert">
                        <qrcode-vue :value="getDataAsetUrl(row.kode_aset)" size="100" level="L" margin="2" />
                        <div class="px-2">
                            <p class="text-xs flex justify-center px-2 py-0.5 rounded max-w-fit mb-2 bg-green-100">
                                {{row.kode_aset}}
                            </p>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div class="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-2">
            <ol class="relative text-gray-500 border-l border-gray-200 dark:border-gray-700 dark:text-gray-400"
                v-for="(row, i) in dataDetailAset" :key="i">
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Kode Aset</h3>
                    <div class="flex">
                        <p class="text-xs text-left">{{row.kode_aset}}</p>
                        <svg class="w-4 h-4 text-gray-400 hover:text-gray-600 ml-1" aria-hidden="true"
                            @click="openModal('aset-edit-modal', 'modal-flowbite', row.id)"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28" />
                        </svg>
                    </div>
                </li>
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9 16H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1M9 12H4m8 8V9h8v11h-8Zm0 0H9m8-4a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Nama Aset</h3>
                    <p class="text-xs text-left">{{row.nama_aset}}</p>
                </li>
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 4h12M6 4v16M6 4H5m13 0v16m0-16h1m-1 16H6m12 0h1M6 20H5M9 7h1v1H9V7Zm5 0h1v1h-1V7Zm-5 4h1v1H9v-1Zm5 0h1v1h-1v-1Zm-3 4h2a1 1 0 0 1 1 1v4h-4v-4a1 1 0 0 1 1-1Z" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Jenis Aset</h3>
                    <p class="text-xs text-left">{{row.jenis_aset}}</p>
                </li>
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                d="M3 21h18M4 18h16M6 10v8m4-8v8m4-8v8m4-8v8M4 9.5v-.955a1 1 0 0 1 .458-.84l7-4.52a1 1 0 0 1 1.084 0l7 4.52a1 1 0 0 1 .458.84V9.5a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5Z" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Perusahaan</h3>
                    <p class="text-xs text-left">{{row.entitas}}</p>
                </li>
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3.05A2.5 2.5 0 1 1 9 5.5M19.5 17h.5a1 1 0 0 0 1-1 3 3 0 0 0-3-3h-1m0-3.05a2.5 2.5 0 1 0-2-4.45m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3 1 1 0 0 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Divisi</h3>
                    <p class="text-xs text-left">{{row.divisi}}</p>
                </li>
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-width="2"
                                d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">User</h3>
                    <p class="text-xs text-left">{{row.nama_user ? row.nama_user : '-'}}</p>
                </li>
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M11 9h6m-6 3h6m-6 3h6M6.996 9h.01m-.01 3h.01m-.01 3h.01M4 5h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Spesifikasi</h3>
                    <p v-if="row.brand" class="text-xs flex text-left bg-yellow-100 px-2 py-0.5 rounded max-w-fit mb-2">
                        <svg class="w-4 h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9 16H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1M9 12H4m8 8V9h8v11h-8Zm0 0H9m8-4a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z" />
                        </svg>
                        {{row.brand}}
                    </p>
                    <p v-if="row.tipe" class="text-xs flex text-left bg-yellow-100 px-2 py-0.5 rounded max-w-fit mb-2">
                        <svg class="w-4 h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M5 14v7M5 4.971v9.541c5.6-5.538 8.4 2.64 14-.086v-9.54C13.4 7.61 10.6-.568 5 4.97Z" />
                        </svg>
                        {{row.tipe}}
                    </p>
                    <p v-if="row.seri" class="text-xs flex text-left bg-yellow-100 px-2 py-0.5 rounded max-w-fit mb-2">
                        <svg class="w-4 h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        {{row.seri}}
                    </p>
                    <p v-if="row.serial_number"
                        class="text-xs flex text-left bg-yellow-100 px-2 py-0.5 rounded max-w-fit mb-2">
                        <svg class="w-4 h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        {{row.serial_number}}
                    </p>
                </li>
            </ol>
            <ol class="relative text-gray-500 border-l border-gray-200 dark:border-gray-700 dark:text-gray-400"
                v-for="(row, i) in dataDetailAset" :key="i">
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M11 9h6m-6 3h6m-6 3h6M6.996 9h.01m-.01 3h.01m-.01 3h.01M4 5h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Kelengkapan</h3>
                    <p v-for="(kel, i) in dataDetailKelengkapan" :key="i"
                        class="text-xs flex text-left px-2 py-0.5 rounded max-w-fit mb-2"
                        :class="kel.flag_status == 1 ? 'bg-green-100' : 'bg-red-100'">
                        <svg class="w-4 h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9 16H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1M9 12H4m8 8V9h8v11h-8Zm0 0H9m8-4a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z" />
                        </svg>
                        <span class="font-bold">{{kel.nama_barang}} </span> | {{kel.status_aset_pelengkap}} |
                        {{kel.keterangan}}
                    </p>
                </li>
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Produksi - Kedatangan</h3>
                    <p class="text-xs text-left">{{row.tahun_produksi}} - {{row.tahun_kedatangan}}</p>
                </li>
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd"
                                d="M9 15a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm3.845-1.855a2.4 2.4 0 0 1 1.2-1.226 1 1 0 0 1 1.992-.026c.426.15.809.408 1.111.749a1 1 0 1 1-1.496 1.327.682.682 0 0 0-.36-.213.997.997 0 0 1-.113-.032.4.4 0 0 0-.394.074.93.93 0 0 0 .455.254 2.914 2.914 0 0 1 1.504.9c.373.433.669 1.092.464 1.823a.996.996 0 0 1-.046.129c-.226.519-.627.94-1.132 1.192a1 1 0 0 1-1.956.093 2.68 2.68 0 0 1-1.227-.798 1 1 0 1 1 1.506-1.315.682.682 0 0 0 .363.216c.038.009.075.02.111.032a.4.4 0 0 0 .395-.074.93.93 0 0 0-.455-.254 2.91 2.91 0 0 1-1.503-.9c-.375-.433-.666-1.089-.466-1.817a.994.994 0 0 1 .047-.134Zm1.884.573.003.008c-.003-.005-.003-.008-.003-.008Zm.55 2.613s-.002-.002-.003-.007a.032.032 0 0 1 .003.007ZM4 14a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Zm3-2a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1Zm6.5-8a1 1 0 0 1 1-1H18a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-.796l-2.341 2.049a1 1 0 0 1-1.24.06l-2.894-2.066L6.614 9.29a1 1 0 1 1-1.228-1.578l4.5-3.5a1 1 0 0 1 1.195-.025l2.856 2.04L15.34 5h-.84a1 1 0 0 1-1-1Z"
                                clip-rule="evenodd" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Vendor - Harga</h3>
                    <p class="text-xs text-left">{{row.vendor}} - {{formatPrice(row.harga_pembelian)}}</p>
                </li>
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9.5 11.5 11 13l4-3.5M12 20a16.405 16.405 0 0 1-5.092-5.804A16.694 16.694 0 0 1 5 6.666L12 4l7 2.667a16.695 16.695 0 0 1-1.908 7.529A16.406 16.406 0 0 1 12 20Z" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Status</h3>
                    <p class="text-xs flex text-left px-2 py-0.5 rounded max-w-fit mb-2"
                        :class="cekStatusAset(row.flag_status)">
                        <svg class="w-4 h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M5 14v7M5 4.971v9.541c5.6-5.538 8.4 2.64 14-.086v-9.54C13.4 7.61 10.6-.568 5 4.97Z" />
                        </svg>
                        {{row.status_aset}}
                    </p>
                </li>
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9.5 11.5 11 13l4-3.5M12 20a16.405 16.405 0 0 1-5.092-5.804A16.694 16.694 0 0 1 5 6.666L12 4l7 2.667a16.695 16.695 0 0 1-1.908 7.529A16.406 16.406 0 0 1 12 20Z" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Kondisi</h3>
                    <div class="w-1/4 bg-gray-200 rounded-full dark:bg-gray-700">
                        <div class="text-xs font-medium text-center p-0.5 leading-none rounded-full"
                            :class="checkClassColor(row.kondisi)" :style="checkClassWidth(row.kondisi)">
                            {{row.kondisi}}%</div>
                    </div>
                </li>
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Lokasi</h3>
                    <p class="text-xs text-left">{{row.lokasi_aset}}</p>
                </li>
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M5 19V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v13H7a2 2 0 0 0-2 2Zm0 0a2 2 0 0 0 2 2h12M9 3v14m7 0v4" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Legalitas</h3>
                    <p class="text-xs text-left">{{row.legalitas ? row.legalitas : '-'}}</p>
                </li>
                <li class="mb-6 ml-6">
                    <span
                        class="absolute flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                        </svg>
                    </span>
                    <h3 class="font-medium leading-tight text-left text-dark">Diupdate</h3>
                    <p class="text-xs text-left">{{formatDate(row.updated_at)}}</p>
                </li>
            </ol>
        </div>
    </div>

    <modal-flowbite :showModal="showModal" :componentName="componentModalName" @closeModal="closeModal"
        :document_id="meeting_id"></modal-flowbite>
</template>

<script>
    import VueBarcode from '@chenfengyuan/vue-barcode';
    import QrcodeVue from 'qrcode.vue'
    import ModalFlowbite from '@/components/modal/ModalFlowbite.vue';
    import html2pdf from "html2pdf.js";

    export default {
        components: {
            VueBarcode,
            QrcodeVue,
            ModalFlowbite
        },
        data() {
            return {
                dataDetailAset: [],
                dataDetailKelengkapan: [],
                meeting_id: 0,
                componentModalName: '',
                showModal: false
            }
        },
        created() {
            this.getDetailAset(this.$route.query.kode_aset)
        },
        methods: {
            getDataAsetUrl(kode_aset) {
                var url = `https://legal.rsii.id/aset-perusahaan/detail?kode_aset=${kode_aset}`
                return url
            },
            getDetailAset(kode_aset) {
                this.$http.get(`/api/auth/aset/data-aset-detail?kode_aset=${kode_aset}`).then((
                    response) => {
                    this.dataDetailAset = response.data.dataDetail
                    this.dataDetailKelengkapan = response.data.dataKelengkapan
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            formatDate(dateTime) {
                var date = new Date(dateTime).toLocaleString('ind', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    weekday: 'long'
                })
                var time = new Date(dateTime).toLocaleTimeString()
                var date_time = date + " " + time
                return date_time
            },
            cekStatusAset(flag_status) {
                if (flag_status == 1) {
                    var classStatus = 'bg-green-100'
                } else if (flag_status == 2) {
                    var classStatus = 'bg-orange-100'
                } else if (flag_status == 3) {
                    var classStatus = 'bg-red-100'
                }

                return classStatus
            },
            checkClassWidth(kondisi) {
                var style = `width: ${kondisi}%`
                return style;
            },
            checkClassColor(kondisi) {
                if (kondisi >= 1 && kondisi <= 20) {
                    var style = `bg-red-500 text-red-100`
                } else if (kondisi >= 21 && kondisi <= 40) {
                    var style = `bg-orange-500 text-orange-100`
                } else if (kondisi >= 41 && kondisi <= 60) {
                    var style = `bg-yellow-500 text-yellow-100`
                } else if (kondisi >= 61 && kondisi <= 80) {
                    var style = `bg-blue-500 text-blue-100`
                } else if (kondisi >= 81 && kondisi <= 100) {
                    var style = `bg-green-500 text-green-100`
                }
                return style;
            },
            openModal(componentModalName, modalName, meeting_id) {
                this.meeting_id = meeting_id ? meeting_id : 0
                if (componentModalName != 'viewModalDetail') {
                    if (modalName == 'modal-flowbite') {
                        this.showModal = true
                    } else {
                        this.viewModal.viewModalReg = true
                    }
                } else {
                    this.viewModal[componentModalName] = true
                }
                this.componentModalName = componentModalName
            },
            closeModal(componentModalName, modalName) {
                if (modalName == 'modal-flowbite') {
                    this.showModal = false
                } else {
                    this.viewModal.viewModalReg = false
                    this.viewModal.viewModalDetail = false
                }
                location.reload();
            },
            exportToPDF(kode_aset) {
                html2pdf(document.getElementById("element-to-convert"), {
                    margin: 1,
                    filename: `qrcode_${kode_aset}.pdf`,
                    jsPDF: {
                        format: [29, 36],
                        orientation: 'portrait'
                    }
                });
            }
        }
    }
</script>