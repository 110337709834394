<template>
    <div class="h-full">

        <div class="p-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div class="relative overflow-x-auto sm:rounded-lg">
                <div class="pb-4">
                    <label for="table-search" class="sr-only">Search</label>
                    <div class="relative mt-1">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input type="text" id="table-search" v-model="keywordSearch" @keyup="searchDoc"
                            class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search for items">
                    </div>
                </div>
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-white uppercase bg-blue-600 dark:bg-blue-600 dark:text-white">
                        <tr>
                            <th scope="col" class="p-4">
                                No
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Nama Dokumen
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Tanggal Expired
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Prioritas
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Level
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Pemilik
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="dataDoc.length != 0">
                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                            v-for="(row, i) in dataDoc" :key="i">
                            <td class="w-4 p-4">
                                {{i + 1}}
                            </td>
                            <td scope="row" class="px-6 py-4 font-medium w-2/5">
                                <span class="font-bold">{{row.document_name}}</span>
                                <p class="truncated break-words text-xs text-gray-400" :id="['deskripsi_' + i]"
                                    @mouseover="showButtonDetail(i,true)" @mouseleave="showButtonDetail(i,false)">
                                    {{row.description}}</p>
                                <div class="flex items-center space-x-3 pt-1 hidden" :id="['button_detail_' + i]"
                                    @mouseover="showButtonDetail(i,true)" @mouseleave="showButtonDetail(i,false)">
                                    <button @click="openPage('document-editor-page', row.id)" v-if="row.is_write == 2"
                                        class="font-medium text-gray-400 dark:text-gray-400 hover:underline">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                        </svg>
                                    </button>
                                    <button @click="openModal('document-share-modal', row.id)"
                                        class="font-medium text-gray-400 dark:text-gray-400 hover:underline">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                                        </svg>
                                    </button>
                                    <button @click="deleteDoc(row.id)" v-if="row.is_write == 2"
                                        class="font-medium text-gray-400 dark:text-gray-400 hover:underline">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    </button>
                                    <button @click="openModal('document-detail-modal', row.id)"
                                        class="font-medium text-gray-400 dark:text-gray-400 hover:underline">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                        </svg>
                                    </button>
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <span class="font-thin">{{formatDate(row.expiration_date)}}</span>
                            </td>
                            <td class="px-6 py-4">
                                <span :class="[row.priority_badge_class]">{{row.priority}}</span>
                            </td>
                            <td class="px-6 py-4">
                                <span class="font-thin">{{row.document_level}}</span>
                            </td>
                            <td class="px-6 py-4">
                                <span class="text-xs">{{row.pic}}</span>
                            </td>
                            <td class="px-6 py-4">
                                <span :class="[row.status_badge_class]">{{row.document_status}}</span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="7" class="text-center">No Data Showing</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <modal :viewModal="viewModal.viewModalReg" :componentName="componentModalName" @closeModal="closeModal"
                :document_id="document_id"></modal>

            <!-- <document-detail-modal v-if="viewModal.viewModalDetail" :viewModalDetail="viewModal.viewModalDetail"
                    :document_id="document_id" @closeModal="closeModal"></document-detail-modal>

                <document-share-modal v-if="viewModal.viewModalShare" :viewModalShare="viewModal.viewModalShare"
                    :document_id="document_id" @closeModal="closeModal"></document-share-modal> -->
        </div>
    </div>
</template>

<style scoped>
    #deskripsi {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
</style>
<script>
    import Navbar from '@/components/navbar/Navbar.vue'
    import Modal from '@/components/modal/Modal.vue';
    export default {
        components: {
            Navbar,
            Modal
        },
        data() {
            return {
                viewMenu: true,
                top: '0px',
                left: '0px',
                buttonDetail: false,
                dataDoc: [],
                dataDocBack: [],
                viewModal: {
                    viewModalReg: false,
                    viewModalDetail: false
                },
                document_id: 0,
                componentModalName: '',
                keywordSearch: ''
            }
        },
        mounted() {
            window.addEventListener("contextmenu", (event) => {
                event.preventDefault();
            });
        },
        created() {
            this.getDocumentCreation()
        },
        methods: {
            openModal(componentModalName, document_id) {
                this.componentModalName = componentModalName
                this.viewModal.viewModalReg = true
                this.document_id = document_id
            },
            closeModal(componentModalName, modalName) {
                if (modalName == 'modal-flowbite') {
                    this.showModal = false
                } else {
                    this.viewModal.viewModalReg = false
                    this.viewModal.viewModalDetail = false
                }
                location.reload();
            },
            openPage(router_name, document_id) {
                this.$router.push({
                    name: router_name,
                    params: {
                        document_id: document_id
                    }
                });
            },
            formatDate(dateTime) {
                var date = new Date(dateTime).toLocaleString('ind', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    weekday: 'long'
                })
                var time = new Date(dateTime).toLocaleTimeString()
                var date_time = date + " " + time
                return date_time
            },
            showButtonDetail(i, stat) {
                if (stat == true) {
                    var element = document.getElementById("button_detail_" + i);
                    element.classList.remove("hidden");

                    var element = document.getElementById("deskripsi_" + i);
                    element.classList.add("hidden");
                } else {
                    var element = document.getElementById("button_detail_" + i);
                    element.classList.add("hidden");

                    var element = document.getElementById("deskripsi_" + i);
                    element.classList.remove("hidden");
                }
            },
            getDocumentCreation() {
                this.$http.get('/api/auth/getDocumentCreation').then((response) => {
                    if (response.data.data.dataDoc) {
                        this.dataDoc = response.data.data.dataDoc
                        this.dataDocBack = response.data.data.dataDoc
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            deleteDoc(document_id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Anda yakin akan menghapus item ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Yakin',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$http.post('/api/auth/deleteDataDoc', {
                            document_id: document_id
                        }).then((response) => {
                            this.$swal('Deleted!', '', 'File anda berhasil di hapus')
                            setInterval(() => {
                                location.reload()
                            }, 1000)
                        }).catch((err) => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                            })
                        })
                    }
                })
            },
            searchDoc() {
                var dataDoc = this.dataDoc
                var keywordSearch = this.keywordSearch
                var lengtKeyword = keywordSearch.length

                let dataDocFilter = dataDoc.filter(function (el) {
                    return el.document_name.includes(keywordSearch)
                });

                if (lengtKeyword == 0) {
                    this.dataDoc = this.dataDocBack
                } else {
                    this.dataDoc = dataDocFilter
                }
            }
        }
    }
</script>