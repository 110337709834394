<template>
    <div>
        <nav class="flex px-2" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li class="inline-flex items-center dark:hover:bg-gray-300">
                    <a href="#"
                        class="inline-flex items-center text-sm font-medium decoration-gray-950 hover:text-blue-600 dark:decoration-gray-950">
                        <svg class="w-3 h-3 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor" viewBox="0 0 20 20">
                            <path
                                d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                        </svg>
                        {{ dataIndexFolderParent.folder_name }}
                    </a>
                </li>
                <li v-for="(row, i) in dataIndexFolder" :key="i">
                    <div class="flex items-center dark:hover:bg-gray-300">
                        <svg class="w-3 h-3 decoration-gray-950 mx-1" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m1 9 4-4-4-4" />
                        </svg>
                        <a href="#"
                            class="ml-1 text-sm font-medium decoration-gray-950 hover:text-blue-600 md:ml-2 dark:decoration-gray-950">{{ row.folder_name }}</a>
                    </div>
                </li>
            </ol>
        </nav>
    </div>
</template>

<script>
    export default {
        props: {
            dataIndexFolderParent: Object,
            dataIndexFolder: Array
        }
    }
</script>