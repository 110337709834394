<template>
    <div class="p-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <h6 class="text-md text-left font-bold tracking-tight text-gray-900 dark:text-white">Document Uploaded</h6>
        <hr class="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700">

        <div class="relative overflow-x-auto overflow-y-scroll sm:rounded-lg h-96">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            Nama Dokumen
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Nama User
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Uploaded
                        </th>
                    </tr>
                </thead>
                <tbody v-if="dataDocRecentlyUpload.length != 0">
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        v-for="(row, i) in dataDocRecentlyUpload" :key="i" @dblclick="toPage('open-document', row.id)">
                        <th scope="row" style="max-width: 200px;" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <p class="text-xs truncate" @mouseover="showPopover(i)"
                                @mouseleave="closePopover(i)">{{ row.document_name }}</p>
                            <p class="text-xs absolute rounded bg-gray-900 px-1 hidden text-white mt-5 ml-8"
                                tabindex="1" :id="['popover_' + i]">{{row.document_name}}</p>
                        </th>
                        <td class="px-6 py-4">
                            <span class="text-xs">{{ row.user_name }}</span>
                        </td>
                        <td class="px-6 py-4">
                            <span class="text-xs">{{ formatDate(row.created_at) }}</span>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td class="text-center" colspan="3">
                            No Data Showing
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataDocRecentlyUpload: []
            }
        },
        created() {
            this.getDataDocRecentlyUpload()
        },
        methods: {
            getDataDocRecentlyUpload() {
                this.$http.get('/api/auth/getDataDocumentRecentlyUpload').then((response) => {
                    if (response.data.data.dataDocRecentlyUpload) {
                        this.dataDocRecentlyUpload = response.data.data.dataDocRecentlyUpload
                    }
                }).catch((err) => {
                    // this.$swal.fire({
                    //     icon: 'error',
                    //     title: 'Oops...',
                    //     text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    // })
                })
            },
            formatDate(dateTime) {
                var date = new Date(dateTime).toLocaleString('ind', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    weekday: 'long'
                })
                var time = new Date(dateTime).toLocaleTimeString()
                var date_time = date + " " + time
                return date_time
            },
            showPopover(i) {
                var element = document.getElementById("popover_" + i);
                element.classList.remove("hidden");
            },
            closePopover(i) {
                var element = document.getElementById("popover_" + i);
                element.classList.add("hidden");
            },
            toPage(router_name, parameter) {
                this.$router.push({
                    name: router_name,
                    params: {
                        document_id: parameter
                    }
                });
            },
        }
    }
</script>