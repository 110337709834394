<template>
    <!-- TABS -->
    <div class="border-b border-gray-200 dark:border-gray-700">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li class="mr-2">
                <button @click="getDataDetail()" :class="[tabs.tabsDetailActive ? tabActive : tabNonActive]">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="w-4 h-4 mr-2">
                        <path fill-rule="evenodd"
                            d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75H12a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                            clip-rule="evenodd" />
                    </svg>
                    Detail
                </button>
            </li>
            <li class="mr-2">
                <button @click="getDataAktivitas()" :class="[tabs.tabsAktivitasActive ? tabActive : tabNonActive]"
                    aria-current="page">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="w-4 h-4 mr-2">
                        <path fill-rule="evenodd"
                            d="M2.25 13.5a8.25 8.25 0 018.25-8.25.75.75 0 01.75.75v6.75H18a.75.75 0 01.75.75 8.25 8.25 0 01-16.5 0z"
                            clip-rule="evenodd" />
                        <path fill-rule="evenodd"
                            d="M12.75 3a.75.75 0 01.75-.75 8.25 8.25 0 018.25 8.25.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75V3z"
                            clip-rule="evenodd" />
                    </svg>
                    Aktivitas
                </button>
            </li>
            <li class="mr-2">
                <button @click="getDataAccess()" :class="[tabs.tabsAccessActive ? tabActive : tabNonActive]"
                    aria-current="page">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="w-4 h-4 mr-2">
                        <path fill-rule="evenodd"
                            d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5zm6.61 10.936a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clip-rule="evenodd" />
                        <path
                            d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                    </svg>

                    Access
                </button>
            </li>
        </ul>
    </div>

    <div class="items-start justify-between p-10 overflow-y-auto" style="height: 450px" v-if="tabs.tabsDetailActive">
        <ol class="relative text-gray-500 border-l border-gray-200 dark:border-gray-700 dark:text-gray-400"
            v-for="(row, i) in dataDetail" :key="i">
            <li class="mb-10 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                        <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
                        <path fill-rule="evenodd"
                            d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
                            clip-rule="evenodd" />
                    </svg>

                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Nama File</h3>
                <p class="text-xs text-left">{{row.document_name}}</p>
            </li>
            <li class="mb-10 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                        <path
                            d="M18.75 12.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM12 6a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 6zM12 18a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 18zM3.75 6.75h1.5a.75.75 0 100-1.5h-1.5a.75.75 0 000 1.5zM5.25 18.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM3 12a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 12zM9 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM12.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM9 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                    </svg>

                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Jenis File</h3>
                <p class="text-xs text-left">{{row.document_extension}}</p>
            </li>
            <li class="mb-10 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                    </svg>

                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Ukuran</h3>
                <p class="text-xs text-left">{{checkSize(row.document_size)}}</p>
            </li>
            <li class="mb-10 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                        <path fill-rule="evenodd"
                            d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zM12.75 12a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V18a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V12z"
                            clip-rule="evenodd" />
                        <path
                            d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
                    </svg>

                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Di Buat</h3>
                <p class="text-xs text-left">{{formatDate(row.created_at)}}</p>
            </li>
            <li class="mb-10 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                        <path
                            d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                        <path
                            d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                    </svg>

                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Di Modifikasi</h3>
                <p class="text-xs text-left">{{formatDate(row.updated_at)}}</p>
            </li>
            <li class="mb-10 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                        <path
                            d="M19.906 9c.382 0 .749.057 1.094.162V9a3 3 0 00-3-3h-3.879a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H6a3 3 0 00-3 3v3.162A3.756 3.756 0 014.094 9h15.812zM4.094 10.5a2.25 2.25 0 00-2.227 2.568l.857 6A2.25 2.25 0 004.951 21H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-2.227-2.568H4.094z" />
                    </svg>

                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Lokasi Folder</h3>
                <p class="text-xs text-left">{{row.folder}}</p>
            </li>
            <li class="mb-10 ml-6">
                <span
                    class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                        <path fill-rule="evenodd"
                            d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                            clip-rule="evenodd" />
                    </svg>

                </span>
                <h3 class="font-medium leading-tight text-left text-dark">Lokasi Dokumen</h3>
                <p class="text-xs text-left">{{row.document_location}}</p>
            </li>
        </ol>
    </div>

    <div class="items-start justify-between p-10 overflow-y-auto" style="height: 420px" v-if="tabs.tabsAktivitasActive">

        <ul class="max-w-md divide-y divide-gray-200 dark:divide-gray-700">
            <li class="py-3 sm:py-4" v-for="(row, i) in dataActivity" :key="i">
                <div class="flex items-left space-x-4">
                    <div class="flex-shrink-0">
                        <img class="w-9 h-9 rounded-full" :src="[row.foto_profil]" alt="Neil image"
                            onerror="this.onerror=null;this.src='https://is3.cloudhost.id/legalrsii/images/account_image.png';">
                    </div>
                    <div class="flex-1 min-w-0">
                        <p class="text-sm font-medium text-left text-gray-900 truncate dark:text-white">
                            {{row.nama_lengkap}} {{row.jenis_aktivitas}}
                        </p>
                        <p class="text-sm text-gray-500 text-left truncate dark:text-gray-400">
                            {{formatDate(row.created_at)}}
                        </p>
                    </div>
                </div>
            </li>
        </ul>

    </div>

    <div class="items-start justify-between p-10 overflow-y-auto" style="height: 450px" v-if="tabs.tabsAccessActive">
        <div class="grid grid-cols-2 gap-4">
            <div>
                <p class="font-bold">Shared</p>
                <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700">
                <ul class="max-w-md divide-y divide-gray-200 dark:divide-gray-700">
                    <li class="pb-3 sm:pb-4" v-for="(row, i) in dataAccess.documentPermission" :key="i">
                        <div class="flex items-left space-x-4">
                            <div class="flex-shrink-0">
                                <img class="w-8 h-8 rounded-full" :src="row.foto_profil" alt="Neil image"
                                    onerror="this.onerror=null;this.src='https://is3.cloudhost.id/legalrsii/images/account_image.png';">
                            </div>
                            <div class="flex-1 min-w-0">
                                <p class="text-sm text-left font-medium text-gray-900 truncate dark:text-white">
                                    {{row.nama_lengkap}}
                                </p>
                                <p class="text-sm text-left text-gray-500 truncate dark:text-gray-400">
                                    {{row.email}}
                                </p>
                            </div>
                            <div class="inline-flex items-center text-gray-900 dark:text-white">
                                <span
                                    class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{{row.role_doc}}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div>
                <p class="font-bold">Approvement</p>
                <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700">
                <ul class="max-w-md divide-y divide-gray-200 dark:divide-gray-700">
                    <li class="pb-3 sm:pb-4" v-for="(row, i) in dataAccess.documentAssigned" :key="i">
                        <div class="flex items-left space-x-4">
                            <div class="flex-shrink-0">
                                <img class="w-8 h-8 rounded-full" :src="row.foto_profil" alt="Neil image"
                                    onerror="this.onerror=null;this.src='https://is3.cloudhost.id/legalrsii/images/account_image.png';">
                            </div>
                            <div class="flex-1 min-w-0">
                                <p class="text-sm text-left font-medium text-gray-900 truncate dark:text-white">
                                    {{row.nama_lengkap}}
                                </p>
                                <p class="text-sm text-left text-gray-500 truncate dark:text-gray-400">
                                    {{row.email}}
                                </p>
                            </div>
                            <div class="inline-flex items-center text-gray-900 dark:text-white">
                                <span
                                    class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{{row.status_assigned}}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            viewModalDetail: Boolean,
            document_id: Number
        },
        data() {
            return {
                tabActive: 'inline-flex items-center justify-center p-4 text-blue-500 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group',
                tabNonActive: 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-700 hover:border-gray-300 dark:hover:text-gray-300 group',
                tabs: {
                    tabsDetailActive: false,
                    tabsAktivitasActive: false,
                    tabsAccessActive: false
                },
                dataDetail: [],
                dataActivity: [],
                dataAccess: [],
                namaDocument: ''
            }
        },
        emits: ["closeModal"],
        created() {
            this.getDataDetail()
        },
        methods: {
            checkSize(sizebyte) {
                if (sizebyte <= 999999) {
                    var size = sizebyte / 1000;
                    var satuan = 'KB';
                } else {
                    var size = sizebyte / 1000000;
                    var satuan = 'MB';
                }

                return size.toFixed(2) + ' ' + satuan;
            },
            getDataDetail() {
                this.tabs.tabsDetailActive = true
                this.tabs.tabsAktivitasActive = false
                this.tabs.tabsAccessActive = false

                this.$http.post('/api/auth/getDocumentDetail', {
                    document_id: this.document_id
                }).then((response) => {
                    this.dataDetail = response.data.data.data_detail
                    this.namaDocument = response.data.data.data_detail[0].document_name
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            getDataAktivitas() {
                this.tabs.tabsDetailActive = false
                this.tabs.tabsAktivitasActive = true
                this.tabs.tabsAccessActive = false

                this.$http.post('/api/auth/getDocumentActivity', {
                    document_id: this.document_id
                }).then((response) => {
                    this.dataActivity = response.data.data.data_activity
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            getDataAccess() {
                this.tabs.tabsDetailActive = false
                this.tabs.tabsAktivitasActive = false
                this.tabs.tabsAccessActive = true

                this.$http.post('/api/auth/getDataAccessDoc', {
                    document_id: this.document_id
                }).then((response) => {
                    this.dataAccess = response.data.data
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            formatDate(dateTime) {
                var date = new Date(dateTime).toLocaleString('ind', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    weekday: 'long'
                })
                var time = new Date(dateTime).toLocaleTimeString()
                var date_time = date + " " + time
                return date_time
            },
            toTitleCase(str) {
                return str.replace(
                    /\w\S*/g,
                    function (txt) {
                        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    }
                );
            }
        }
    }
</script>