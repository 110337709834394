<template>
    <div class="h-full">
        <div class="grid grid-cols-1 gap-4 mb-4" v-if="dataDocument.length != 0">
            <div class="col-span-3" v-for="(row, i) in dataDocument" :key="i">
                <h4 class="mb-2">{{row.document_name}}</h4>
                <div v-if="checkExtension(row.document_extension) == true">
                    <object :data="row.document_file" type="application/pdf" v-if="row.document_extension == 'PDF'" width="100%" height="600"></object>
                    <iframe v-if="row.document_extension != 'PDF'" type="application/pdf" id="document_viewer"
                        :src="checkDocExt(row.document_extension, row.document_file)" width="100%" height="600"
                        frameborder='0' class="rounded">
                        <p>This browser does not support PDF!</p>
                    </iframe>
                </div>
                <div v-else>
                    <div class="images" v-viewer="{navbar: false, toolbar: false}">
                        <img :key="row.document_file" :src="row.document_file" class="rounded h-auto max-w-lg mx-auto">
                    </div>
                    <!-- <img :src="row.document_file" class="rounded h-auto max-w-lg mx-auto"> -->
                </div>
            </div>
        </div>
        <div v-if="judul_meeting" class="grid grid-cols-1 gap-4 mb-4">
            <div>
                <h4 class="mb-2">Notlensi {{judul_meeting}}</h4>
                <div v-if="checkExtension(file_ext) == true">
                    <object :data="file_name" type="application/pdf" v-if="file_ext == 'PDF'" width="100%" height="600"></object>
                    <iframe v-if="file_ext != 'PDF'" type="application/pdf" id="document_viewer"
                        :src="checkDocExt(file_ext, file_name)" width="100%" height="600"
                        frameborder='0' class="rounded">
                        <p>This browser does not support PDF!</p>
                    </iframe>
                </div>
                <div v-else>
                    <div class="images" v-viewer="{navbar: false, toolbar: false}">
                        <img :key="file_name" :src="file_name" class="rounded h-auto max-w-lg mx-auto">
                    </div>
                    <!-- <img :src="row.document_file" class="rounded h-auto max-w-lg mx-auto"> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'viewerjs/dist/viewer.css'
    import {
        directive as viewer
    } from "v-viewer"
    export default {
        directives: {
            viewer: viewer({
                debug: true
            })
        },
        data() {
            return {
                document_id: this.$route.params.document_id,
                dataDocument: [],
                file_name: this.$route.query.file_name,
                file_ext: this.$route.query.file_ext,
                judul_meeting: this.$route.query.name
            }
        },
        created() {
            this.getDataFile()
        },
        methods: {
            getDataFile() {
                this.$http.post('/api/auth/getDataFileUrl', {
                    document_id: this.document_id
                }).then((response) => {
                    if (response.data.data) {
                        this.dataDocument = response.data.data.dataDoc
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            checkExtension(ext) {
                var arrDoc = ['PDF', 'DOC', 'DOCX', 'XLSX', 'PPT', 'PPTX', 'XLS']
                var check = arrDoc.includes(ext)
                return check
            },
            checkDocExt(ext, urlFile) {
                var urlOffice = 'https://view.officeapps.live.com/op/embed.aspx?src='
                var urlGoogle = 'https://docs.google.com/viewer?embedded=true&url='
                if (ext == 'PDF') {
                    var url = urlGoogle + urlFile
                } else {
                    var url = urlOffice + urlFile
                }

                return url
            }
        }
    }
</script>