<template>
  <div id="app" class="h-full w-full">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
  import Navbar from './components/navbar/Navbar.vue';
  import HomeView from './views/HomeView.vue';
  import {
    Popover
  } from 'flowbite';
  import {
    initFlowbite
  } from 'flowbite'
  import SideNav from './components/navbar/SideNav.vue';
  import SideNavPage from './components/navbar/SideNavPage.vue';
  export default {
    components: {
      Navbar,
      HomeView,
      SideNav,
      SideNavPage
    },
    onMounted() {
      initFlowbite();
      // // set the popover content element
      // const $targetEl = document.getElementById('popoverContent');

      // // set the element that trigger the popover using hover or click
      // const $triggerEl = document.getElementById('popoverButton');

      // // options with default values
      // const options = {
      //   placement: 'bottom',
      //   triggerType: 'hover',
      //   offset: 10
      // };

      // const popover = new Popover($targetEl, $triggerEl, options);
    },
    computed: {
      layout() {
        if (this.$route.meta.layout === 'home-view') return 'home-view'
        else if (this.$route.meta.layout === 'login-page') return 'login-page'
        else if (this.$route.meta.layout === 'sso-authentication') return 'sso-authentication'
        else return 'side-nav-page'
      },
    },
  }
</script>
<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  nav {
    padding: 5px;
  }

  nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  nav a.router-link-exact-active {
    color: #42b983;
  }
</style>