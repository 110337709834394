import axios from 'axios'

const axiosInstance = axios.create({
  headers: {
    token: localStorage.getItem("storageTokenKeyName"),
    headers: {
      "Access-Control-Allow-Origin": "*",
      'content-type': 'application/json'
    }
  },
  withCredentials:true,
  baseURL: 'https://legal.rsii.id/'
  // baseURL: 'http://127.0.0.1:7000/'
})

export default axiosInstance