<template>
    <div class="h-full">
        <div class="flex items-center justify-left px-2">
            <span class="text-md font-bold mb-2">Laporan Regulator</span>
        </div>
        <div class="grid grid-cols-4 gap-2">
            <div class="col-span-3">
                <component :is="componentName"></component>
            </div>
            <div class="">
                <div
                    class="block max-w-sm bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <div class="p-1 rounded-lg dark:border-gray-700">
                        <div v-if="dataFolder.length != 0" class="grid grid-cols-1 gap-1 mb-2">
                            <div v-for="(row, i) in dataFolder" :key="i">
                                <button @click="toPage('laporan-regulator', toLowerCase(row.folder_name))"
                                    class="flex items-center justify-left h-12 rounded bg-gray-300 dark:bg-gray-300 hover:bg-gray-400 px-5 py-1 w-full">
                                    <p class="text-gray-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                            class="w-5 h-5">
                                            <path
                                                d="M19.5 21a3 3 0 003-3v-4.5a3 3 0 00-3-3h-15a3 3 0 00-3 3V18a3 3 0 003 3h15zM1.5 10.146V6a3 3 0 013-3h5.379a2.25 2.25 0 011.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 013 3v1.146A4.483 4.483 0 0019.5 9h-15a4.483 4.483 0 00-3 1.146z" />
                                        </svg>

                                    </p>
                                    <span class="ml-2 text-sm truncate">{{ row.folder_name }}</span>
                                </button>
                                <div class="ml-8 grid grid-cols-1 gap-4 mt-2" :id="i">
                                    <!-- <button
                                            class="flex items-center justify-left h-10 rounded bg-gray-300 dark:bg-gray-300 hover:bg-gray-400 px-5 py-0 w-full">
                                            <p class="text-gray-600">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                    fill="currentColor" class="w-5 h-5">
                                                    <path
                                                        d="M19.5 21a3 3 0 003-3v-4.5a3 3 0 00-3-3h-15a3 3 0 00-3 3V18a3 3 0 003 3h15zM1.5 10.146V6a3 3 0 013-3h5.379a2.25 2.25 0 011.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 013 3v1.146A4.483 4.483 0 0019.5 9h-15a4.483 4.483 0 00-3 1.146z" />
                                                </svg>

                                            </p>
                                            <span class="ml-2 text-sm truncate">PT Regarsport Industri Indonesia</span>
                                        </button> -->
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-center">No Data Showing</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Navbar from "@/components/navbar/Navbar.vue"
    import ExpirationDocument from './components/ExpirationDocument.vue'
    import PembayaranPbb from './components/PembayaranPbb.vue'
    import LaporanLkpm from './components/LaporanLkpm.vue'

    export default {
        components: {
            Navbar,
            ExpirationDocument,
            PembayaranPbb,
            LaporanLkpm
        },
        data() {
            return {
                dataFolder: [],
                route_parameter: null,
                componentName: 'expiration-document'
            }
        },
        created() {
            this.getDataFolderRegulator()
        },
        watch: {
            $route(to, from) {
                this.route_parameter = to.query.page
                this.componentName = to.query.page
            }
        },
        methods: {
            getDataFolderRegulator() {
                this.$http.get('/api/auth/getDataFolderRegulator').then((response) => {
                    if (response.data.data.data_folder) {
                        this.dataFolder = response.data.data.data_folder
                    }
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            toPage(router_name, parameter) {
                this.$router.push({
                    name: router_name,
                    query: {
                        page: parameter
                    }
                });
            },
            toLowerCase(string) {
                let str = string.toLowerCase();
                let str_replace = str.replace(" ", "-");
                return str_replace
            }
        }
    }
</script>