<template>
    <nav class="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div class="px-3 py-3 lg:px-5 lg:pl-3">
            <div class="flex items-center justify-between">
                <div class="flex items-center justify-start rtl:justify-end">
                    <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar"
                        aria-controls="logo-sidebar" type="button"
                        class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                        <span class="sr-only">Open sidebar</span>
                        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path clip-rule="evenodd" fill-rule="evenodd"
                                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
                            </path>
                        </svg>
                    </button>
                    <a href="#" class="flex items-center">
                        <img src="https://regarsport.net/landing_page/img/logo_rs.png" class="mr-3 h-6 sm:h-9"
                            alt="Legal Regarsport Logo" />
                        <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Regarsport
                            Legal</span>
                    </a>
                </div>
                <div class="flex items-center">
                    <div class="flex items-center ms-3">
                        <div>
                            <button type="button" @click="showDropdownNotifikasi()"
                                class="relative inline-flex items-center p-3 text-sm font-medium text-center text-dark"
                                id="button-notifikasi">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                                </svg>
                                <div
                                    class="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-0 -right-0 dark:border-gray-900">
                                    {{dataNotifikasi.length}}
                                </div>
                            </button>
                        </div>
                        <div data-popover id="notifikasi-form" role="tooltip"
                            class="hidden absolute inline-block w-72 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                            <div
                                class="px-3 py-2 bg-blue-600 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                                <h3 class="font-semibold text-white dark:text-white text-left">Notification</h3>
                            </div>
                            <div class="px-3 py-2">
                                <button type="button" class="text-xs mb-2 text-grey text-right"
                                    @click="clearAllNotification()">Clear all</button>
                                <ul class="max-w-md dark:divide-gray-700 overflow-y-auto max-h-96">
                                    <li class="py-3 sm:py-4 hover:bg-gray-200 px-2 rounded-lg"
                                        v-for="(row, i) in dataNotifikasi" :key="i">
                                        <div class="flex items-center space-x-4">
                                            <div class="flex-shrink-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                    stroke-width="1.5" stroke="currentColor"
                                                    class="w-5 h-5 text-green-500" v-if="row.notification_type_id == 1">
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                </svg>
                                                <svg @click="toPage('meeting')" class="w-5 h-5 text-green-500" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    fill="none" viewBox="0 0 24 24" v-if="row.notification_type_id == 2">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                                        d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3.05A2.5 2.5 0 1 1 9 5.5M19.5 17h.5a1 1 0 0 0 1-1 3 3 0 0 0-3-3h-1m0-3.05a2.5 2.5 0 1 0-2-4.45m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3 1 1 0 0 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                                                </svg>
                                            </div>
                                            <div class="flex-1 min-w-0">
                                                <p
                                                    class="text-xs font-medium text-gray-900 truncate dark:text-white text-left">
                                                    {{row.document_name}}
                                                </p>
                                                <p class="text-xs text-gray-500 dark:text-gray-400 text-left">
                                                    {{row.keterangan}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-3 gap-1 mt-2" v-if="row.is_need_approve == 2">
                                            <button type="button" @click="approveDoc(row.document_id, row.id)"
                                                class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Approve</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div data-popper-arrow class="bg-blue-600"></div>
                        </div>
                        <div @click="showDropdownUser()">
                            <button type="button" @click="showDropdownUser()"
                                class="flex ml-3 mr-3 text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                aria-expanded="false" id="user-menu-button" data-dropdown-toggle="user-dropdown">
                                <span class="sr-only">Open user menu</span>
                                <img class="w-8 h-8 rounded-full" :src="dataUser.foto_profil" alt="user photo">
                            </button>
                        </div>
                        <div class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                            id="user-dropdown">
                            <div class="px-4 py-3" role="none">
                                <p class="text-sm text-gray-900 dark:text-white" role="none">
                                    {{dataUser.nama_lengkap}}
                                </p>
                                <p class="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">
                                    {{dataUser.email}}
                                </p>
                            </div>
                            <ul class="py-1" role="none">
                                <li>
                                    <a @click="logOut()"
                                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                                        role="menuitem">Sign out</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>

    <aside id="logo-sidebar"
        class="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Sidebar">
        <div class="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
            <ul class="space-y-2 font-medium">
                <li>
                    <button @click="toPage('dashboard')"
                        class="flex items-center p-2 text-gray-900 hover:text-white text-sm rounded-lg dark:text-white hover:bg-blue-700 dark:hover:bg-gray-700 group">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-5 h-5 text-red-500">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                        </svg>

                        <span class="ml-3 whitespace-nowrap">Dashboard</span>
                    </button>
                </li>
                <li>
                    <button type="button"
                        class="flex items-center w-full p-2 text-sm text-gray-900 hover:text-white transition duration-75 rounded-lg group hover:bg-blue-700 dark:text-white dark:hover:bg-gray-700"
                        aria-controls="dropdown-repo" data-collapse-toggle="dropdown-repo">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-5 h-5 text-green-500">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                        </svg>

                        <span class="flex-1 ml-3 text-left whitespace-nowrap">Repository</span>
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m1 1 4 4 4-4" />
                        </svg>
                    </button>
                    <ul id="dropdown-repo" class="py-2 space-y-2">
                        <li>
                            <button @click="toPage('document-folder-page')"
                                class="flex items-center w-full p-2 text-gray-900 hover:text-white transition duration-75 rounded-lg pl-11 group hover:bg-blue-700 dark:text-white dark:hover:bg-gray-700"><svg
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-blue-500">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>

                                <span class="flex-1 ml-3 text-xs text-left whitespace-nowrap">Dokumen Saya</span>
                            </button>
                        </li>
                        <li>
                            <button @click="toPage('share-with-me')"
                                class="flex items-center w-full p-2 text-gray-900 hover:text-white transition duration-75 rounded-lg pl-11 group hover:bg-blue-700 dark:text-white dark:hover:bg-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-orange-500">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                                </svg>

                                <span class="flex-1 ml-3 text-xs text-left whitespace-nowrap">Dibagikan Kepada
                                    Saya</span>
                            </button>
                        </li>
                        <li>
                            <button @click="toPage('document-creation-page')"
                                class="flex items-center w-full p-2 text-gray-900 hover:text-white transition duration-75 rounded-lg pl-11 group hover:bg-blue-700 dark:text-white dark:hover:bg-gray-700"><svg
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-yellow-500">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>

                                <span class="flex-1 ml-3 text-xs text-left whitespace-nowrap">Pembuatan
                                    Dokumen</span>
                            </button>
                        </li>
                        <li>
                            <button @click="toPage('laporan-regulator', 'expiration-document')"
                                class="flex items-center w-full p-2 text-gray-900 hover:text-white transition duration-75 rounded-lg pl-11 group hover:bg-blue-700 dark:text-white dark:hover:bg-gray-700"><svg
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-lime-500">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
                                </svg>
                                <span class="flex-1 ml-3 text-xs text-left whitespace-nowrap">Laporan
                                    Regulator</span>
                            </button>
                        </li>
                        <li>
                            <button @click="toPage('meeting')"
                                class="flex items-center w-full p-2 text-gray-900 hover:text-white transition duration-75 rounded-lg pl-11 group hover:bg-blue-700 dark:text-white dark:hover:bg-gray-700">
                                <svg class="w-4 h-4 text-cyan-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                    width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                        d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3.05A2.5 2.5 0 1 1 9 5.5M19.5 17h.5a1 1 0 0 0 1-1 3 3 0 0 0-3-3h-1m0-3.05a2.5 2.5 0 1 0-2-4.45m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3 1 1 0 0 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                                </svg>
                                <span class="flex-1 ml-3 text-xs text-left whitespace-nowrap">Meeting</span>
                            </button>
                        </li>
                        <li class="">
                            <button @click="toPage('aset-perusahaan')"
                                class="flex items-center w-full p-2 text-gray-900 hover:text-white transition duration-75 rounded-lg pl-11 group hover:bg-blue-700 dark:text-white dark:hover:bg-gray-700">
                                <svg class="w-4 h-4 text-indigo-500" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                    viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M9 16H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1M9 12H4m8 8V9h8v11h-8Zm0 0H9m8-4a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z" />
                                </svg>
                                <span class="flex-1 ml-3 text-xs text-left whitespace-nowrap">Aset Perusahaan</span>
                            </button>
                        </li>
                        <li class="hidden">
                            <a href="#"
                                class="flex items-center w-full p-2 text-gray-900 hover:text-white transition duration-75 rounded-lg pl-11 group hover:bg-blue-700 dark:text-white dark:hover:bg-gray-700"><svg
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                                </svg>

                                <span class="flex-1 ml-3 text-xs text-left whitespace-nowrap">Penyelesaian Kasus
                                    Hukum</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#"
                        class="hidden flex items-center p-2 text-gray-900 hover:text-white text-sm rounded-lg dark:text-white hover:bg-blue-700 dark:hover:bg-gray-700 group">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                        <span class="ml-3 whitespace-nowrap">Recycle Bin</span>
                    </a>
                </li>
                <li>
                    <a href="#"
                        class="hidden flex items-center p-2 text-gray-900 hover:text-white text-sm rounded-lg dark:text-white hover:bg-blue-700 dark:hover:bg-gray-700 group">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <span class="ml-3 whitespace-nowrap">Account</span>
                    </a>
                </li>
                <li class="hidden">
                    <a href="#"
                        class="flex items-center p-2 text-gray-900 hover:text-white text-sm rounded-lg dark:text-white hover:bg-blue-700 dark:hover:bg-gray-700 group">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z" />
                        </svg>
                        <span class="ml-3 whitespace-nowrap">Penyimpanan (45 %)</span>
                    </a>
                    <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div class="bg-blue-600 h-2.5 rounded-full" style="width: 45%"></div>
                    </div>
                </li>
            </ul>
        </div>
    </aside>

    <div class="p-4 sm:ml-64">
        <div class="p-4 rounded-lg mt-14">
            <router-view />
        </div>
    </div>

</template>

<script>
    import {
        Dropdown,
        Popover
    } from 'flowbite';
    export default {
        data() {
            return {
                dataUser: [],
                dataNotifikasi: []
            }
        },
        created() {
            this.getDetailUsers();
            this.getNotification();
        },
        methods: {
            toPage(router_name, parameter) {
                if (router_name == 'document-folder-page' || router_name == 'share-with-me') {
                    this.$router.push({
                        name: router_name,
                        params: {
                            parent_id: 0,
                            index_number: 1
                        }
                    });
                } else {
                    this.$router.push({
                        name: router_name
                    });
                }

                if (parameter) {
                    this.$router.push({
                        name: router_name,
                        query: {
                            page: parameter
                        }
                    });
                }
            },
            getDetailUsers() {
                var token = localStorage.getItem("storageTokenKeyName");
                if (token) {
                    this.$http.get('/api/auth/getDataUserDetails').then((response) => {
                        this.dataUser = response.data.data
                        localStorage.setItem("foto_profil", this.dataUser.foto_profil)
                        localStorage.setItem("id", this.dataUser.id)
                        localStorage.setItem("nama", this.dataUser.nama_lengkap)
                    }).catch((err) => {
                        // this.$swal.fire({
                        //     icon: 'error',
                        //     title: 'Oops...',
                        //     text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                        // })
                    })
                }
            },
            logOut() {
                this.$http.post('/api/auth/logout').then((response) => {
                    localStorage.removeItem("storageTokenKeyName");
                    location.reload()
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            showDropdownUser() {
                // set the dropdown menu element
                const $targetEl = document.getElementById('user-dropdown');

                // set the element that trigger the dropdown menu on click
                const $triggerEl = document.getElementById('user-menu-button');

                // options with default values
                const options = {
                    placement: 'bottom',
                    triggerType: 'click',
                    offsetSkidding: 0,
                    offsetDistance: 10,
                    delay: 300,
                    ignoreClickOutsideClass: false,
                    onHide: () => {
                        // console.log('dropdown has been hidden');
                    },
                    onShow: () => {
                        // console.log('dropdown has been shown');
                    },
                    onToggle: () => {
                        // console.log('dropdown has been toggled');
                    },
                };

                // instance options object
                const instanceOptions = {
                    id: 'user-dropdown',
                    override: true
                };
                const dropdown = new Dropdown($targetEl, $triggerEl, options, instanceOptions);
            },
            showDropdownNotifikasi() {
                // set the tooltip content element
                const $targetEl = document.getElementById('notifikasi-form');

                // set the element that trigger the tooltip using hover or click
                const $triggerEl = document.getElementById('button-notifikasi');

                // options with default values
                const options = {
                    placement: 'bottom',
                    triggerType: 'click',
                    onHide: () => {
                        // console.log('tooltip is shown');
                    },
                    onShow: () => {
                        $targetEl.classList.remove("hidden");
                        // console.log('tooltip is hidden');
                    },
                    onToggle: () => {
                        // console.log('tooltip is toggled');
                    },
                };


                // instance options with default values
                const instanceOptions = {
                    id: 'notifikasi-form',
                    override: true
                };
                const popover = new Popover($targetEl, $triggerEl, options, instanceOptions);
            },
            getNotification() {
                this.$http.get('/api/auth/getDataNotification').then((response) => {
                    this.dataNotifikasi = response.data.data.data
                }).catch((err) => {
                    // this.$swal.fire({
                    //     icon: 'error',
                    //     title: 'Oops...',
                    //     text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    // })
                })
            },
            clearAllNotification() {
                this.$http.post('/api/auth/clearNotifikasi').then((response) => {
                    this.dataNotifikasi = []
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            approveDoc(document_id, notif_id) {
                this.$http.post('/api/auth/updateApproveDocApi', {
                    document_id: document_id,
                    notif_id: notif_id
                }).then((response) => {
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Berhasil',
                        text: 'Dokumen berhasil di approve!',
                    })
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            }
        }
    }
</script>