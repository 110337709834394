<template>
    <div class="h-full">
        <div class="p-2 rounded-lg dark:border-gray-700">
            <div class="grid grid-cols-2 gap-4 mb-4">
                <document-expiration></document-expiration>
                <document-status></document-status>
                <document-recently-upload></document-recently-upload>
                <document-statistik></document-statistik>
            </div>
        </div>
    </div>
</template>

<script>
    import DocumentExpiration from './components/DocumentExpiration.vue'
    import DocumentRecentlyUpload from './components/DocumentRecentlyUpload.vue'
    import DocumentStatistik from './components/DocumentStatistik.vue'
    import DocumentStatus from './components/DocumentStatus.vue'
    export default {
        components: {
            DocumentExpiration,
            DocumentStatus,
            DocumentRecentlyUpload,
            DocumentStatistik
        },
        setup() {

        },
    }
</script>