<template>
  <div class="container h-screen w-full py-8 flex justify-center">
    <div class="h-full w-full rounded-md drop-shadow-lg bg-cyan-600 flex">
      <div>
        <img src="https://regarsport.net/landing_page/img/55.png" alt="">
      </div>
      <div>
        <img src="https://regarsport.net/landing_page/img/55.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HelloWorld'
  }
</script>