<template>
    <div class="flex min-w-full">
        <h3 class="mx-auto">Coming Soon</h3>
    </div>
</template>

<script>
    export default {
        setup() {

        },
    }
</script>