<template>
    <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">Rename Folder</h3>
    <div class="space-y-6" action="#">
        <div>
            <label for="folder_name" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-white">Nama Folder</label>
            <input type="folder_name" name="folder_name" id="folder_name" v-model="document_name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Masukkan nama folder baru" required>
        </div>
        <button type="button" @click="saveRenameFolder"
            class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Simpan</button>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                document_name: null
            }
        },
        props: {
            document_id: Number
        },  
        methods: {
            saveRenameFolder() {
                if (this.document_name == "") {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'Mohon isi kolom nama file terlebih dahulu',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    this.$http.post('/api/auth/renameDataFolder', {
                        document_id: this.document_id,
                        document_name: this.document_name
                    }).then((response) => {
                        if (response.data.status == "Success") {
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Nama folder berhasil di simpan',
                                showConfirmButton: false,
                                timer: 1200
                            })
                            setInterval(location.reload(), 5000);
                        }
                    }).catch((err) => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                        })
                    })
                }
            },
        }
    }
</script>