<template>
    <div>
        <div
            class="max-w-sm p-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <a href="#">
                <div class="rounded-lg hover:border-solid hover:bg-gray-300 mb-2">
                    <div class="rounded-t-lg bg-teal-500 h-32 py-14">
                        <span class="text-white text-center font-semibold tracking-wide text-lg">Antrian</span>
                    </div>
                    <div class="rounded-b-lg bg-gray-200 p-3 text-left hover:bg-gray-300">
                        <span class="flex tracking-wide text-sm">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="w-4 h-4 me-2">
                                <path fill="#949494"
                                    d="M54.2 202.9C123.2 136.7 216.8 96 320 96s196.8 40.7 265.8 106.9c12.8 12.2 33 11.8 45.2-.9s11.8-33-.9-45.2C549.7 79.5 440.4 32 320 32S90.3 79.5 9.8 156.7C-2.9 169-3.3 189.2 8.9 202s32.5 13.2 45.2 .9zM320 256c56.8 0 108.6 21.1 148.2 56c13.3 11.7 33.5 10.4 45.2-2.8s10.4-33.5-2.8-45.2C459.8 219.2 393 192 320 192s-139.8 27.2-190.5 72c-13.3 11.7-14.5 31.9-2.8 45.2s31.9 14.5 45.2 2.8c39.5-34.9 91.3-56 148.2-56zm64 160a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z" />
                            </svg>
                            <p class="text-gray-400">{{totalOnline}}</p>
                        </span>
                        <span class="flex tracking-wide text-sm">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-4 h-4 me-2">
                                <path fill="#949494"
                                    d="M64 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16h80V400c0-26.5 21.5-48 48-48s48 21.5 48 48v64h80c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64zM0 64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm88 40c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V104zM232 88h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V104c0-8.8 7.2-16 16-16zM88 232c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V232zm144-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V232c0-8.8 7.2-16 16-16z" />
                            </svg>
                            <p class="text-gray-400">{{totalOffline}}</p>
                        </span>
                    </div>
                </div>
            </a>
            <div class="overflow-y-auto max-h-96">
                <div class="relative p-3 bg-gray-200 rounded-lg mb-2 text-left hover:bg-gray-300 mb-2"
                    v-for="(row, i) in dataMeeting" :key="i">
                    <div class="flex justify-items-start item-center text-gray-400 font-base tracking-wide text-sm">
                        {{row.nama_meeting}}

                        <svg class="w-4 h-4 text-gray-400 hover:text-gray-600 ml-1" aria-hidden="true"
                            @click="$emit('openModal', 'modal-edit-meeting', 'modal-flowbite', row.id)"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28" />
                        </svg>
                    </div>
                    <div class="flex justify-items-start item-center text-gray-500 font-sm tracking-wide text-xs mb-1">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" stroke-width="2"
                                d="M7 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h1m4-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7.441 1.559a1.907 1.907 0 0 1 0 2.698l-6.069 6.069L10 19l.674-3.372 6.07-6.07a1.907 1.907 0 0 1 2.697 0Z" />
                        </svg>
                        {{row.user_id == user_id ? 'Dibuat oleh Anda' : row.pembuat_meeting}}
                    </div>
                    <div class="flex justify-items-start item-center text-gray-400 font-base tracking-wide text-sm">
                        <span
                            class="bg-yellow-100 text-gray-400 text-xs font-medium inline-flex items-center px-2 py-0.5 rounded me-2 dark:bg-gray-700 dark:text-gray-400 border border-yellow-300 ">
                            <svg class="w-2.5 h-2.5 me-1.5 animate-pulse" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
                            </svg>
                            {{formatDate(row.tanggal_meeting)}}
                        </span>
                    </div>
                    <div class="absolute top-3 right-2">
                        <svg class="w-4 h-4 text-gray-400 hover:text-gray-600 mb-2" aria-hidden="true"
                            @click="$emit('openModal', 'modal-detail-meeting', 'modal-flowbite', row.id)"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4" />
                        </svg>
                        <svg class="w-4 h-4 text-gray-400 hover:text-gray-600 mb-2" aria-hidden="true"
                            @click="$emit('openModal', 'modal-bagikan-meeting', 'modal-flowbite', row.id)"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                d="M7.926 10.898 15 7.727m-7.074 5.39L15 16.29M8 12a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm12 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm0-11a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                        </svg>
                        <svg class="w-4 h-4 text-gray-400 hover:text-gray-600" aria-hidden="true"
                            @click="hapusMeeting(row.id)" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                        </svg>
                    </div>
                </div>
            </div>
            <a @click="$emit('openModal', 'modal-tambah-meeting', 'modal-flowbite')">
                <div class="p-1 rounded-lg mb-2 text-left hover:bg-gray-300 mb-2">
                    <span
                        class="text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400">
                        <svg class="w-3 h-3 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        Tambah Meeting
                    </span>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                user_id: localStorage.getItem('id'),
                dataMeeting: [],
                totalOnline: 0,
                totalOffline: 0
            }
        },
        created() {
            this.getDataMeeting()
        },
        methods: {
            getDataMeeting() {
                this.$http.get('/api/auth/meeting/data-meeting?status=1').then((response) => {
                    this.dataMeeting = response.data.data.data_meeting
                    this.totalOnline = response.data.data.total_online
                    this.totalOffline = response.data.data.total_offline
                }).catch((err) => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                    })
                })
            },
            formatDate(dateTime) {
                var replaceT = dateTime.replace("T", " ");
                var replaceZ = replaceT.replace("Z", "")

                var date = new Date(replaceZ).toLocaleString('ind', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    weekday: 'long'
                })
                var time = new Date(replaceZ).toLocaleTimeString('en-US', {
                    timeZone: 'Asia/Jakarta',
                    hour12: false
                })
                var date_time = date + " " + time
                return date_time
            },
            hapusMeeting(meeting_id) {
                this.$swal.fire({
                    title: "Hapus Data Meeting!",
                    text: "Anda yakin akan menghapus data ini?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yakin"
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$http.post('/api/auth/meeting/hapus-data-meeting', {
                            meeting_id: meeting_id
                        }).then((response) => {
                            if (response.data.status == "Success") {
                                this.$swal.fire({
                                    icon: 'success',
                                    title: 'Meeting berhasil di hapus',
                                    showConfirmButton: false,
                                    timer: 1200
                                })
                                setInterval(location.reload(), 5000);
                            }
                        }).catch((err) => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi!',
                            })
                        })
                    }
                })
            }
        }
    }
</script>